import login from "./translations/login.json";
import resetPassword from "./translations/resetPassword.json";
import page404 from "./translations/page-404.json";
import home from "./translations/home.json";
import navbar from "./translations/navbar.json";
import modal from "./translations/modal.json";
import format from "./translations/format.json";
import table from "./translations/table.json";
import status from "./translations/status.json";
import settings from "./translations/settings.json";
import profile from "./translations/profile.json";
import users from "./translations/users.json";
import validation from "./translations/validation.json";
import notPermission from "./translations/notPermission.json";
import listOrder from "./translations/listOrder.json";
import formOrder from "./translations/formOrder.json";
import detailOrder from "./translations/detailOrder.json";

import listProduct from "./translations/listProduct.json";
import formProduct from "./translations/formProduct.json";

import listService from "./translations/listService.json";
import formService from "./translations/formService.json";

import category from "./translations/category.json";

import listCustomer from "./translations/listCustomer.json";
import listUsers from "./translations/listUsers.json";
import formCustomer from "./translations/formCustomer.json";
import formUser from "./translations/formUser.json";

import listPartner from "./translations/listPartner.json";
import formPartner from "./translations/formPartner.json";

const translate = {
    login,
    resetPassword,
    page404,
    home,
    table,
    status,
    navbar,
    modal,
    format,
    settings,
    validation,
    users,
    profile,
    notPermission,
    listOrder,
    formOrder,
    detailOrder,
    listProduct,
    formProduct,
    listService,
    formService,
    listCustomer,
    formCustomer,
    listPartner,
    formPartner,
    category,
    listUsers,
    formUser
};

export default translate;
