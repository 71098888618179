import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { setPrefix } from "utils";

const Layout = lazy(() => import("components/layout"));
const Home = lazy(() => import("pages/home"));

const ListOrder = lazy(() => import("pages/listOrder"));
const DetailOrder = lazy(() => import("pages/detailOrder"));
const FormOrder = lazy(() => import("pages/formOrder"));

//Customer
const ListCustomer = lazy(() => import("pages/listCustomer"));
const ListUsers = lazy(() => import("pages/listUsers"));
const FormCustomer = lazy(() => import("pages/formCustomer"));
const FormUser = lazy(() => import("pages/formUser"));

const FlowCash = lazy(() => import("pages/flowCash"));
const Scheduler = lazy(() => import("pages/scheduler"));
//Products
const ListProduct = lazy(() => import("pages/listProduct"));

const Page404 = lazy(() => import("pages/404"));

const StoreReceptionist: React.FC = () => (
    <Suspense>
        <Router>
            <Layout>
                <Routes>
                    <Route path={setPrefix("/")} element={<Home />} />

                    <Route
                        path={setPrefix("/controle-caixa")}
                        element={<FlowCash />}
                    />
                                      <Route
                        path={setPrefix("/pedidos")}
                        element={<ListOrder />}
                    />
                    <Route
                        path={setPrefix("/controle-caixa")}
                        element={<FlowCash />}
                    />
                    <Route
                        path={setPrefix("/pedidos-loja")}
                        element={<ListOrder />}
                    />
                    <Route
                        path={setPrefix("/pedidos-online")}
                        element={<ListOrder />}
                    />
                    <Route
                        path={setPrefix("/pedido/:id")}
                        element={<DetailOrder />}
                    />
                    <Route
                        path={setPrefix("/criar-pedido/:id")}
                        element={<FormOrder />}
                    />
                    <Route
                        path={setPrefix("/criar-pedido-online/:id")}
                        element={<FormOrder />}
                    />

                    <Route
                        path={setPrefix("/agendamento/entrega")}
                        element={<Scheduler />}
                    />  
                    <Route
                        path={setPrefix("/agendamento/entrega-online")}
                        element={<Scheduler />}
                    />  
                    <Route
                        path={setPrefix("/agendamento/coleta")}
                        element={<Scheduler />}
                    />  
                    <Route
                        path={setPrefix("/clientes")}
                        element={<ListCustomer />}
                    />
                    <Route
                        path={setPrefix("/cliente/:id")}
                        element={<FormCustomer />}
                    />
                    <Route
                        path={setPrefix("/produtos")}
                        element={<ListProduct />}
                    />
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Layout>
        </Router>
    </Suspense>
);

export default StoreReceptionist;
