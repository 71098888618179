import React, { memo } from 'react';

import { GroupRadio, ButtonCustom } from './styled';

interface Props {
  label?: string;
  name?: string;
  value: string;
  checked: boolean;
  disabled?: boolean;
  error?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputRadio: React.FC<Props> = ({ label, name, value, checked, disabled, onChange }) => (
  <GroupRadio>
    <ButtonCustom color={checked ? 'secondary' : 'primary'} disabled={disabled}>
      <input type="radio" name={name} value={value} onChange={onChange} />
      {label}
    </ButtonCustom>
  </GroupRadio>
);

export default memo(InputRadio);
