import React, { memo } from "react";
import { Button } from "components/button";
import { InputText, InputPassword, } from "components/form";
import { Typography } from "@material-ui/core";

import {
    Grid,
    IconError,
    IconSuccess,
    Loading,
    Modal,
    ModalActions,
    ModalBody,
    ModalGroupMessage,
    ModalStatus,
    ModalTextSuccess,
    ModalTitle,
    PositionSwapIcon
} from "./styled";
import FooterButton from "components/footerButton";
import useWithdraw from './useWithdraw';
import { MdOutlineChangeCircle } from "react-icons/md";

interface Props {
    open: boolean;    
    refreshData: () => void;
    onGoNext: () => void;
    onGoback: () => void;
    onClose: () => void;
}

const Withdraw: React.FC<Props> = memo(
    ({ open, onGoNext, onGoback, refreshData, onClose }) => {
        const { formik, statusModal: status , message, userType,loginMask, handleLoginMask, setStatusModal, translate } = useWithdraw();

        const modalContent = () => {
            switch (status) {
                case "loading":
                    return (
                        <ModalStatus>
                            <Loading color="secondary" />
                        </ModalStatus>
                    );
                case "success":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconSuccess />
                                    <ModalTextSuccess>
                                        {message}
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={()=>{setStatusModal(''); refreshData(); onGoNext()}}
                                        >
                                            continue
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );
                case "error":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconError />
                                    <ModalTextSuccess>
                                        {message}
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={()=>{setStatusModal(''); onGoback()}}                                            
                                        >
                                            {translate("modal.button.goback")}
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );

                default:
                    return (
                        <>
                            <ModalTitle>
                                Retirada
                            </ModalTitle>
                            <ModalBody>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Tipo de Retirada
                                        </Typography>
                                        <InputText
                                            disabled={true}
                                            label={'Ex : Dinheiro'}
                                            className="login"
                                            name="type"
                                            variant={"standard"}
                                            color={"primary"}
                                            value={formik.values.type}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.type &&
                                                Boolean(formik.errors.type)
                                            }
                                            helperText={
                                                formik.touched.type &&
                                                formik.errors.type
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Razão da retirada
                                        </Typography>
                                        <InputText
                                            label={'Ex : Autorizado por '}
                                            className="login"
                                            name="reason"
                                            variant={"standard"}
                                            color={"primary"}
                                            value={formik.values.reason}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.reason &&
                                                Boolean(formik.errors.reason)
                                            }
                                            helperText={
                                                formik.touched.reason &&
                                                formik.errors.reason
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Valor
                                        </Typography>
                                        <InputText
                                            label=""
                                            className="login"
                                            name="value"
                                            mask="money"
                                            variant={"standard"}
                                            color={"primary"}
                                            value={String(formik.values.value)}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.value &&
                                                Boolean(formik.errors.value)
                                            }
                                            helperText={
                                                formik.touched.value &&
                                                formik.errors.value
                                            }
                                        />
                                    </Grid>
                                    {userType !== 'admin' ?
                                        (<>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Login
                                                </Typography>
                                                <PositionSwapIcon onClick={() => { handleLoginMask(loginMask === 'cpf' ? 'cnpj' : 'cpf') }}>
                                                    <MdOutlineChangeCircle size={24} color="#D8913A" />
                                                </PositionSwapIcon>
                                                <input name="login" type='text' style={{ display: 'block', position:'absolute', top:"-30px"}}/>
                                                <InputText
                                                    label={translate(
                                                        loginMask === 'cpf' ? "login.form.login" : "login.form.loginCnpj"
                                                    )}
                                                    className="login"
                                                    name="login"
                                                    mask={loginMask}
                                                    variant={"standard"}
                                                    color={"primary"}
                                                    value={formik.values.login}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.login &&
                                                        Boolean(formik.errors.login)
                                                    }
                                                    helperText={
                                                        formik.touched.login &&
                                                        formik.errors.login
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Senha
                                                </Typography>
                                                <input name="password" type='password' style={{ display: 'block', position:'absolute', top:"-30px"}}/>
                                                <InputPassword
                                                    label={translate(
                                                        "login.form.password"
                                                    )}
                                                    className="login"
                                                    name="password"
                                                    variant={"standard"}
                                                    color={"primary"}
                                                    value={formik.values.password}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.password &&
                                                        Boolean(
                                                            formik.errors.password
                                                        )
                                                    }
                                                    helperText={
                                                        formik.touched.password &&
                                                        formik.errors.password
                                                    }
                                                />
                                            </Grid>
                                        </>
                                        ) : ""
                                    }
                                    <Grid item xs={12}>
                                        <FooterButton>
                                            <Button
                                                onClick={(e) => { formik.handleSubmit() }}
                                            >
                                                {translate("modal.button.add")}
                                            </Button>
                                            <Button
                                                onClick={onClose}
                                                variant="outlined"
                                            >
                                                {translate("modal.button.cancel")}
                                            </Button>

                                        </FooterButton>
                                    </Grid>
                                </Grid>
                            </ModalBody>
                        </>
                    );
            }
        };

        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                scroll="body"
            >
                {modalContent()}
            </Modal>
        );
    }
);

export default Withdraw;
