import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/button";

import Form, { FormGroup, InputSelect, InputText } from "components/form";

import { Box, Typography } from "@material-ui/core";
import DropzoneUpload from "components/dropzoneUpload";
import {
    Grid,
    IconError,
    IconSuccess,
    Loading,
    Modal,
    ModalActions,
    ModalBody,
    ModalGroupMessage,
    ModalStatus,
    ModalTextSuccess,
    ModalTitle,
    ListSearch,
    ListSearchItems,
    NameItem,
    QtdItem,
    ValueItem,
    FooterService,
    FooterValueService,
    SearchService,
} from "./styled";

import InputQuantity from "components/form/InputQuantity";
import Title from "components/title";
import FooterButton from "components/footerButton";

import { formatMoney } from "utils/Utils";
type Error = {
    name?: string;
};

interface Props {
    open: boolean;
    status: "loading" | "success" | "error" | "";
    message?: string;
    fields: any;
    listCategories?: any;
    listServices?: any;
    sizes?: any;
    rowsService: any;
    onGoback: () => void;
    onClose: () => void;
    getServices: Function;
    selectServices: Function;
    addServices: Function;
    alterQuantity: Function;
}

const AddItemsOrder: React.FC<Props> = memo(
    ({
        open,
        status,
        message,
        fields,
        listCategories,
        listServices,
        sizes,
        onGoback,
        onClose,
        getServices,
        addServices,
        alterQuantity,
        rowsService = [],
        selectServices,
    }) => {
        const { t: translate } = useTranslation();

        const modalContent = () => {
            switch (status) {
                case "loading":
                    return (
                        <ModalStatus>
                            <Loading color="secondary" />
                        </ModalStatus>
                    );
                case "success":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconSuccess />
                                    <ModalTextSuccess>
                                        {message}
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={onGoback}
                                        >
                                            {translate("modal.button.goback")}
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );
                case "error":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconError />
                                    <ModalTextSuccess>
                                        {message}
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={onGoback}
                                        >
                                            {translate("modal.button.goback")}
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );

                default:
                    return (
                        <>
                            <ModalTitle>
                                {fields.values.id !== ""
                                    ? "Alterar peça"
                                    : "Adicionar peça"}
                            </ModalTitle>
                            <ModalBody>
                                <Form onSubmit={fields.handleSubmit}>
                                    <Grid container spacing={4}>
                                        <Grid
                                            xs={12}
                                            md={12}
                                            xl={12}
                                            style={{
                                                margin: 0,
                                                paddingBottom: 0,
                                            }}
                                            item
                                        >
                                            <Title
                                                text={
                                                    "Adicionar Serviço / Produto"
                                                }
                                                variant="h6"
                                                component="h1"
                                            />
                                        </Grid>
                                        <Grid xs={12} md={6} xl={6} item>
                                            <FormGroup>
                                                <Typography>
                                                    Categoria
                                                </Typography>
                                                <InputSelect
                                                    color="primary"
                                                    name="category"
                                                    options={listCategories}
                                                    value={
                                                        fields.values.category
                                                    }
                                                    onChange={(item) => {
                                                        fields.handleChange(
                                                            item
                                                        );
                                                        getServices(
                                                            item.target.value
                                                        );
                                                    }}
                                                    error={Boolean(
                                                        fields.errors.category
                                                    )}
                                                    helperText={
                                                        fields.errors.category
                                                    }
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid xs={12} md={6} xl={6} item>
                                            <FormGroup>
                                                <Typography>Tamanho</Typography>
                                                <InputSelect
                                                    color="primary"
                                                    name="size"
                                                    options={sizes}
                                                    value={fields.values.size}
                                                    onChange={(item) => {
                                                        fields.handleChange(
                                                            item
                                                        );
                                                    }}
                                                    error={Boolean(
                                                        fields.errors.size
                                                    )}
                                                    helperText={
                                                        fields.errors.size
                                                    }
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid xs={12} md={6} xl={6} item>
                                            <FormGroup>
                                                <Typography>
                                                    Marca da Peça
                                                </Typography>
                                                <InputText
                                                    label={""}
                                                    name="mark_piece"
                                                    color={"primary"}
                                                    value={
                                                        fields.values.mark_piece
                                                    }
                                                    onChange={
                                                        fields.handleChange
                                                    }
                                                    error={
                                                        Boolean(
                                                            fields.touched
                                                                .mark_piece
                                                        ) &&
                                                        Boolean(
                                                            fields.errors
                                                                .mark_piece
                                                        )
                                                    }
                                                    helperText={
                                                        fields.touched
                                                            .mark_piece &&
                                                        fields.errors.mark_piece
                                                    }
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid xs={12} md={6} xl={6} item>
                                            <FormGroup>
                                                <Typography>
                                                    Cor da Peça
                                                </Typography>
                                                <InputText
                                                    label={""}
                                                    name="color_piece"
                                                    color={"primary"}
                                                    value={
                                                        fields.values
                                                            .color_piece
                                                    }
                                                    onChange={
                                                        fields.handleChange
                                                    }
                                                    error={
                                                        Boolean(
                                                            fields.touched
                                                                .color_piece
                                                        ) &&
                                                        Boolean(
                                                            fields.errors
                                                                .color_piece
                                                        )
                                                    }
                                                    helperText={
                                                        fields.touched
                                                            .color_piece &&
                                                        fields.errors
                                                            .color_piece
                                                    }
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid xs={12} md={12} xl={12} item>
                                            <FormGroup>
                                                <Typography>
                                                    Observações
                                                </Typography>
                                                <InputText
                                                    label={""}
                                                    name="obs_piece"
                                                    color={"primary"}
                                                    value={
                                                        fields.values.obs_piece
                                                    }
                                                    onChange={
                                                        fields.handleChange
                                                    }
                                                    error={
                                                        Boolean(
                                                            fields.touched
                                                                .obs_piece
                                                        ) &&
                                                        Boolean(
                                                            fields.errors
                                                                .obs_piece
                                                        )
                                                    }
                                                    helperText={
                                                        fields.touched
                                                            .obs_piece &&
                                                        fields.errors.obs_piece
                                                    }
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid
                                            xs={12}
                                            md={12}
                                            xl={12}
                                            style={{ position: "relative" }}
                                            item
                                        >
                                            <FormGroup>
                                                <Typography>
                                                    Serviços/Produtos
                                                </Typography>
                                                <SearchService>
                                                    <InputSelect
                                                        variant="standard"
                                                        color="primary"
                                                        name="service"
                                                        multiple={true}
                                                        options={listServices}
                                                        value={
                                                            fields.values
                                                                .service
                                                        }
                                                        onChange={(item) => {
                                                            fields.handleChange(
                                                                item
                                                            );
                                                            selectServices(
                                                                item.target
                                                                    .value
                                                            );
                                                        }}
                                                        error={Boolean(
                                                            fields.errors
                                                                .service
                                                        )}
                                                        // helperText={formikItems.errors.service}
                                                    />
                                                    <Button
                                                        onClick={() => {
                                                            addServices();
                                                        }}
                                                        className="header"
                                                        fullWidth
                                                        size="large"
                                                    >
                                                        Adicionar
                                                    </Button>
                                                </SearchService>
                                                {rowsService.length > 0 && (
                                                    <ListSearch>
                                                        {rowsService.map(
                                                            (
                                                                item: any,
                                                                key: number
                                                            ) => {
                                                                return (
                                                                    <ListSearchItems
                                                                        key={
                                                                            key
                                                                        }
                                                                    >
                                                                        <NameItem>
                                                                            {
                                                                                item.title
                                                                            }
                                                                        </NameItem>
                                                                        <QtdItem>
                                                                            <InputQuantity
                                                                                onChange={(
                                                                                    value
                                                                                ) => {
                                                                                    alterQuantity(
                                                                                        value,
                                                                                        key
                                                                                    );
                                                                                }}
                                                                                valueInitial={
                                                                                    item.qtd
                                                                                }
                                                                                valueMin={fields.values.id !== ""
                                                                                    ? 0
                                                                                    : 1}
                                                                            />
                                                                        </QtdItem>
                                                                        <ValueItem>
                                                                            {formatMoney(
                                                                                item.qtd *
                                                                                    parseFloat(
                                                                                        item
                                                                                            .type
                                                                                            .price
                                                                                    )
                                                                            )}
                                                                        </ValueItem>
                                                                    </ListSearchItems>
                                                                );
                                                            }
                                                        )}
                                                        <FooterService>
                                                            <FooterValueService>
                                                                TOTAL{" "}
                                                                {formatMoney(
                                                                    fields
                                                                        .values
                                                                        .valor_total
                                                                )}
                                                            </FooterValueService>
                                                        </FooterService>
                                                    </ListSearch>
                                                )}
                                            </FormGroup>
                                        </Grid>
                                        <Grid xs={12} md={12} xl={12} item>
                                            <Box display="flex">
                                                <Button
                                                    size="large"
                                                    type="submit"
                                                    style={{
                                                        marginRight: "20px",
                                                    }}
                                                >
                                                    {fields.values.id !== ""
                                                        ? "Alterar item"
                                                        : "Adicionar item"}
                                                </Button>
                                                <Button
                                                    variant={"outlined"}
                                                    onClick={onGoback}
                                                    size="large"
                                                >
                                                    Cancelar
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Form>
                            </ModalBody>
                        </>
                    );
            }
        };

        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                scroll="body"
            >
                {modalContent()}
            </Modal>
        );
    }
);

export default AddItemsOrder;
