import React, { memo, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AvatarCustom, DropZone, ErrorField } from "./styled";

import Avatar from "@material-ui/core/Avatar";
import PhotoLibraryOutlinedIcon from "@material-ui/icons/PhotoLibraryOutlined";

interface Props {
    accept?: any;
    type?: "dropzone" | "avatar";
    oldPhoto?: String;
    error?: boolean;
    helperText?: string;
    onFiles: Function;
}

const DropzoneUpload: React.FC<Props> = memo(
    ({
        accept,
        onFiles,
        oldPhoto,
        error = true,
        helperText,
        type = "dropzone",
    }) => {
        const [images, setImages] = useState<String[]>([]);

        const acceptProps = accept ?? { "image/*": [] };

        const onDrop = useCallback(async (myFiles: any) => {
            onFiles.apply(null, [myFiles]);
            setImages([]);
            myFiles.map(async (item: File) => {
                const image = await readFileAsync(item);
                setImages((oldArray: any) => [...oldArray, image]);
            });
        }, []);

        const { getRootProps, getInputProps, isDragActive } = useDropzone({
            onDrop,
            multiple: false,
            accept: acceptProps,
        });

        function readFileAsync(file: File) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();

                reader.onload = () => {
                    resolve(reader.result);
                };

                reader.onerror = reject;

                reader.readAsDataURL(file);
            });
        }

        function isValidHttpUrl(httpUrl: any) {
            let url;

            try {
                url = new URL(httpUrl);
            } catch (_) {
                return false;
            }

            return url.protocol === "http:" || url.protocol === "https:";
        }

        useEffect(() => {
            if (typeof oldPhoto !== "undefined" && isValidHttpUrl(oldPhoto)) {
                setImages([oldPhoto]);
            }
            return () => {};
        }, []);

        if (type == "avatar") {
            return (
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {images.length == 0 && (
                        <Avatar style={{ width: 200, height: 200 }} />
                    )}

                    {images.map((item: any, index: number) => (
                        <Avatar
                            key={index}
                            src={item}
                            style={{ width: 200, height: 200 }}
                        />
                    ))}

                    {error ? (
                        <ErrorField>Coloque ao menos uma imagem</ErrorField>
                    ) : (
                        ""
                    )}
                </div>
            );
        }

        return (
            <>
                <DropZone {...getRootProps()} error={error}>
                    <input {...getInputProps()} />
                    <PhotoLibraryOutlinedIcon
                        fontSize="large"
                        color="primary"
                    />
                    {isDragActive ? (
                        <p>Solte os arquivos aqui</p>
                    ) : (
                        <p>Arraste uma imagem aqui, ou escolha</p>
                    )}
                </DropZone>
                {error ? (
                    <ErrorField>Coloque ao menos uma imagem</ErrorField>
                ) : (
                    ""
                )}
                {images.map((item: any, index: number) => (
                    <AvatarCustom
                        variant="square"
                        key={index}
                        src={item}
                        style={{ width: 150, height: 150 }}
                    />
                ))}
            </>
        );
    }
);

export default DropzoneUpload;
