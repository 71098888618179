import React from "react";

import Admin from "./Admin";
import NonAdmin from "./NonAdmin";
import StoreReceptionist from "./StoreReceptionist";
import Public from "./Public";

import { useAuth } from "hooks/auth";

const Routes: React.FC = () => {
    const { loadingPage, signed, profile, userType } = useAuth();

    const isTypeAdmin = userType === "admin";
    const isTypeStoreReceptionist = userType === "storeReceptionist";
    const isTypeDeliveryReceptionist = userType === "deliveryReceptionist";

    if (!loadingPage && signed && isTypeAdmin) {
        return <Admin />;
    }

    if (
        !loadingPage &&
        signed &&
        (isTypeStoreReceptionist || isTypeDeliveryReceptionist)
    ) {
        return <StoreReceptionist />;
    }

    if (!loadingPage && signed && !isTypeAdmin) {
        return <NonAdmin />;
    }

    if (!loadingPage && !signed) {
        return <Public />;
    }

    return <></>;
};

export default Routes;
