import { useDrawer } from "hooks/drawer";
import { useAuth } from "hooks/auth";
import { useFormik, useFormikContext } from "formik";
import * as yup from "yup";
import api from "service/api";
import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns"
import { setPrefix } from "utils";
import { useNavigate } from "react-router-dom";

export interface BodyScheduler {
    order: number
    partner:number
    statusId:number
    statusValue:number
    hoursIni:string
    date:string
}

export default function useShowSchedulerInfo() {
    const { openDrawer, activated, onOpenDrawer } = useDrawer();    
    const { t: translate } = useTranslation();
    const navigate = useNavigate();

    const [statusModal, setStatusModal] = useState<
        "loading" | "success" | "error" | ""
    >("");

    const [message, setMessage] = useState("");

    const goPage = useCallback(
        (link: string) => {
            navigate(setPrefix(link));
        },
        [navigate]
    );

    return {        
        message,
        statusModal,
        goPage,
        setStatusModal,
        translate
    };
}
