import { useDrawer } from "hooks/drawer";
import { useAuth } from "hooks/auth";
import { useFormik, useFormikContext } from "formik";
import * as yup from "yup";
import api from "service/api";
import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface BodyWithdraw {
    password?: string
    login?: string
    type: string
    reason: string
    value: number
}

export default function useWithdraw() {
    const { openDrawer, activated, onOpenDrawer } = useDrawer();
    const { userType } = useAuth();
    const { t: translate } = useTranslation();

    const [statusModal, setStatusModal] = useState<
        "loading" | "success" | "error" | ""
    >("");

    const [message, setMessage] = useState("");
    const [loginMask, setloginMask] = useState<string>('cpf');

    const whitdraw = useCallback(async (body: BodyWithdraw) => {
        setStatusModal('loading');
        try {
            await api.post(`/client/order/flow-cash/withdraw`, body)
            setStatusModal('success');
            setMessage('Retirada realizada com sucesso!')
        }
        catch (e) {
            setStatusModal('error');
            setMessage(e.message)
        }
    }, [])

    const validationPaymentSchema = useCallback(
        () =>
            yup.object({
                login: userType === 'admin' ? yup.string() : yup
                    .string()
                    .required(translate("validation.required")),
                password: userType === 'admin' ? yup.string() : yup
                    .string()
                    .required(translate("validation.required")),
                type: yup
                    .string(),                    
                reason: yup
                    .string()
                    .required(translate("validation.required")),
                value: yup
                    .string()
                    .required(translate("validation.required")),
            }),
        [translate]
    );

    const formik = useFormik({
        initialValues: {
            login: '',
            password: '',
            type: 'Dinheiro',
            reason: '',
            value: ''
        },
        validationSchema: validationPaymentSchema,
        onSubmit: (values, { resetForm }) => {
            const form = Object.assign({}, values);            
            whitdraw({ ...form, value: Number(form.value.replace(/R\$/g, '').replace(/,/g, '.').trimStart()), login: form.login.replace(/\.|\/|\-/g,'').trim() })
            resetForm()
        },
    });

    const handleLoginMask = (input: string) => {
        formik.setFieldValue('login', formik.values.login.slice(0, 14))
        setloginMask(input);
    }

    return {
        formik,
        userType,
        message,
        statusModal,
        loginMask, 
        handleLoginMask,
        setStatusModal,
        translate
    };
}
