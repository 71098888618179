import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { setPrefix } from "utils";

const Layout = lazy(() => import("components/layout"));
const Home = lazy(() => import("pages/home"));

const ListOrder = lazy(() => import("pages/listOrder"));
const DetailOrder = lazy(() => import("pages/detailOrder"));
const FormOrder = lazy(() => import("pages/formOrder"));

//Customer
const ListCustomer = lazy(() => import("pages/listCustomer"));
const FormCustomer = lazy(() => import("pages/formCustomer"));

//Rotas das páginas compartilhadas do sistema
const Profile = lazy(() => import("pages/profile"));
const Page404 = lazy(() => import("pages/404"));

const NonAdmin: React.FC = () => (
    <Suspense>
        <Router>
            <Layout>
                <Routes>
                    <Route path={setPrefix("/")} element={<Home />} />
                    <Route
                        path={setPrefix("/pedidos")}
                        element={<ListOrder />}
                    />
                    <Route
                        path={setPrefix("/pedidos-loja")}
                        element={<ListOrder />}
                    />
                    <Route
                        path={setPrefix("/pedido/:id")}
                        element={<DetailOrder />}
                    />
                    <Route
                        path={setPrefix("/criar-pedido/:id")}
                        element={<FormOrder />}
                    />
                    {/* <Route
                        path={setPrefix("/produtos")}
                        element={<ListProduct />}
                    />
                    <Route
                        path={setPrefix("/produto/:id")}
                        element={<FormProduct />}
                    /> */}

                    {/* <Route
                        path={setPrefix("/servicos")}
                        element={<ListService />}
                    />
                    <Route
                        path={setPrefix("/servico/:id")}
                        element={<FormService />}
                    /> */}

                    <Route
                        path={setPrefix("/clientes")}
                        element={<ListCustomer />}
                    />
                    <Route
                        path={setPrefix("/cliente/:id")}
                        element={<FormCustomer />}
                    />

                    {/* <Route
                        path={setPrefix("/parceiros")}
                        element={<ListPartner />}
                    />
                    <Route
                        path={setPrefix("/parceiro/:id")}
                        element={<FormPartner />}
                    /> */}

                    {/* <Route
                        path={setPrefix("/categoria/:tipo")}
                        element={<Category />}
                    /> */}

                    <Route path={setPrefix("/profile")} element={<Profile />} />
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Layout>
        </Router>
    </Suspense>
);

export default NonAdmin;
