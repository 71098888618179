import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/button";

import Form, { FormGroup, InputSelect, InputText } from "components/form";

import { Box, Typography } from "@material-ui/core";
import DropzoneUpload from "components/dropzoneUpload";
import {
    Grid,
    IconError,
    IconSuccess,
    Loading,
    Modal,
    ModalActions,
    ModalBody,
    ModalGroupMessage,
    ModalStatus,
    ModalTextSuccess,
    ModalTitle,
    ListSearch,
    ListSearchItems,
    NameItem,
    QtdItem,
    ValueItem,
    FooterService,
    FooterValueService,
    SearchService,
    ErrorMessage,
} from "./styled";
import useAddPayments from "./useAddPayments";
import InputQuantity from "components/form/InputQuantity";
import Title from "components/title";
import FooterButton from "components/footerButton";

import { formatMoney } from "utils/Utils";
type Error = {
    name?: string;
};

interface Props {
    open: boolean;
    message?: string;
    orderId?: any;
    valorTotal?: any;
    fields?: any;
    delivery?: any;
    onClose: () => void;
}

const AddPayments: React.FC<Props> = memo(
    ({ open, message, valorTotal, orderId, fields, delivery, onClose }) => {
        const { t: translate } = useTranslation();        
        const {
            status,
            paymentsTypes,
            paymentsMethod,
            paymentsMethodFlag,
            paymentsQuota,
            formik,
            errorMessage,
            handleEditPayments
        } = useAddPayments(valorTotal, orderId);

        handleEditPayments(fields)
        const renderByFlag = () => {
            if (["PIX", "DINHEIRO", "CREDITO_CONTA"].includes(formik.values.payment_method)) {
                return null;
            }

            if (formik.values.payment_method === "DEBITO") {
                return (
                    <>
                        <Grid xs={12} md={12} xl={12} item>
                            <FormGroup>
                                <Typography>Bandeira</Typography>
                                <InputSelect
                                    color="primary"
                                    name="payment_flag"
                                    options={
                                        paymentsMethodFlag[
                                            formik.values.payment_method ?? 1
                                        ]
                                    }
                                    value={formik.values.payment_flag}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.payment_flag)}
                                    helperText={formik.errors.payment_flag}
                                />
                            </FormGroup>
                        </Grid>
                    </>
                );
            }

            return (
                <>
                    <Grid xs={12} md={12} xl={12} item>
                        <FormGroup>
                            <Typography>Bandeira</Typography>
                            <InputSelect
                                color="primary"
                                name="payment_flag"
                                options={
                                    paymentsMethodFlag[
                                        formik.values.payment_method ?? 1
                                    ]
                                }
                                value={formik.values.payment_flag}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.payment_flag)}
                                helperText={formik.errors.payment_flag}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid xs={12} md={12} xl={12} item>
                        <FormGroup>
                            <Typography>Parcelamento</Typography>
                            <InputSelect
                                color="primary"
                                name="quota"
                                options={paymentsQuota}
                                value={formik.values.quota}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.quota)}
                                helperText={formik.errors.quota}
                            />
                        </FormGroup>
                    </Grid>
                </>
            );
        };

        const renderByType = () => {            
            if (["1"].includes(String(formik.values.payment))) {
                return (
                    <>
                        <Grid xs={12} md={12} xl={12} item>
                            <FormGroup>
                                <Typography>Forma de Pagamento</Typography>
                                <InputSelect
                                    color="primary"
                                    name="payment_method"
                                    options={paymentsMethod}
                                    value={formik.values.payment_method}
                                    onChange={formik.handleChange}
                                    error={Boolean(
                                        formik.errors.payment_method
                                    )}
                                    helperText={formik.errors.payment_method}
                                />
                            </FormGroup>
                        </Grid>

                        <Grid xs={12} md={12} xl={12} item>
                            <FormGroup>
                                <Typography>Valor a pagar</Typography>
                                <InputText
                                    label={""}
                                    mask={"money"}
                                    name="price_total"
                                    color={"primary"}
                                    value={formik.values.price_total}
                                    onChange={formik.handleChange}
                                    error={
                                        Boolean(formik.touched.price_total) &&
                                        Boolean(formik.errors.price_total)
                                    }
                                    helperText={
                                        formik.touched.price_total &&
                                        formik.errors.price_total
                                    }
                                />
                            </FormGroup>
                        </Grid>
                        {renderByFlag()}
                    </>
                );
            }

            if (formik.values.payment == "3") {
                return (
                    <>
                        <Grid xs={12} md={12} xl={12} item>
                            <FormGroup>
                                <Typography>Forma de Pagamento</Typography>
                                <InputSelect
                                    color="primary"
                                    name="payment_method"
                                    options={paymentsMethod}
                                    value={formik.values.payment_method}
                                    onChange={formik.handleChange}
                                    error={Boolean(
                                        formik.errors.payment_method
                                    )}
                                    helperText={formik.errors.payment_method}
                                />
                            </FormGroup>
                        </Grid>

                        <Grid xs={12} md={12} xl={12} item>
                            <FormGroup>
                                <Typography>Valor pago antecipado</Typography>
                                <InputText
                                    label={""}
                                    mask={"money"}
                                    name="anticipated"
                                    color={"primary"}
                                    value={formik.values.anticipated}
                                    onChange={formik.handleChange}
                                    error={
                                        Boolean(formik.touched.anticipated) &&
                                        Boolean(formik.errors.anticipated)
                                    }
                                    helperText={
                                        formik.touched.anticipated &&
                                        formik.errors.anticipated
                                    }
                                />
                            </FormGroup>
                        </Grid>
                        {renderByFlag()}
                    </>
                );
            }

            if (formik.values.payment == "4") {
                return (
                    <>
                        <Grid xs={12} md={12} xl={12} item>
                            <FormGroup>
                                <Typography>Forma de Pagamento</Typography>
                                <InputSelect
                                    color="primary"
                                    name="type_discount"
                                    options={[
                                        { title: "Valor Fixo", value: "1" },
                                        { title: "Porcentagem", value: "2" },
                                    ]}
                                    value={formik.values.type_discount}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.type_discount)}
                                    helperText={formik.errors.type_discount}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid xs={12} md={12} xl={12} item>
                            <FormGroup>
                                <Typography>Valor do Desconto</Typography>
                                <InputText
                                    label={""}
                                    mask={
                                        formik.values.type_discount == "1"
                                            ? "money"
                                            : "percent"
                                    }
                                    name="discount"
                                    color={"primary"}
                                    value={formik.values.discount}
                                    onChange={formik.handleChange}
                                    error={
                                        Boolean(formik.touched.discount) &&
                                        Boolean(formik.errors.discount)
                                    }
                                    helperText={
                                        formik.touched.discount &&
                                        formik.errors.discount
                                    }
                                />
                            </FormGroup>
                        </Grid>
                    </>
                );
            }

            return null;
        };

        const handleOnChange = (event: any) => {
            console.log("Form::onChange", event);
        };

        const modalContent = () => {
            switch (status) {
                case "loading":
                    return (
                        <ModalStatus>
                            <Loading color="secondary" />
                        </ModalStatus>
                    );
                case "success":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconSuccess />
                                    <ModalTextSuccess>
                                        Pagamento realizado com sucesso!
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button onClick={onClose}>
                                            {translate("modal.button.goback")}
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );
                case "error":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconError />
                                    <ModalTextSuccess>
                                        {errorMessage}                                        
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={onClose}
                                        >
                                            {translate("modal.button.goback")}
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );

                default:
                    return (
                        <>
                            <ModalTitle>Incluir na Fatura</ModalTitle>
                            <ModalBody>
                                {delivery == null ? (
                                    <ErrorMessage>
                                        Informar a data de entrega, para o
                                        pagamento aparecer em CONTROLE DE CAIXA
                                    </ErrorMessage>
                                ) : (
                                    ""
                                )}
                                <Form onSubmit={formik.handleSubmit}>
                                    <Grid container spacing={0}>
                                        <Grid xs={12} md={12} xl={12} item>
                                            <FormGroup>
                                                <Typography>
                                                    Lançamento
                                                </Typography>
                                                <InputSelect
                                                    color="primary"
                                                    name="payment"
                                                    options={paymentsTypes}
                                                    value={
                                                        formik.values.payment
                                                    }
                                                    onChange={(item) => {
                                                        formik.handleChange(
                                                            item
                                                        );
                                                    }}
                                                    error={Boolean(
                                                        formik.errors.payment
                                                    )}
                                                    helperText={
                                                        formik.errors.payment
                                                    }
                                                />
                                            </FormGroup>
                                        </Grid>
                                        {renderByType()}

                                        <Grid xs={12} md={12} xl={12} item>
                                            <Box display="flex">
                                                <Button
                                                    size="large"
                                                    type="submit"
                                                    style={{
                                                        marginRight: "20px",
                                                    }}
                                                >
                                                    Adicionar
                                                </Button>
                                                <Button
                                                    variant={"outlined"}
                                                    onClick={onClose}
                                                    size="large"
                                                >
                                                    Cancelar
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Form>
                            </ModalBody>
                        </>
                    );
            }
        };

        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                scroll="body"
            >
                {modalContent()}
            </Modal>
        );
    }
);

export default AddPayments;
