import React, { ReactNode } from "react";

import ChangeThemeProvider from "hooks/theme";
import NotificationProvider from "hooks/notification";
import AuthProvider from "hooks/auth";
import DrawerProvider from "hooks/drawer";

interface Props {
    children: ReactNode;
}

const MainProvider: React.FC<Props> = ({ children }) => {
    return (
        <NotificationProvider>
            <AuthProvider>
                <ChangeThemeProvider>
                    <DrawerProvider>{children}</DrawerProvider>
                </ChangeThemeProvider>
            </AuthProvider>
        </NotificationProvider>
    );
};

export default MainProvider;
