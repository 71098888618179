import React, { memo, useState, useCallback } from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { FormGroupPassword, InputCustom, ButtonPassword } from './styled';

interface Props {
  type?: 'text' | 'password';
  mask?: string;
  variant?: any;
  color?: 'primary' | 'secondary';
  className?: string;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  label: string;
  name?: string;
  value: string;
  error?: any;
  helperText?: any;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputPassword: React.FC<Props> = ({
  multiline,
  minRows,
  maxRows,
  variant,
  className,
  color,
  label,
  name,
  value,
  onChange,
  error,
  helperText,
  disabled,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(true);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormGroupPassword>
      <InputCustom
        type={showPassword ? 'password' : 'text'}
        multiline={multiline || false}
        maxRows={maxRows || ''}
        minRows={minRows || ''}
        variant={variant || 'outlined'}
        className={className || ''}
        color={color}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        autoComplete="off"
      />
      <ButtonPassword
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </ButtonPassword>
    </FormGroupPassword>
  );
};

export default memo(InputPassword);
