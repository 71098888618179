import React, { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/button";
import Form, { FormGroup, InputSelect, InputText } from "components/form";
import { Typography } from "@material-ui/core";

import {
    Grid,
    IconError,
    IconSuccess,
    Loading,
    Modal,
    ModalActions,
    ModalBody,
    ModalGroupMessage,
    ModalStatus,
    ModalTextSuccess,
    ModalTitle,
} from "./styled";
import FooterButton from "components/footerButton";

interface Props {
    open: boolean;
    status: "loading" | "success" | "error" | "";
    message?: string;
    listFilterOptions: any;
    onGoNext: (item) => void;
    onGoback: () => void;
    onClose: () => void;
}

const AddFilter: React.FC<Props> = memo(
    ({ open, status, message, listFilterOptions, onGoNext, onGoback, onClose }) => {
        const { t: translate } = useTranslation();
        const [ filter, setFilter ] = useState(0)

        const handleSubmit = () => {
            onGoNext(filter)
            onClose()
        }

        const modalContent = () => {
            switch (status) {
                case "loading":
                    return (
                        <ModalStatus>
                            <Loading color="secondary" />
                        </ModalStatus>
                    );
                case "success":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconSuccess />
                                    <ModalTextSuccess>
                                        {message}
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={onGoNext}
                                        >
                                            continue
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );
                case "error":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconError />
                                    <ModalTextSuccess>
                                        {message}
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={onGoback}
                                        >
                                            {translate("modal.button.goback")}
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );

                default:
                    return (
                        <>
                            <ModalTitle>
                                Imprimir Pagamentos
                            </ModalTitle>
                            <Form onSubmit={handleSubmit}>
                                <ModalBody>
                                    <Grid container spacing={3} >
                                        <Grid item xs={12}>
                                            <Typography>
                                                Tipo de Pagamento
                                            </Typography>
                                            <InputSelect
                                                key={crypto.randomUUID()}
                                                color="primary"
                                                name={`listFilter`}
                                                options={listFilterOptions}
                                                value={filter}
                                                onChange={(item) => {
                                                    const value =  (item?.target?.value) as number
                                                    setFilter(value ?? 0)
                                                }
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FooterButton>
                                                <Button
                                                    type="submit"
                                                >
                                                    Continuar
                                                </Button>
                                                <Button
                                                    onClick={onClose}
                                                    variant="outlined"
                                                >
                                                    {translate("modal.button.cancel")}
                                                </Button>

                                            </FooterButton>
                                        </Grid>
                                    </Grid>
                                </ModalBody>
                            </Form>
                        </>
                    );
            }
        };

        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                scroll="body"
            >
                {modalContent()}
            </Modal>
        );
    }
);

export default AddFilter;
