import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/button";

import {
    Modal,
    ModalStatus,
    ModalTitle,
    ModalBody,
    ModalText,
    ModalActions,
    ModalGroupMessage,
    ModalTextSuccess,
    Loading,
    IconSuccess,
    IconError,
    Filename,
} from "./styled";

interface PropsConfirm {
    title?: string;
    text?: string;
    message?: string;
    attachment?: string | null;
    open: boolean;
    status: "loading" | "success" | "error" | "";
    onClose: () => void;
    onConfirm: (event: React.ChangeEvent<HTMLFormElement>) => void;
}

const Confirm: React.FC<PropsConfirm> = memo(
    ({
        title,
        text,
        attachment,
        message,
        open,
        status,
        onClose,
        onConfirm,
    }) => {
        const { t: translate } = useTranslation();

        const ModalContent = () => {
            switch (status) {
                case "loading":
                    return (
                        <ModalStatus>
                            <Loading color="secondary" />
                        </ModalStatus>
                    );
                case "success":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconSuccess />
                                    <ModalTextSuccess>
                                        {message}
                                    </ModalTextSuccess>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );
                case "error":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconError />
                                    <ModalTextSuccess>
                                        {message}
                                    </ModalTextSuccess>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );

                default:
                    return (
                        <>
                            {title && <ModalTitle>{title}</ModalTitle>}
                            <ModalBody>
                                {text && <ModalText>{text}</ModalText>}
                                {attachment && (
                                    <Filename>{attachment}</Filename>
                                )}
                            </ModalBody>
                            <ModalActions>
                                <Button onClick={onClose} variant={"outlined"}>
                                    {translate("modal.button.cancel")}
                                </Button>
                                <Button onClick={onConfirm}>
                                    {translate("modal.button.confirm")}
                                </Button>
                            </ModalActions>
                        </>
                    );
            }
        };

        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
            >
                <ModalContent />
            </Modal>
        );
    }
);

export default Confirm;
