import React, { memo, ReactNode } from "react";

import Drawer from "components/drawer";

import { useDrawer } from "hooks/drawer";
import { useTheme } from "hooks/theme";

import { Container, Main } from "./styled";

interface Props {
    children: ReactNode;
}

const PageName = (value: string) => {
    const params = value.split("/")[1];
    return params;
};

const Layout: React.FC<Props> = memo(({ children }) => {
    const { themeSelected } = useTheme();
    // const { profile } = useAuth();
    const { openDrawer } = useDrawer();
    // const location = useLocation();
    return (
        <Main myTheme={themeSelected}>
            <Drawer />
            <Container className={!openDrawer ? "container__full" : ""}>
                {children}
            </Container>
        </Main>
    );
});

export default Layout;
