import styled from "styled-components";
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip,
} from "@material-ui/core";
import { unstable_createMuiStrictModeTheme as createTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomFormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import imgLoading from "assets/images/loading.svg";

interface PropsForm {
    disabled?: boolean;
}

interface PropsFormGroup {
    space?: boolean;
}

interface PropsLabel {
    error?: boolean;
}

export const themeSelect = createTheme({
    palette: {
        primary: {
            main: "#fff",
            dark: "#fff",
            light: "#fff",
        },
    },
});

export const themeSelectDark = createTheme({
    palette: {
        primary: {
            main: "#000",
            dark: "#000",
            light: "#000",
        },
    },
});

export const FormCustom = styled.form<PropsForm>`
    width: 100%;
    position: relative;
    transition: all 0.3s ease-in-out;
    opacity: ${(props: any) => (props.disabled ? 0.2 : 1)};

    &:before {
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: ${(props: any) => (props.disabled ? 10 : -5)};
    }

    &.loading-form {
        transform: scale(0.9);
        opacity: 0.7;

        &:before {
            width: 100%;
            height: 100%;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
        }

        &:after {
            width: 100%;
            height: 100%;
            content: "";
            background-image: url(${imgLoading});
            background-repeat: no-repeat;
            background-size: 70px;
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 15;
        }
    }
`;

export const FormGroupCustom = styled.div<PropsFormGroup>`
    width: 100%;
    margin-bottom: ${(props: any) => (props.space === true ? 20 : 0)}px;
`;

export const InputCustom = styled(TextField)`
    width: 100%;
    && {
        &.login {
            div {
                ::after {
                    border-color: #d8913a;
                }
                ::before {
                    border-color: #d8913a;
                }
            }
        }
        label {
            color: ${(props) =>
                props.color === "primary"
                    ? props.theme.palette.primary.main
                    : props.theme.palette.primary.light};

            &.Mui-error {
                color: ${(props: any) => props.theme.palette.error.main};
            }
        }
        fieldset {
            border-color: ${(props) =>
                props.color === "primary"
                    ? props.theme.palette.primary.dark
                    : props.theme.palette.primary.light};
        }
        div {
            input {
                color: ${(props) =>
                    props.color === "primary"
                        ? props.theme.palette.primary.dark
                        : props.theme.palette.primary.light};

                &:focus {
                    color: ${(props) =>
                        props.color === "primary"
                            ? props.theme.palette.primary.dark
                            : props.theme.palette.primary.light};
                }
            }
            &.Mui-error {
                fieldset {
                    border-width: 1px;
                    border-color: ${(props: any) =>
                        props.theme.palette.error.main};
                }
                input {
                    color: ${(props: any) => props.theme.palette.error.main};
                }
            }

            &.Mui-focused {
                fieldset {
                    border-width: 1px;
                }
            }
        }
        &:hover {
            label:not(.Mui-focused):not(.Mui-error) {
                color: ${(props) =>
                    props.color === "primary"
                        ? props.theme.palette.primary.main
                        : "#fff"};
            }
        }
        & > div:not(.Mui-focused):not(.Mui-error) {
            &:hover {
                fieldset {
                    border-color: ${(props) =>
                        props.color === "primary"
                            ? props.theme.palette.primary.main
                            : "#fff"};
                }
                input {
                    color: ${(props) =>
                        props.color === "primary"
                            ? props.theme.palette.primary.main
                            : "#fff"};
                }
            }
        }
        & > div {
            &.Mui-error {
                fieldset {
                    border-color: ${(props: any) =>
                        props.theme.palette.error.main};
                }
                input {
                    color: ${(props: any) => props.theme.palette.error.main};
                }
            }
        }
        .MuiFormHelperText-root {
            line-height: 1.3;
        }
    }
`;

export const FormGroupPassword = styled.div`
    position: relative;

    input {
        padding-right: 60px;
    }
`;

export const ButtonPassword = styled(IconButton)`
    && {
        width: 60px;
        min-width: 60px;
        max-width: 60px;
        position: absolute;
        top: 4px;
        right: 0px;
        color: ${(props) =>
            props.color === "primary"
                ? props.theme.palette.primary.dark
                : props.theme.palette.primary.light};
        border-radius: 0;

        svg {
            color: ${(props) =>
                props.color === "primary"
                    ? props.theme.palette.primary.dark
                    : props.theme.palette.primary.light};
        }
    }
`;

export const FormControlCustom = styled(FormControl)`
    width: 100%;

    label {
        color: ${(props) =>
            props.color === "primary"
                ? props.theme.palette.primary.main
                : props.theme.palette.primary.light};

        &.Mui-focused {
            color: ${(props) =>
                props.color === "primary"
                    ? props.theme.palette.primary.dark
                    : props.theme.palette.primary.light};
        }
    }

    &:hover {
        label:not(.Mui-focused):not(.Mui-error) {
            color: ${(props) =>
                props.color === "primary"
                    ? props.theme.palette.primary.main
                    : "#fff"};
        }
    }
`;

export const InputLabelCustom = styled(InputLabel)<PropsLabel>`
    && {
        line-height: 1.1;

        &.Mui-error {
            color: ${(props: any) => props.theme.palette.error.main};
        }
        ${({ error }: any) =>
            error === true
                ? `color: ${(props: any) => props.theme.palette.error.main}`
                : ""};

        &.Mui-error {
            color: ${(props: any) => props.theme.palette.error.main};
        }
    }
`;

export const FormHelperText = styled(CustomFormHelperText)`
    && {
        line-height: 1.3;

        &.Mui-error {
            color: ${(props: any) => props.theme.palette.error.main};
        }
    }
`;

export const InputSelectCustom = styled(Select)`
    li {
        display: none;
    }
    && {
        width: 100%;

        div[class*="MuiSelect-select-"] {
            color: ${(props) =>
                props.color === "primary"
                    ? props.theme.palette.primary.dark
                    : props.theme.palette.primary.light};
        }

        fieldset {
            border-width: 1px;
            border-radius: 4px;
            border-color: ${(props) =>
                props.color === "primary"
                    ? props.theme.palette.primary.dark
                    : props.theme.palette.primary.light};
        }

        &.Mui-focused {
            fieldset {
                border-width: 1px;
                border-radius: 4px;
                border-color: ${(props) =>
                    props.color === "primary"
                        ? props.theme.palette.primary.dark
                        : props.theme.palette.primary.light};
            }
        }

        svg {
            cursor: default;
            color: ${(props) =>
                props.color === "primary"
                    ? props.theme.palette.primary.dark
                    : props.theme.palette.primary.light};
        }

        &:hover {
            div[class*="MuiSelect-root-"] {
                color: ${(props) =>
                    props.color === "primary"
                        ? props.theme.palette.primary.main
                        : "#fff"};
            }
            fieldset {
                border-color: ${(props) =>
                    props.color === "primary"
                        ? props.theme.palette.primary.main
                        : "#fff"};
            }
        }

        &.Mui-error {
            fieldset {
                border-color: ${(props: any) => props.theme.palette.error.main};
            }
            svg {
                color: ${(props: any) => props.theme.palette.error.main};
            }
        }
    }
`;

export const OptionSelect = styled(MenuItem)``;

export const InputSearchCustom = styled(Autocomplete)`
    && {
        width: 100%;

        div[class*="MuiAutocomplete-inputRoot"] {
            padding-right: 16px;

            & > div > input {
                color: ${(props) =>
                    props.color === "primary"
                        ? props.theme.palette.primary.dark
                        : props.theme.palette.primary.light};

                &:hover {
                    color: ${(props) =>
                        props.color === "primary"
                            ? props.theme.palette.primary.dark
                            : props.theme.palette.primary.light};
                }

                &:focus {
                    color: ${(props) =>
                        props.color === "primary"
                            ? props.theme.palette.primary.dark
                            : props.theme.palette.primary.light};
                }
            }

            fieldset {
                border-width: 1px;
                border-radius: 4px;
                border-color: #333;
            }

            &:not(.Mui-focused):hover {
                & > input {
                    ${(props) =>
                        props.color === "primary"
                            ? props.theme.palette.primary.dark
                            : props.theme.palette.primary.light};

                    &:hover {
                        ${(props) =>
                            props.color === "primary"
                                ? props.theme.palette.primary.dark
                                : props.theme.palette.primary.light};
                    }

                    &:focus {
                        ${(props) =>
                            props.color === "primary"
                                ? props.theme.palette.primary.dark
                                : props.theme.palette.primary.light};
                    }
                }

                & > div[class*="MuiFormControl-root-"] {
                    & > div[class*="MuiInputBase-root-"] {
                        fieldset {
                            border-color: ${(props) =>
                                props.color === "primary"
                                    ? props.theme.palette.primary.main
                                    : "#fff"};
                        }
                    }
                }
            }

            svg {
                cursor: default;
            }

            &.Mui-error {
                & > input,
                & > input:not(.MuiAutocomplete-inputFocused):hover {
                    color: ${(props: any) => props.theme.palette.error.main};
                }
                fieldset {
                    border-color: ${(props: any) =>
                        props.theme.palette.error.main};
                }
                svg {
                    color: ${(props: any) => props.theme.palette.error.main};
                }

                &:not(.Mui-focused):hover fieldset {
                    border-color: ${(props: any) =>
                        props.theme.palette.error.main};
                }

                &:hover {
                    & > input,
                    & > input:not(.MuiAutocomplete-inputFocused):hover {
                        color: ${(props: any) =>
                            props.theme.palette.error.main};
                    }
                }
            }
        }
    }
`;

export const WrapChip = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const OptionChip = styled(Chip)`
    && {
        margin: 2px;
        background-color: #e5e5e5;
        background-image: linear-gradient(180deg, #e5e5e5, #efefef);
        border: 1px solid ${(props) => props.theme.palette.primary.dark};
    }
`;

export const GroupRadio = styled.label`
    margin: 0;
    padding: 0;
    position: relative;

    button {
        overflow: hidden;

        input {
            width: 200%;
            height: 200%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            opacity: 0;
        }
    }
`;

export const ButtonCustom = styled(Button)`
    && {
        width: 100%;
        height: 50px;
        border-radius: 10px;
        padding: 12px 20px;
        font-weight: 700;
        background-color: ${({ theme }: any) =>
            theme.palette.button.default.light[0]};
        background-image: ${({ theme }: any) =>
            `linear-gradient(180deg, ${theme.palette.button.default.light})`};
        border: 0;

        &:not(:disabled):hover {
            background-color: ${({ theme }: any) =>
                theme.palette.button.default.dark[0]};
            background-image: ${({ theme }: any) =>
                `linear-gradient(180deg, ${theme.palette.button.default.dark})`};
        }

        .MuiButton-label {
            color: ${({ theme }: any) => theme.palette.button.default.main};
        }

        &.MuiButton-textPrimary {
            background-color: ${({ theme }: any) =>
                theme.palette.button.primary.light[0]};
            background-image: ${({ theme }: any) =>
                `linear-gradient(180deg, ${theme.palette.button.primary.light})`};

            .MuiButton-label {
                color: ${({ theme }: any) => theme.palette.button.primary.main};
            }

            &:not(:disabled):hover {
                background-color: ${({ theme }: any) =>
                    theme.palette.button.primary.dark[0]};
                background-image: ${({ theme }: any) =>
                    `linear-gradient(180deg, ${theme.palette.button.primary.dark})`};
            }
        }

        &.MuiButton-textSecondary {
            background-color: ${({ theme }: any) =>
                theme.palette.button.secondary.light[0]};
            background-image: ${({ theme }: any) =>
                `linear-gradient(180deg, ${theme.palette.button.secondary.light})`};

            .MuiButton-label {
                color: ${({ theme }: any) =>
                    theme.palette.button.secondary.main};
            }

            &:not(:disabled):hover {
                background-color: ${({ theme }: any) =>
                    theme.palette.button.secondary.dark[0]};
                background-image: ${({ theme }: any) =>
                    `linear-gradient(180deg, ${theme.palette.button.secondary.dark})`};
            }
        }
    }
`;

export const ButtonGroupCustom = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: stretch;
    flex-direction: row;
    flex-wrap: nowrap;

    button {
        width: 30px !important;
        height: 30px !important;
        min-width: 30px !important;
    }

    input {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        height: 30px;
        width: 30px;
        border: 0 !important;
        text-align: center;
    }
`;
