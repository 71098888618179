import React, { memo } from "react";
import Popover from "@material-ui/core/Popover";

interface PropsConfirm {
    children?: React.ReactNode;
    targetText: string;
}

const SimplePopover: React.FC<PropsConfirm> = memo(
    ({ children, targetText }) => {
        const [anchorEl, setAnchorEl] =
            React.useState<HTMLButtonElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? "simple-popover" : undefined;

        return (
            <div style={{ backgroundColor: "#fff" }}>
                <span
                    style={{ cursor: "pointer" }}
                    aria-describedby={id}
                    onClick={handleClick}
                >
                    <p
                        style={{
                            textDecoration: "underline",
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "#635d5d",
                        }}
                    >
                        {targetText}
                    </p>
                </span>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    {children}
                </Popover>
            </div>
        );
    }
);

export default SimplePopover;
