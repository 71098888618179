import React, { memo } from "react";
import { Button } from "components/button";
import { InputText, InputPassword, InputSelect, } from "components/form";
import { Typography } from "@material-ui/core";

import {
    FormatText,
    Grid,
    IconError,
    IconSuccess,
    Loading,
    Modal,
    ModalActions,
    ModalBody,
    ModalGroupMessage,
    ModalStatus,
    ModalTextSuccess,
    ModalTitle,
} from "./styled";
import useShowSchedulerInfo from './useShowSchedulerInfo';
import { ContentCalendar } from "pages/scheduler/interface";

interface Props {
    title: string
    open: boolean;
    body: ContentCalendar
    onGoNext: () => void;
    onGoback: () => void;
    onClose: () => void;
}

const ShowSchedulerInfo: React.FC<Props> = memo(
    ({ open, title, body, onGoNext, onGoback, onClose }) => {
        const { statusModal: status, message, setStatusModal, translate, goPage } = useShowSchedulerInfo();
        const modalContent = () => {
            switch (status) {
                case "loading":
                    return (
                        <ModalStatus>
                            <Loading color="secondary" />
                        </ModalStatus>
                    );
                case "success":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconSuccess />
                                    <ModalTextSuccess>
                                        {message}
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={() => { onGoNext() }}
                                        >
                                            continue
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );
                case "error":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconError />
                                    <ModalTextSuccess>
                                        {message}
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={() => { onGoback() }}
                                        >
                                            {translate("modal.button.goback")}
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );

                default:
                    return (
                        <>
                            <ModalTitle>
                                {title}
                            </ModalTitle>
                            <ModalBody>
                                <Grid container spacing={3}>
                                    {body && (
                                        <Grid item xs={12}>
                                            <FormatText>
                                                <Typography onClick={() => goPage(`/pedido/${body.orderId}`)} style={{ textAlign: "center", cursor: "pointer", textDecoration: "underline" }}>
                                                    #Pedido {body.orderId}
                                                </Typography>
                                                <Typography style={{ textAlign: "center", cursor: "pointer", textDecoration: "underline" }}>
                                                    Cliente {body.costumer_name}
                                                </Typography>
                                                <Typography style={{ textAlign: "center", cursor: "pointer", textDecoration: "underline" }}>
                                                    Tel {body.phone}
                                                </Typography>
                                                <Typography onClick={() => body.partnerId != -1 && goPage(`/parceiro/${body.partnerId}`)} style={{ textAlign: "center", cursor: "pointer", textDecoration: "underline" }}>
                                                    Parceiro(a) {body.partnerName}
                                                </Typography>
                                                <Typography style={{ textAlign: "center", cursor: "pointer", textDecoration: "underline" }}>
                                                    {body.address}
                                                </Typography>
                                            </FormatText>
                                        </Grid>

                                    )}
                                </Grid>
                            </ModalBody>
                        </>
                    );
            }
        };

        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                scroll="body"
            >
                {modalContent()}
            </Modal>
        );
    }
);

export default ShowSchedulerInfo;
