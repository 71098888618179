import styled from "styled-components";
import { Typography } from "@material-ui/core";

export interface PropsTitle {
    variant?: string;
    component?: string;
}

export const Text = styled(Typography)<PropsTitle>`
    font-weight: ${(props) => (props.variant !== "h6" ? "500" : "400")};
    color: #635d5d;
`;
