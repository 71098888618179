import React, { memo, ReactNode } from "react";
import { FormGroupCustom } from "./styled";

interface Props {
    children: ReactNode;
    className?: string;
    space?: boolean;
}

const FormGroup: React.FC<Props> = memo(({ children, space = true, className = "" }: Props) => (
    <FormGroupCustom space={space} className={className}>
        {children}
    </FormGroupCustom>
));

export default FormGroup;
