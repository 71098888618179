import React, { memo, useEffect, useState } from "react";

import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

import { InputCustom } from "./styled";

interface Props {
    type?: "text" | "password" | "tel" | "number";
    mask?: string | null;
    variant?: any;
    color?: "primary" | "secondary";
    className?: string;
    placeholder?: string;
    multiline?: boolean;
    minRows?: number;
    maxRows?: number;
    label: string;
    name?: string;
    value?: string;
    error?: any;
    helperText?: any;
    disabled?: boolean;
    ref?:any
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputMaskCpf: React.FC = (props: any) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                /\d/,
                /\d/,
                /\d/,
                ".",
                /\d/,
                /\d/,
                /\d/,
                ".",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
            ]}
            placeholderChar={"\u2000"}
        />
    );
};

const InputMaskCnpj: React.FC = (props: any) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                /\d/,
                /\d/,
                ".",
                /\d/,
                /\d/,
                /\d/,
                ".",
                /\d/,
                /\d/,
                /\d/,
                "/",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
            ]}
            placeholderChar={"\u2000"}
        />
    );
};

const InputMaskCep: React.FC = (props: any) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
            placeholderChar={"\u2000"}
        />
    );
};

const InputMaskDate: React.FC = (props: any) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={"\u2000"}
        />
    );
};
const InputMaskCellphone: React.FC = (props: any) => {
    const { inputRef, ...other } = props;
    function getInitialMask(value: string) {
        // Mais de 11 digitos tem código de país, menos sem, com variações para numero com 8 ou 9 digitos
        // Espaços extras no fim para poder sempre colocar 13 digitos
        // default com 13 espaços sem formatação
        switch(value.match(/\d/g)?.length) {
            case 13: return ["+", /\d/, /\d/, " ", "(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/];
            case 12: return ["+", /\d/, /\d/, " ", "(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/];
            case 11: return ["(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
            case 10: return ["(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
            default: return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
        };
    }
    const [mask, setMask] = useState<(string | RegExp)[]>(getInitialMask(props.value));

    useEffect(() => {
        if(props.value){
            setMask(getInitialMask(props.value));
        }
    }, [props.value]);
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask}
            placeholderChar={"\u2000"}
        />
    );
};
const InputMaskQtd: React.FC = (props: any) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/]}
            placeholderChar={"\u2000"}
        />
    );
};

const InputCurrency: React.FC = (props: any) => {
    const defaultMaskOptions = {
        prefix: "R$ ",
        suffix: "",
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: "",
        allowDecimal: true,
        decimalSymbol: ",",
        decimalLimit: 2,
        integerLimit: 10,
        allowNegative: false,
        allowLeadingZeroes: false,
    };
    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
        ...props,
    });
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={currencyMask}
            placeholderChar={"\u2000"}
        />
    );
};

const InputPercentage: React.FC = (props: any) => {
    const defaultMaskOptions = {
        prefix: "",
        suffix: "%",
        includeThousandsSeparator: false,
        thousandsSeparatorSymbol: "",
        allowDecimal: false,
        decimalSymbol: ",",
        decimalLimit: 0,
        integerLimit: 3,
        allowNegative: false,
        allowLeadingZeroes: false,
    };
    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
        ...props,
    });
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={currencyMask}
            placeholderChar={"\u2000"}
        />
    );
};

const InputText: React.FC<Props> = ({
    type,
    mask,
    multiline,
    minRows,
    maxRows,
    variant,
    className,
    color,
    label,
    name,
    value,
    placeholder,
    onChange,
    ref,
    error,
    helperText,
    disabled,
}) => {
    const inputsComponents: any = {
        cpf: InputMaskCpf,
        money: InputCurrency,
        cnpj: InputMaskCnpj,
        cep: InputMaskCep,
        date: InputMaskDate,
        cellphone: InputMaskCellphone,
        qtd: InputMaskQtd,
        percent: InputPercentage,
    };
    const maskChange: string = mask ?? "";
    if (maskChange in inputsComponents === true) {
        return (
            <InputCustom
                type={type || "text"}
                multiline={multiline || false}
                maxRows={maxRows || ""}
                minRows={minRows || ""}
                variant={variant || "outlined"}
                className={className || ""}
                color={color}
                label={label}
                name={name}
                ref={ref}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                error={error}
                helperText={helperText}
                autoComplete="off"
                disabled={disabled}
                InputProps={{
                    inputComponent:
                        inputsComponents[
                            maskChange as keyof typeof InputMaskCpf
                        ],
                }}
            />
        );
    }

    return (
        <InputCustom
            type={type || "text"}
            multiline={multiline || false}
            maxRows={maxRows || ""}
            minRows={minRows || ""}
            variant={variant || "outlined"}
            className={className || ""}
            color={color}
            label={label}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            error={error}
            helperText={helperText}
            autoComplete="off"
            disabled={disabled}
        />
    );
};

export default memo(InputText);
