import { Button } from "@material-ui/core";
import styled from "styled-components";

interface PropsButton {
    borderRadius?: string;
}

export const ButtonCustom = styled(Button)<PropsButton>`
    width: ${(props) => (props.fullWidth ? "100%" : "auto")};
    height: 40px;
    line-height: 14px;
    font-size: 12px;
    font-weight: 500;
    box-shadow: none !important;
    background-color: ${({ theme }: any) =>
        theme.palette.button.default.light[0]};

    &,
    .MuiButton-label {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.header {
        border-radius: 50px;
    }
    &.header-2 {
        border-radius: 20px;
    }

    .MuiButton-label {
        color: ${({ theme }: any) => theme.palette.button.default.main};
    }

    &:not(:disabled):hover {
        background-color: ${({ theme }: any) =>
            theme.palette.button.default.light[1]};

        .MuiButton-label {
            color: ${({ theme }: any) => theme.palette.button.default.dark[1]};
        }
    }

    &.MuiButton-containedPrimary {
        background-color: ${({ theme }: any) =>
            theme.palette.button.primary.light[0]};

        .MuiButton-label {
            color: ${({ theme }: any) => theme.palette.button.primary.main};
        }

        &:not(:disabled):hover {
            background-color: ${({ theme }: any) =>
                theme.palette.button.primary.dark[0]};
        }
    }

    &.MuiButton-containedSecondary {
        background-color: ${({ theme }: any) =>
            theme.palette.button.secondary.light[0]};

        .MuiButton-label {
            color: ${({ theme }: any) => theme.palette.button.secondary.main};
        }

        &:not(:disabled):hover {
            background-color: ${({ theme }: any) =>
                theme.palette.button.secondary.dark[1]};
        }
    }

    &.MuiButton-outlined {
        background-color: #fff;
        border-color: ${({ theme }: any) =>
            theme.palette.button.default.light[0]};

        .MuiButton-label {
            color: ${({ theme }: any) => theme.palette.button.default.light[0]};
        }

        &:not(:disabled):hover {
            background-color: ${({ theme }: any) =>
                theme.palette.button.default.light[0]};
            .MuiButton-label {
                color: #fff;
            }
        }
    }
    &.MuiButton-text {
        background-color: transparent;
        border-color: ${({ theme }: any) =>
            theme.palette.button.default.light[0]};

        .MuiButton-label {
            color: ${({ theme }: any) => theme.palette.button.default.light[0]};
        }

        &:not(:disabled):hover {
            background-color: transparent;
            border-color: ${({ theme }: any) =>
                theme.palette.button.default.light[0]};

            .MuiButton-label {
                color: ${({ theme }: any) =>
                    theme.palette.button.default.light[0]};
            }
        }
    }
`;
