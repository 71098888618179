import styled, { keyframes } from "styled-components";
import { AppBar, Toolbar, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { unstable_createMuiStrictModeTheme as createTheme } from "@material-ui/core/styles";

import LogoImg from "assets/images/DonaFilo.svg";
import { DeviceMediaQuery } from "assets/styled";

interface PropsNavbar {
    open: boolean;
}

export const themeNavbar = createTheme({
    palette: {
        primary: {
            main: "#404040",
            dark: "#424242",
            light: "#585858",
        },
    },
});

export const themeSearch = createTheme({
    palette: {
        primary: {
            main: "#666",
            dark: "#000",
            light: "#999",
        },
    },
});

export const NavBar = styled(AppBar).attrs({
    position: "fixed",
})<PropsNavbar>`
    box-shadow: 20px -20px 4px -1px rgb(0 0 0 / 20%),
        20px -19px 5px 0px rgb(0 0 0 / 14%), 20px -2px 10px 0px rgb(0 0 0 / 12%);
    background: #fff;

    @media ${DeviceMediaQuery.laptop} {
        transition: all 0.3s ease-in-out;
        width: ${(props) => (props.open ? `calc(100% - 250px)` : "100%")};
        min-height: 80px;
        img.logo-header {
            display: none;
        }
    }

    &:before {
        width: ${(props) => (props.open ? `calc(100% - 250px)` : "100%")};
        height: 100%;
        content: "";
        transition: all 0.3s ease-in-out;
        position: absolute;
        top: 0;
        right: 0;
    }

    & > div {
        padding: 15px 24px;
    }
`;

export const ToolBar = styled(Toolbar)``;

export const GroupLogo = styled.div`
    width: 230px;
    height: 100%;
    margin-right: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const NameProject = styled.h3`
    width: 100%;
    max-width: 330px;
    height: 100%;
    font-size: 18px;
    font-size: 600;
    color: #fff;
    transition: all 0.3s ease-in-out;
    animation-name: ${(props: any) => (!props.show ? FadeAnimation : "none")};
    animation-duration: 2s;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const GroupOptions = styled.div`
    width: calc(100% - 560px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 16px;

    @media (max-width: 768px) {
        width: auto;
    }
`;

export const Logo = styled.img.attrs({
    src: LogoImg,
})`
    max-width: 140px;
    height: auto;

    @media (max-width: 768px) {
        max-width: 200px;
    }

    @media (max-width: 340px) {
        max-width: 180px;
    }
`;

export const ButtonOpen = styled(IconButton)`
    margin-left: 0;
    padding: 5px;
    [class*="MuiSvgIcon-root"] {
        fill: ${(props) => props.theme.palette.primary.main};
    }

    &:hover {
        background-color: ${(props) => props.theme.palette.primary.dark};

        [class*="MuiSvgIcon-root"] {
            fill: ${(props) => props.theme.palette.primary.light};
        }
    }
`;

export const IconMenuOpen = styled(MenuIcon)``;

const FadeAnimation = keyframes`
 0% { opacity: 0; visibility: hidden;}
 100% { opacity: 1; visibility: visible;}
`;
