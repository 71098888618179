import { useDrawer } from "hooks/drawer";
import { useAuth } from "hooks/auth";
import { useFormik, useFormikContext } from "formik";
import * as yup from "yup";
import api from "service/api";
import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

type TYPE_AUTHORIZATION = "BASIC"|"ADMIN"
interface BodyAuthorization {
    password : string
    login : string
}

export default function useGetAuth() {    
    const { t: translate } = useTranslation();
    
    const [statusModal, setStatusModal] = useState<
    "loading" | "success" | "error" | ""
>("");

const { signIn } = useAuth()
const [message, setMessage] = useState("");
const [typeAuthorization, setTypeAuthorization] = useState<TYPE_AUTHORIZATION>("ADMIN");
const [loginMask, setloginMask] = useState<string>('cpf');

    const getAuthorization = useCallback(async (body: BodyAuthorization) => {
        setStatusModal('loading');
        try {
            await api.post(`/client/admin-authorize`, body)
            setStatusModal('success');
            setMessage('Permissão concedida com sucesso!')            
            }
        catch (e) {
            setStatusModal('error');
            setMessage(e.message)
        }
    }, [])

    const handleLoginMask = (input : string) => {
        formik.setFieldValue('login', formik.values.login.slice(0,14))
        setloginMask(input);
    }

    const handleSignIn = useCallback(async (body: BodyAuthorization) => {
        setStatusModal('loading');
        try {            
            await api.post(`/client/basic-authorize`, body)
            setStatusModal('success');
            setMessage('Permissão concedida com sucesso!')            
            }
        catch (e) {
            setStatusModal('error');
            setMessage(e.message)
        }
    }, [])

    const validationPaymentSchema = useCallback(
        () =>
            yup.object({
                login: yup
                    .string()
                    .required(translate("validation.required")),
                password: yup
                    .string()
                    .required(translate("validation.required"))            
            }),
        [translate]
    );

    const formik = useFormik({
        initialValues: {
           login: '',
           password: '',
        },
        validationSchema: validationPaymentSchema,
        onSubmit: (values, { resetForm }) => {
            const form = Object.assign({}, values);            

            if(typeAuthorization === 'BASIC'){
                handleSignIn({login: form.login.replace(/\.|\/|\-/g,'').trim(), password: form.password})
            }
            else{
                getAuthorization({...form, login: form.login.replace(/\.|\/|\-/g,'').trim()})
            }
            resetForm()
        },
    });
    
    return {
        formik,        
        message,
        statusModal,
        typeAuthorization,
        loginMask,
        handleLoginMask,
        setStatusModal,
        setTypeAuthorization,
        translate
    };
}
