import React, { memo, ReactNode } from "react";
import { FormCustom } from "./styled";

interface Props {
    children: ReactNode;
    loading?: boolean;
    disabled?: boolean;
    onSubmit?(event: React.ChangeEvent<HTMLFormElement>): void;
}

const Form: React.FC<Props> = memo(
    ({ children, onSubmit, loading, disabled }) => (
        <FormCustom
            className={loading ? "loading-form" : ""}
            onSubmit={onSubmit}
            autoComplete="off"
            method="post"
            disabled={disabled || false}
        >
            {children}
        </FormCustom>
    )
);

export default Form;
