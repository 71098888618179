import { ReactNode } from 'react';
import { useDrawerProvider, DrawerContext } from './useDrawer';

interface ChildrenProps {
  children?: ReactNode;
}

export default function Drawer({ children }: ChildrenProps) {
  const { openDrawer, activated, onOpenDrawer, onActivated } = useDrawerProvider();

  return (
    <DrawerContext.Provider
      value={{
        openDrawer,
        activated,
        onOpenDrawer,
        onActivated,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
}
