import styled from "styled-components";

import Bg from "assets/images/bg_login.png";
import { DeviceMediaQuery } from "assets/styled";

function imageBg(props: any) {
    switch (props.myTheme) {
        case "default":
            return `
                background-image: ${`url(${Bg})`};
                background-size: cover;
                background-position: center;

                &:before {
                    width: 100%;
                    height: 100%;
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            `;
        default:
            return `
                background-color: ${props.theme.palette.background.default};
            `;
    }
}

interface MainProps {
    myTheme: string;
}

export const Main = styled.main<MainProps>`
    width: 100%;
    height: 100%;
    display: flex;

    &.show-mosaic {
        & > header {
            transform: translateY(-200px);
            visibility: hidden;
            opacity: 0;
        }

        .container__full {
            & > div {
                max-width: 100%;
                padding: 0;
            }
        }
    }
`;

export const Container = styled.div`
    width: calc(100% - 260px);
    height: calc(100vh - 100px);
    transition: all 0.3s ease-in-out;
    position: relative;
    flex-grow: 1;
    background-color: #fff;
    padding: 0;
    overflow-y: auto;
    margin-top: 112px;

    @media ${DeviceMediaQuery.laptop} {
        height: calc(100vh - 20px) !important;
        margin-top: 80px !important;
    }

    &.container__full {
        margin-left: -260px;
    }
`;

export const ContainerHome = styled.div`
    width: calc(100% - 260px);
    height: 100vh;
    transition: all 0.3s ease-in-out;
    position: relative;
    flex-grow: 1;
    background-color: #dad7d7;
    padding: 0;
    overflow-y: auto;
    margin-top: 0;
    transition: opacity 0.3s ease-in-out;
`;
