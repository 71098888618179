import React, { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/button";
import Form, { InputText } from "components/form";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";

import {
    Grid,
    IconError,
    IconSuccess,
    InputsContainer,
    Loading,
    Modal,
    ModalActions,
    ModalBody,
    ModalGroupMessage,
    ModalStatus,
    ModalTextSuccess,
    ModalTitle,
} from "./styled";
import FooterButton from "components/footerButton";
import useAddRework from "./useAddRework";

type Categorie = {
    id: number;
    name: string;
    type: string;
};
type Service = {
    categorie: Categorie;
    name: string;
    id: number;
};
type Piece = {
    pieceService: {
        pieceId: number;
        serviceId: number;
    };
    needRework: false;
    hide: false;
    discount_percent: number;
    id: number;
    id_piece: number;
    category: number;
    color_piece: string;
    mark_piece: string;
    size: string;
    obs_piece: string;
    price: number;
    qtd: number;
    valor_total: number;
    service_item: {
        title: string;
        type: {
            photo: string;
            price: string;
        };
    };
    category_name: string;
    grupo: string;
};
interface Props {
    open: boolean;
    message?: string;
    listPieces: Piece[];
    orderId: number;
    onGoNext: () => void;
    onGoback: () => void;
    onClose: () => void;
}

const AddRework: React.FC<Props> = memo(
    ({ open, orderId, listPieces, message, onGoNext, onGoback, onClose }) => {
        const { t: translate } = useTranslation();
        const { status, formik, errorMessage } = useAddRework(orderId);
        // console.log(formik.values)
        const modalContent = () => {
            switch (status) {
                case "loading":
                    return (
                        <ModalStatus>
                            <Loading color="secondary" />
                        </ModalStatus>
                    );
                case "success":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconSuccess />
                                    <ModalTextSuccess>
                                        {message}
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={onGoNext}
                                        >
                                            continue
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );
                case "error":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconError />
                                    <ModalTextSuccess>
                                        {message}
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={onGoback}
                                        >
                                            {translate("modal.button.goback")}
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );

                default:
                    return (
                        <>
                            <ModalTitle>
                                Defina as Peças para Retrabalho
                            </ModalTitle>
                            <Form onSubmit={formik.handleSubmit}>
                                <ModalBody>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography>
                                                Pedido: #{orderId}
                                            </Typography>
                                        </Grid>
                                        {listPieces.map((pieces, index) => {
                                            return (
                                                <>
                                                    <Grid item xs={12}>
                                                        <InputsContainer>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        name={`pieceService[${index}]`}
                                                                        checked={
                                                                            formik
                                                                                .values
                                                                                .pieceService[
                                                                                index
                                                                            ] ??
                                                                            false
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            const aux =
                                                                                formik
                                                                                    .values
                                                                                    .pieceService[
                                                                                    index
                                                                                ]
                                                                                    ? null
                                                                                    : {
                                                                                          pieceId:
                                                                                              pieces
                                                                                                  .pieceService
                                                                                                  .pieceId,
                                                                                          serviceId:
                                                                                              pieces
                                                                                                  .pieceService
                                                                                                  .serviceId,
                                                                                      };
                                                                            formik.setFieldValue(
                                                                                `pieceService[${index}]`,
                                                                                aux
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                label=""
                                                            />

                                                            <InputText
                                                                key={crypto.randomUUID()}
                                                                label={""}
                                                                disabled={false}
                                                                // multiple={true}
                                                                name={`pieces[${index}]`}
                                                                color={
                                                                    "primary"
                                                                }
                                                                value={`Peça ${
                                                                    pieces.id_piece
                                                                } ${
                                                                    pieces?.category_name
                                                                } ${
                                                                    pieces.color_piece
                                                                } ${
                                                                    pieces.mark_piece
                                                                } ${
                                                                    pieces.size
                                                                } ${
                                                                    pieces
                                                                        .service_item
                                                                        ?.title ??
                                                                    ""
                                                                } ${
                                                                    pieces.obs_piece
                                                                }`}
                                                            />
                                                        </InputsContainer>
                                                    </Grid>
                                                </>
                                            );
                                        })}
                                        <Grid item xs={12}>
                                            <FooterButton>
                                                <Button type="submit">
                                                    {translate(
                                                        "modal.button.add"
                                                    )}
                                                </Button>
                                                <Button
                                                    onClick={onClose}
                                                    variant="outlined"
                                                >
                                                    {translate(
                                                        "modal.button.cancel"
                                                    )}
                                                </Button>
                                            </FooterButton>
                                        </Grid>
                                    </Grid>
                                </ModalBody>
                            </Form>
                        </>
                    );
            }
        };

        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                scroll="body"
            >
                {modalContent()}
            </Modal>
        );
    }
);

export default AddRework;
