import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/button";

import {
    IconError,
    IconSuccess,
    Loading,
    Modal,
    ModalActions,
    ModalBody,
    ModalGroupMessage,
    ModalStatus,
    ModalTextSuccess,
    ModalTitle,
    StatusGroup,
} from "./styled";
import useListOrdersFilters from "./useListOrdersFilters";

import { format } from "date-fns";
import { ptBR as ptBRDate } from "date-fns/locale";

import { ButtonActionTable } from "components/button";
import { sum } from "lodash";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    ptBR,
} from "@mui/x-data-grid";
import { formatMoney } from "utils/Utils";

type Error = {
    name?: string;
};

interface Props {
    open: boolean;
    message?: string;
    statusId?: number;
    startDate: any;
    endDate: any;
    onClose: () => void;
}

const ListOrdersFilters: React.FC<Props> = memo(
    ({ open, message, statusId, startDate, endDate, onClose }) => {
        const { t: translate } = useTranslation();

        const {
            status,
            statusPedido,
            loadingGrid,
            listListOrder,
            handlePageChange,
            goPage,
        } = useListOrdersFilters(statusId, open, startDate, endDate);

        const RenderPartnerName = ({ row }: GridRenderCellParams<any>) => {
            try {                
                const status = row.actualStatus.filter(status => status.status == statusId)                
                return (
                    <div style={{cursor:'pointer'}} onClick={() => goPage(`/parceiro/${status[0]?.partners[0]?.id}`)}>

                        {status[0]?.partners[0]?.profile?.name ?? ''}
                    </div>
                );
            } catch (error) {
            }
        };
        const RenderCoustumerName = ({ row }: GridRenderCellParams<any>) => {
            try {                                           
                return (
                    <div style={{cursor:'pointer'}} onClick={() => goPage(`/cliente/${row.costumer.id}`)}>

                        {row.costumer.profile.name ??  ''}
                    </div>
                );
            } catch (error) {
            }
        };

        const RenderAddressName = ({ row }: GridRenderCellParams<any>) => {
            try {   
                const address = row.costumer.address[0]                                       
                return (
                    <>
                        {`${address.address_name ?? ''} ${address.address_number ?? ''} - ${address.neighborhood ?? ''}`}
                    </>

                );
            } catch (error) {
            }
        };

        const RenderStatusOrder = ({ row }: GridRenderCellParams<any>) => {
            try {
                let statuses = statusPedido
                    .filter((item) => item.id == row.last_status)
                    .pop();

                return (
                    <StatusGroup className={statuses.value}>
                        {statuses.label}
                    </StatusGroup>
                );
            } catch (error) {
                return (
                    <StatusGroup className={"sem_status"}>
                        SEM STATUS API
                    </StatusGroup>
                );
            }
        };

        const RenderAction = ({ row }: GridRenderCellParams<any>) => {
            return (
                <ButtonActionTable
                    items={[
                        {
                            label: "Editar",
                            onClick: () => {
                                goPage(`/pedido/${row?.id}`);
                            },
                        },
                    ]}
                />
            );
        };

        const RenderPhone = ({ row }: GridRenderCellParams<any>) => {
            const phone = row.costumer.phone.replace(/(\d{2})(\d{4})(\d{5})/, '($1) $2-$3')
            return (
                <>

                {phone ?? ''}
            </>
            );
        };

        const RenderOrderId = ({ row }: GridRenderCellParams<any>) => {            
            return (
                <div style={{cursor:'pointer'}} onClick={() => goPage(`/pedido/${row?.id}`)}>

                    {row.id}
                </div>
            );
        };

        const columnsTable: GridColDef[] = [
            {
                field: "id",
                flex: 0.2,
                headerName: "Ordem",
            },
            {
                field: "costumer",
                flex: 0.6,
                headerName: "Cliente",
                renderCell: RenderCoustumerName,
            },
            {
                field: "address",
                flex: 0.8,
                headerName: "Endereço",
                renderCell: RenderAddressName,
            },
            {
                field: "delivery_app",
                flex: 0.8,
                headerName: "Data de retirada",
                valueFormatter: ({ value }) => {
                    return format(
                        new Date(value),
                        translate("format.datetime"),
                        {
                            locale: ptBRDate,
                        }
                    );
                },
            },
            {
                field: "actualStatus.",
                flex: 0.8,
                headerName: "Parceiro",
                renderCell: RenderPartnerName,
            },
            {
                field: "actualStatus",
                flex: 0.4,
                headerName: "Status",
                renderCell: RenderStatusOrder,
            },
            {
                field: "action",
                flex: 0.3,
                headerName: "",
                sortable: false,
                filterable: false,
                hideable: false,
                renderCell: RenderAction,
            },
        ];

        const columnsTable2: GridColDef[] = [
            {
                field: "id",
                flex: 0.2,
                headerName: "Comanda",
                renderCell: RenderOrderId,
            },
            {
                field: "costumer.phone",
                flex: 0.3,
                headerName: "Telefone",
                renderCell: RenderPhone,
            },
            {
                field: "actualStatus.",
                flex: 0.8,
                headerName: "Parceiro",
                renderCell: RenderPartnerName,
            },
            {
                field: "delivery",
                flex: 0.3,
                headerName: "Data e Hora",
                valueFormatter: ({ value }) => {
                    return format(
                        new Date(value),
                        translate("format.datetime"),
                        {
                            locale: ptBRDate,
                        }
                    );
                },
            },
            {
                field: "action",
                flex: 0.2,
                headerName: "",
                sortable: false,
                filterable: false,
                hideable: false,
                renderCell: RenderAction,
            },
        ];

        const modalContent = () => {
            switch (status) {
                case "loading":
                    return (
                        <ModalStatus>
                            <Loading color="secondary" />
                        </ModalStatus>
                    );
                case "success":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconSuccess />
                                    <ModalTextSuccess>
                                        Pagamento realizado com sucesso!
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button onClick={onClose}>
                                            {translate("modal.button.goback")}
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );
                case "error":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconError />
                                    <ModalTextSuccess>
                                        Erro ao salvar o pagamento!
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={onClose}
                                        >
                                            {translate("modal.button.goback")}
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );

                default:
                    return (
                        <>
                            <ModalTitle>{statusId === 2 ? 'Agenda de Coleta' : 'Agenda de Entregas'}</ModalTitle>
                            <ModalBody>
                                <div style={{ height: "70vh", width: "100%" }}>
                                    <DataGrid
                                        localeText={
                                            ptBR.components.MuiDataGrid
                                                .defaultProps.localeText
                                        }
                                        rows={listListOrder.data}
                                        columns={statusId === 2 ? columnsTable : columnsTable2}
                                        pagination
                                        pageSize={10}
                                        rowsPerPageOptions={[10]}
                                        rowCount={listListOrder.meta.total ?? 0}
                                        paginationMode="server"
                                        onPageChange={handlePageChange}
                                        page={
                                            (listListOrder.meta.current_page ??
                                                1) - 1
                                        }
                                        loading={loadingGrid}
                                    />
                                </div>
                            </ModalBody>
                        </>
                    );
            }
        };

        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                scroll="body"
            >
                {modalContent()}
            </Modal>
        );
    }
);

export default ListOrdersFilters;
