import React, { memo, ReactNode } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as ThemeStyledProvider } from 'styled-components';

import main from 'assets/styled/themes';

interface Props {
  children: ReactNode;
}

const Theme: React.FC<Props> = ({ children }) => (
  <ThemeProvider theme={main}>
    <ThemeStyledProvider theme={main}>{children}</ThemeStyledProvider>
  </ThemeProvider>
);

export default memo(Theme);
