import styled from "styled-components";
import { Avatar } from "@material-ui/core";

interface PropsItemList {
    error?: boolean;
}

export const DropZone = styled.div<PropsItemList>`
    height: 138px;
    width: 100%;
    border-radius: 5px;
    background: #fafafa;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    border: 1px dashed #eaeaea;
`;

export const AvatarCustom = styled(Avatar)`
    box-shadow: 0px 4px 30px 0px #9b999990;
    margin-top: 20px;
`;

export const ErrorField = styled.p`
    margin-left: 14px;
    margin-right: 14px;
    color: #b71c1c;
    line-height: 1.3;
    font-size: 0.75rem;
`;
