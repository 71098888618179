import { useState, useContext, createContext, useCallback } from "react";

type Activated = {
    nameModule?: string;
    menu: string;
    submenu: string;
};

interface Context {
    openDrawer: boolean;
    activated: Activated | null;
    onOpenDrawer(value: boolean): void;
    onActivated(nameModule: string, menu: string, submenu: string): void;
}

export const DrawerContext = createContext<Context>({
    openDrawer: true,
    activated: null,
    onOpenDrawer: (value: boolean) => {},
    onActivated: (nameModule: string, menu: string, submenu: string) => {},
});

export function useDrawer() {
    const context = useContext(DrawerContext);
    return context;
}

export function useDrawerProvider() {
    const [openDrawer, setOpenDrawer] = useState(true);
    const [activated, setActivated] = useState<Activated | null>(null);

    const onOpenDrawer = useCallback((value: boolean) => {
        setOpenDrawer(value);
    }, []);

    const onActivated = useCallback(
        (nameModule: string, menu: string, submenu: string) => {
            setActivated({ nameModule, menu, submenu });
        },
        []
    );

    return {
        openDrawer,
        activated,
        onOpenDrawer,
        onActivated,
    };
}
