import React, { memo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  condition: boolean;
}

const Visible: React.FC<Props> = memo(({ condition, children }) => {
  if (condition) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return null;
});

export default Visible;
