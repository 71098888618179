import { useState, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuth } from "hooks/auth";
import { useDrawer } from "hooks/drawer";
import { setPrefix } from "utils";
import { useFormik, useFormikContext } from "formik";
import * as yup from "yup";
import api from "service/api";
import { formatMoney, moneyToFloat } from "utils/Utils";
import { format } from "date-fns";

export interface PropList {
    meta: Meta;
    data: Datum[];
}

export interface Datum {
    id?: number;
    client_id?: number;
    name?: string;
    category?: string;
    price?: string;
    dimension?: string;
    photo?: string;
    weight?: string;
    created_at?: Date;
    updated_at?: Date;
}

export interface Meta {
    total?: number;
    per_page?: number;
    current_page?: number;
    last_page?: number;
    first_page?: number;
    first_page_url?: string;
    last_page_url?: string;
    next_page_url?: string;
    previous_page_url?: null;
}

export default function useListOrdersFilters(statusId: any, open: any, startDate : any, endDate : any,) {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const [status, setStatus] = useState<"loading" | "success" | "error" | "">(
        ""
    );

    const [loadingGrid, setLoadingGrid] = useState(false);
    const initialItems = useMemo(
        () => ({
            data: [],
            meta: {},
        }),
        []
    );
    const [listListOrder, setListListOrder] = useState(
        initialItems as PropList
    );

    const handlePageChange = (newPage: number) => {
        // getData(newPage + 1);
    };

    const statusPedido = [
        { label: "Todos os Status", value: "0", id: 0 },
        { label: "Solicitado", value: "requested", id: 1 },
        { label: "Em Coleta", value: "withdrawn", id: 2 },
        { label: "Coletado", value: "confirmed_withdrawal", id: 3 },
        { label: "Pedido Editado", value: "changed_items", id: 4 },
        { label: "Aprovado", value: "approved", id: 5 },
        { label: "Falta Pagamento", value: "missing_payment", id: 6 },
        { label: "Na Oficina", value: "in_workshop", id: 7 },
        { label: "A Caminho da Entrega", value: "out_for_delivery", id: 8 },
        { label: "Pronto para Entrega", value: "awaiting_delivery", id: 9 },
        { label: "Finalizado", value: "delivered", id: 10 },
        { label: "Cancelado", value: "canceled", id: 11 },
        { label: "Disponibilização", value: "in_worked", id: 12 },
        { label: "Retrabalho ", value: "rework", id: 13 },
    ];

    const getData = useCallback(async (pageNumber = 1) => {
        setStatus("loading");
        try {
            const data = <PropList>await api.get(`/client/order/show`, {
                params: {
                    page: pageNumber,
                    status: statusId,
                    must_return_partner_name: true,
                    isDeliveryDate: statusId === 12 ? 1 : 0,
                    date_ini: statusId === 2 ? null : format(startDate, "yyyy-MM-dd"),
                    date_end: statusId === 2 ? null : format(endDate ?? new Date(), "yyyy-MM-dd"),
                },
            });
            setListListOrder(data);
        } catch (error) {}
        setStatus("");
    }, [statusId]);

    const goPage = useCallback(
        (link: string) => {
            navigate(setPrefix(link));
        },
        [navigate]
    );

    useEffect(() => {
        if (open) {
            getData();
        }
        return () => {
            // setStatus("");
        };
    }, [open]);

    return {
        status,
        statusPedido,
        goPage,
        loadingGrid,
        listListOrder,
        handlePageChange,
        translate,
    };
}
