import React, { memo, useCallback, useState } from "react";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ButtonGroupCustom } from "./styled";
import { Button, ButtonActionTable } from "components/button";
interface Props {
    valueInitial?: number;
    valueMax?: number;
    valueMin?: number;
    error?: boolean;
    onChange?: Function;
}

const InputQuantity: React.FC<Props> = ({
    valueMax = 100,
    valueMin,
    valueInitial,
    onChange,
}) => {
    // return <BsCheck2Circle color={"#2BC155"} fontSize="32px" />;

    const [valueStep, setValueStep] = useState(valueInitial ?? 0);

    const step = useCallback(
        async (type: "minus" | "more") => {
            let newVal = valueStep;
            if (type == "minus") {
                newVal = valueStep - 1;
            }
            if (type == "more") {
                newVal = valueStep + 1;
            }
            // onChange(Math.min(Math.max(newVal, valueMin), valueMax));
            onChange.apply(null, [
                Math.min(Math.max(newVal, (valueMin !== undefined && valueMin !== null ? valueMin : 1)), valueMax),
            ]);
            setValueStep(Math.min(Math.max(newVal, (valueMin !== undefined && valueMin !== null ? valueMin : 1)), valueMax));
        },
        [valueStep]
    );

    return (
        <ButtonGroupCustom>
            <Button onClick={() => step("minus")}>
                <FontAwesomeIcon
                    icon={faChevronLeft}
                    color={"#fff"}
                    fontSize="16px"
                />
            </Button>
            <input type={"number"} value={valueStep} readOnly />
            <Button onClick={() => step("more")}>
                <FontAwesomeIcon
                    icon={faChevronRight}
                    color={"#fff"}
                    fontSize="16px"
                />
            </Button>
        </ButtonGroupCustom>
    );
};

export default memo(InputQuantity);
