import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuth } from "hooks/auth";

import { setPrefix } from "utils";

export default function useMenuSettings() {
    const { profile, onLogout } = useAuth();

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const { t: translate } = useTranslation();

    const open = Boolean(anchorEl);

    const handleOpen = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        },
        []
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const goToPage = useCallback(
        (link: string) => {
            handleClose();
            setTimeout(() => {
                navigate(setPrefix(link));
            }, 500);
        },
        [handleClose, navigate]
    );

    return {
        profile,
        anchorEl,
        open,
        translate,
        handleOpen,
        handleClose,
        goToPage,
        onLogout,
    };
}
