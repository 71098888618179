export interface PropsMenus {
    name: string;
    icon: string;
    href: string;
    childrens?: PropsMenus[];
}

export const MenusNaveBarDelivery = <PropsMenus[]>[
    {
        name: "Dashboard",
        icon: "",
        href: "/",
        childrens: [],
    },
    {
        name: "Pedidos Online",
        icon: "",
        href: "/pedidos",
        childrens: [
            {
                name: "Lista de Pedidos",
                icon: "",
                href: "/pedidos",
            },
            {
                name: "Criar Pedido",
                icon: "",
                href: "/criar-pedido-online/novo",
            },
            {
                name: "Gestão Entrega",
                icon: "",
                href: "/agendamento/entrega-online",
            },
            {
                name: "Gestão Coleta",
                icon: "",
                href: "/agendamento/coleta",
            },
        ],
    },
    {
        name: "Produtos",
        icon: "",
        href: "/produtos",
        childrens: [
            {
                name: "Lista de Produtos",
                icon: "",
                href: "/produtos",
            }
        ],
    },
    {
        name: "Controle de Caixa",
        icon: "",
        href: "/controle-caixa",
        childrens: [],
    },
    {
        name: "Clientes",
        icon: "",
        href: "/clientes",
        childrens: [
            {
                name: "Lista Clientes",
                icon: "",
                href: "/clientes",
            },
            {
                name: "Cadastrar Cliente",
                icon: "",
                href: "/cliente/novo",
            },
        ],
    },
];
