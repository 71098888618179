import {
    useState,
    useEffect,
    useContext,
    createContext,
    useCallback,
} from "react";
import { useTranslation } from "react-i18next";

import api from "service/api";

import { useNotification } from "hooks/notification";

import { PropsSelect } from "models";

type Modules = {
    id: string | number;
    name: string;
};

type User = {
    id?: number;
    session_id?: number;
    levelAccess?: number;
    levelAccessName?: string;
    firstAccess?: number;
    document?: number;
    name?: string;
    photo?: string;
    created_at?: Date;
    updated_at?: Date;
    states: PropsSelect[];
    modules: Modules[] | [];
};

interface Props {
    loadingPage: boolean;
    loadingForm: boolean;
    signed: boolean;
    canEdit: boolean;
    clientId: number;
    profile: User | null;
    userType: string;
    clientDetails: Clients;
    signIn(login: string, password: string): void;
    signOut(): void;
    onLogout(): void;
    onChageProfile({ ...props }): void;
    sendCodeToEmail(email: string, callback?: Function): Promise<void>;
    handleCanEdit(state:boolean) : void
}

interface Clients{
    id?: number;
    name?: string;
    phone?: string;
}

interface Sessions {
    id?: number;
    client_id?: number; 
    type: string; 
    phone?: string | number;
    document?: string;
    remember_me_toke?:string; 
    collect?: number; 
    delivery?: number|boolean; 
    measurement?: number;
    workshop?: number;
    clients?: Clients;
    active_notification?: number; 
    deleted_at?: string| null; 
    created_at?: string;
    updated_at?: string;
}

interface PropsResponseLoginError {
    code?: string;
    message?: string;
    status?: number;
}
interface PropsResponseLoginSuccess {
    type: string;
    token: string;
}

export const AuthContext = createContext({} as Props);

export function useAuth() {
    const context = useContext(AuthContext);
    return context;
}

export function useAuthProvider() {    
    const { openNotification } = useNotification();
    
    const [loadingPage, setLoadingPage] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [signed, setSigned] = useState(false);
    const [profile, setProfile] = useState<User | null>(null);
    const [userType, setUserType] = useState<any>(null);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [clientId, setClientId] = useState<number>(0);
    const [clientDetails, setclientDetails] = useState<Clients>({});

    const { t: translate } = useTranslation();

    const canEditRole = ['admin', 'sysadmin']

    const signIn = useCallback(
        async (login: string, password: string): Promise<void> => {
            setLoadingForm(true);

            try {
                const data = <PropsResponseLoginSuccess>await api.post(
                    "/sessions/login",
                    {
                        document: login.replace(/[^0-9]/g, ""),
                        password: password,
                    }
                );

                const { type, token } = data;

                if (data) {
                    localStorage.setItem(
                        `@${process.env.REACT_APP_NAME}:access_token`,
                        token ?? ""
                    );
                    api.defaults.headers.common[
                        "Authorization"
                    ] = `Bearer ${token}`;
                    getInfoUser();
                    // setSigned(true);
                }
            } catch (error: any) {
                const data = <PropsResponseLoginError>error;
                openNotification(data.message ?? "Erro inesperado", "error");
            } finally {
                setLoadingPage(false);
                setLoadingForm(false);
            }
        },
        [openNotification, translate]
    );

    const signOut = useCallback(() => {
        localStorage.removeItem(`@${process.env.REACT_APP_NAME}:access_token`);
        delete api.defaults.headers.common["Authorization"];
        setSigned(false);
        setProfile(null);
        setLoadingPage(false);
    }, []);

    const getInfoUser = useCallback(async () => {
        try {
            const data = <User>await api.get("/profile");
            const datatype = <Sessions><unknown>await api("/sessions/getSessions");
            
            if (!data.id) {
                setSigned(false);
                setLoadingPage(false);
                return;
            }


            setUserType(datatype.type)
            setClientId(datatype.client_id)
            setclientDetails(datatype.clients)
            setCanEdit(canEditRole.includes(datatype.type))
            setProfile(
                (oldProfile) =>
                    ({
                        ...oldProfile,
                        ...data,
                    } as User)
            );
            setSigned(true);
        } catch (error: any) {
            const { data } = error;
            openNotification(translate(`validation.${data.error}`), "error");
        } finally {
            setLoadingPage(false);
        }
    }, [openNotification, translate]);

    const sendCodeToEmail = useCallback(
        async (email: string, callback?: Function): Promise<void> => {
            setLoadingForm(true);

            try {
                const {
                    data: { success, message },
                } = await api.post("/password/email", {
                    email,
                });

                if (success) {
                    openNotification(
                        translate(`validation.${message}`),
                        "success"
                    );
                    if (callback) callback();
                }
            } catch (error: any) {
                const { data } = error;
                openNotification("Não implementado na api", "error");
            } finally {
                setLoadingForm(false);
            }
        },
        [openNotification, translate]
    );

    const onLogout = useCallback(async () => {
        setLoadingPage(true);
        // const {
        //     data: { success },
        // } = await api.put(`/logout`);
        // if (success) {
        // setTimeout(() => {
        signOut();
        // }, 1000);
        // }
    }, [signOut]);

    const onChageProfile = useCallback(({ ...props }) => {
        setProfile(
            (oldProfile) =>
                ({
                    ...oldProfile,
                    ...props,
                } as User)
        );
    }, []);

    const handleCanEdit = useCallback((state : boolean) => {        
        setCanEdit(state || canEditRole.includes(userType))
    }, [canEdit, userType])

    useEffect(() => {
        const accessToken = localStorage.getItem(
            `@${process.env.REACT_APP_NAME}:access_token`
        );

        // console.log(`accessToken:: ${accessToken}`);

        if (accessToken && !signed && profile === null) {
            api.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${accessToken}`;

            getInfoUser();
        }

        if (!accessToken && profile === null) {
            setLoadingPage(false);
        }
    }, [signed, profile, getInfoUser]);

    return {
        loadingPage,
        loadingForm,
        signed,
        profile,
        userType,
        canEdit,
        clientId,
        clientDetails,
        signIn,
        signOut,
        onLogout,
        onChageProfile,
        sendCodeToEmail,
        handleCanEdit
    };
}
