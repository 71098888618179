/**
 * Função que seta um prefixo em todas as páginas
 * @method setPrefix
 * @param {string} link recebe a url uma url
 */
export function setPrefix(link: string) {
    // if (process.env.REACT_APP_BASE_URL !== "/") {
    //     return `/${process.env.REACT_APP_BASE_URL?.toString()}${link}`;
    // }

    return link;
}

export function getHours(filter?: string) {
    const horarios = [];
    let hora = new Date();
    hora.setHours(7);
    hora.setMinutes(30);
    hora.setSeconds(0);

    const horaFinal = new Date();
    horaFinal.setHours(18);
    horaFinal.setMinutes(30);
    horaFinal.setSeconds(0);

    while (hora <= horaFinal) {
        const horaFormatada = hora.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        horarios.push(horaFormatada);
        hora.setMinutes(hora.getMinutes() + 90);
    }

    return filter ? horarios.filter(hours => hours !== filter) : horarios;
}

/**
 * Função que linca para uma página
 * @method goToPage
 * @param {string} link recebe a url da página
 */
export function goToPage(link: string) {
    return link;
}

/**
 * Função que válida um e-mail
 * @method validateEmail
 * @param { string } email recebe o E-mail
 */
export function validateEmail(email: string) {
    const regexEmail =
        /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (regexEmail.test(email)) {
        return true;
    } else {
        return false;
    }
}

/**
 * Função que linca para uma página
 * @method debounce
 * @param {Function} callback recebe uma função
 * @param {number} delay recebe um tempo em segundos
 */
export function debounce(callback: (...params: any[]) => any, delay: number) {
    let inDebounce: ReturnType<typeof setTimeout>;

    return function (this: any, ...args: any[]) {
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => callback.apply(this, args), delay);
    };
}

/**
 * Função que retorna um texto curto
 * @method shortText
 * @param {string} text recebe uma string
 * @param {number} limit recebe o total de caracteres para retornar
 */
export function shortText(text: string, limit: number) {
    if (text.length > limit) {
        return `${text.substring(0, limit)}...`;
    }

    return text;
}

/**
 * Função que converte um arquvio base64
 * @method generateBase64
 * @param {any} file recebe uma string
 */
export function generateBase64(file: any) {
    return new Promise((resolve) => {
        let baseURL = null;
        let reader = new FileReader() as any;

        reader.readAsDataURL(file);

        reader.onload = () => {
            baseURL = reader.result.split(";base64,")[1];
            resolve(baseURL);
        };
    });
}

/**
 * Função que retorna o primeiro e o último nome
 * @method shortName
 * @param {string} fullname recebe uma string
 */
export function shortName(fullname: string) {
    if (typeof fullname == "undefined") {
        return "";
    }
    if (isEmpty(fullname)) {
        return "";
    }
    const [firstName, lastName] = fullname.split(" ").filter(Boolean);
    if (lastName) {
        return `${firstName} ${lastName}`;
    }

    return firstName;
}

/**
 * Função que retorna o valor sem a mascara
 * @method unmask
 * @param {string} value recebe uma string
 */
export function unmask(value: string) {
    return value.replace(/[^\d]+/g, "");
}

/**
 * Função que retorna uma string com o formato titilize
 * @method toSlugText
 * @param {string} value recebe uma string
 */
export function toSlugText(value: string) {
    return value
        .toString()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 ]/g, "")
        .replace(/\s+/g, "-");
}

/**
 * Função que retorna a extensão do arquivo
 * @method getExtension
 */
export function getExtension() {
    return /\.([0-9a-z]+)(?:[?#]|$)/i;
}

/**
 * Função que retorna o link para redirecionar para o módulos correto
 * @method linkRedirectPage
 * @param {number} module_id recebe uma string
 */
export function linkRedirectPage(module_id: number) {
    let page = setPrefix("/");
    return page;
}

/**
 * Função que verifica se o valor está vazio
 * @method isEmpty
 * @param {string} value recebe uma string
 */
export function isEmpty(value: string) {
    if (value === "") {
        return "";
    }

    return value;
}

export function formatMoney(money: any) {
    let moneyNew = parseFloat(money);
    moneyNew = !isNaN(moneyNew) ? moneyNew : 0;
    return moneyNew.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        style: "currency",
        currency: "BRL",
    });
}
export function moneyToFloat(money: any) {
    return parseFloat(
        `${money}`
            .replace(".", "")
            .replace(",", ".")
            .replace("R", "")
            .replace("$", "")
            .replace(" ", "")
            .replace(":", "")
            .trim()
    );
}

function number_format(number, decimals, dec_point, thousands_point) {
    if (number == null || !isFinite(number)) {
        throw new TypeError("number is not valid");
    }

    if (!decimals) {
        var len = number.toString().split(".").length;
        decimals = len > 1 ? len : 0;
    }

    if (!dec_point) {
        dec_point = ".";
    }

    if (!thousands_point) {
        thousands_point = ",";
    }

    number = parseFloat(number).toFixed(decimals);

    number = number.replace(".", dec_point);

    var splitNum = number.split(dec_point);
    splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
    number = splitNum.join(dec_point);

    return number;
}

export function maskFormat(value: any, pattern: string) {
    try {
        var strippedValue = value.replace(/[^A-Za-z0-9 ]/g, "");
        var chars = strippedValue.split("");
        var count = 0;
        var formatted = "";

        for (var i = 0; i < pattern.length; i++) {
            let c = pattern[i];
            if (chars[count]) {
                if (/\*/.test(c)) {
                    formatted += chars[count];
                    count++;
                } else {
                    formatted += c;
                }
            }
        }
        return formatted;
    } catch (e) {
        return "";
    }
}
