import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/button";

import {
    IconError,
    IconSuccess,
    Loading,
    Modal,
    ModalActions,
    ModalBody,
    ModalGroupMessage,
    ModalStatus,
    ModalTextSuccess,
    ModalTitle,
    StatusGroup,
} from "./styled";
import useListCustomerOrders from "./useListCustomerOrders";

import { format } from "date-fns";
import { ptBR as ptBRDate } from "date-fns/locale";

import { ButtonActionTable } from "components/button";
import { sum } from "lodash";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    ptBR,
} from "@mui/x-data-grid";
import { formatMoney } from "utils/Utils";

type Error = {
    name?: string;
};

interface Props {
    open: boolean;
    message?: string;
    customerId?: any;
    onClose: () => void;
}

const ListCustomerOrders: React.FC<Props> = memo(
    ({ open, message, customerId, onClose }) => {
        const { t: translate } = useTranslation();

        const {
            status,
            statusPedido,
            loadingGrid,
            listListOrder,
            handlePageChange,
            goPage,
        } = useListCustomerOrders(customerId, open);

        const RenderStatusOrder = ({ row }: GridRenderCellParams<any>) => {
            try {
                let statuses = statusPedido
                    .filter((item) => item.id == row.last_status)
                    .pop();

                return (
                    <StatusGroup className={statuses.value}>
                        {statuses.label}
                    </StatusGroup>
                );
            } catch (error) {
                return (
                    <StatusGroup className={"sem_status"}>
                        SEM STATUS API
                    </StatusGroup>
                );
            }
        };
        const RenderValorPago = ({ row }: GridRenderCellParams<any>) => {
            try {
                let valoresPagos = row.paymentsOrder.map((item) => {
                    return parseFloat(
                        item.type_payment == 4 ? "0" : item.value
                    );
                });
                return formatMoney(sum(valoresPagos));
            } catch (error) {
                console.log(error);
                return "";
            }
        };
        const RenderAction = ({ row }: GridRenderCellParams<any>) => {
            return (
                <ButtonActionTable
                    items={[
                        {
                            label: "Editar",
                            onClick: () => {
                                goPage(`/pedido/${row?.id}`);
                            },
                        },
                    ]}
                />
            );
        };

        const columnsTable: GridColDef[] = [
            {
                field: "id",
                flex: 0.2,
                headerName: "ID Pedido",
            },
            {
                field: "created_at",
                flex: 0.6,
                headerName: "Data do Pedido",
                valueFormatter: ({ value }) => {
                    return format(
                        new Date(value),
                        translate("format.datetime"),
                        {
                            locale: ptBRDate,
                        }
                    );
                },
            },
            {
                field: "type",
                flex: 0.8,
                headerName: "Canal",
                valueFormatter: ({ value }) => {
                    return `${value == 1 ? "Online" : "Loja"}`;
                },
            },
            {
                field: "total_value",
                flex: 0.8,
                headerName: "Valor Faturado",
                valueFormatter: ({ value }) => {
                    return `${formatMoney(value)}`;
                },
            },
            {
                field: "paymentsOrder",
                flex: 0.8,
                headerName: "Valor Pago",
                renderCell: RenderValorPago,
            },
            {
                field: "actualStatus",
                flex: 0.4,
                headerName: "Status",
                renderCell: RenderStatusOrder,
            },
            {
                field: "action",
                flex: 0.3,
                headerName: "",
                sortable: false,
                filterable: false,
                hideable: false,
                renderCell: RenderAction,
            },
        ];

        const modalContent = () => {
            switch (status) {
                case "loading":
                    return (
                        <ModalStatus>
                            <Loading color="secondary" />
                        </ModalStatus>
                    );
                case "success":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconSuccess />
                                    <ModalTextSuccess>
                                        Pagamento realizado com sucesso!
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button onClick={onClose}>
                                            {translate("modal.button.goback")}
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );
                case "error":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconError />
                                    <ModalTextSuccess>
                                        Erro ao salvar o pagamento!
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={onClose}
                                        >
                                            {translate("modal.button.goback")}
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );

                default:
                    return (
                        <>
                            <ModalTitle>Histórico de Pedidos</ModalTitle>
                            <ModalBody>
                                <div style={{ height: "70vh", width: "100%" }}>
                                    <DataGrid
                                        localeText={
                                            ptBR.components.MuiDataGrid
                                                .defaultProps.localeText
                                        }
                                        rows={listListOrder.data}
                                        columns={columnsTable}
                                        pagination
                                        pageSize={10}
                                        rowsPerPageOptions={[10]}
                                        rowCount={listListOrder.meta.total ?? 0}
                                        paginationMode="server"
                                        onPageChange={handlePageChange}
                                        page={
                                            (listListOrder.meta.current_page ??
                                                1) - 1
                                        }
                                        loading={loadingGrid}
                                    />
                                </div>
                            </ModalBody>
                        </>
                    );
            }
        };

        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                scroll="body"
            >
                {modalContent()}
            </Modal>
        );
    }
);

export default ListCustomerOrders;
