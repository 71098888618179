import { useState, useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useDrawer as useDrawerMain } from "hooks/drawer";
import { useAuth } from "hooks/auth";

import { setPrefix } from "utils";

export default function useDrawerPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { profile } = useAuth();
    const [expanded, setExpanded] = useState<string | false>(false);

    const { openDrawer, activated } = useDrawerMain();

    const handleChange = useCallback(
        (panel: string) =>
            (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
                setExpanded(isExpanded ? panel : false);
            },
        []
    );

    const goToPage = useCallback(
        (link: string) => {
            navigate(setPrefix(link));
        },
        [navigate]
    );

    useEffect(() => {
        const items = location.pathname
            .split("/")
            .filter((item) => item !== "");
        setExpanded(items[0]);
    }, [location.pathname]);

    return {
        profile,
        expanded,
        openDrawer,
        activated,
        handleChange,
        goToPage,
    };
}
