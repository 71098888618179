import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import MainProvider from "hooks/main";
import { Theme } from "components/layout";
import Routes from "routes";

import { GlobalStyled } from "assets/styled";

import "locales";

const App: React.FC = () => {
    return (
            <MainProvider>
                <Theme>
                    <CssBaseline />
                    <GlobalStyled />
                    <Routes />
                </Theme>
            </MainProvider>
    );
};

export default App;
