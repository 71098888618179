import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useDrawer } from "hooks/drawer";
import { chunk, last, orderBy, sum } from "lodash";

import { useFormik } from "formik";
import * as yup from "yup";

import {
    ActualStatus,
    PropCategorias,
    PropCategoriasSelect,
    PropOrderShow,
    PropServices,
} from "./Interface";

import { useAuth } from "hooks/auth";

import { format, isBefore } from "date-fns";
import { useNotification } from "hooks/notification";
import { PaymentsOptions } from "pages/formPartner/Interface";
import api from "service/api";
import apiLocal from "service/apiLocal";
import { setPrefix } from "utils";

import { ptBR as ptBRDate } from "date-fns/locale";

type TypeNotification = {
    status: "error" | "warning" | "info" | "success";
};

export default function useDetailOrder() {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [rowsPieces, setRowsPieces] = useState([]);
    const [rowsPiecesAll, setRowsPiecesAll] = useState([]);
    const [statusPedido, setStatusPedido] = useState([]);
    const [pieces, setPieces] = useState([]);
    const [optionsPayment, setOptionsPayment] = useState<String>(null);

    const [startDate, setStartDate] = useState(new Date());
    const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
    const [openGetAuth, setOpenGetAuth] = useState(false);
    const [promotionCredit, setPromotionCredit] = useState(0);
    const [ignoreValidate, setIgnoreValidate] = useState(false);
    const [editPartner, setEditPartner] = useState([-1]);

    const handleChangeDate = async (e) => {
        const dtRef = new Date();
        dtRef.setHours(0);
        dtRef.setMinutes(0);
        dtRef.setSeconds(0);
        dtRef.setMilliseconds(0);

        if (isBefore(e, dtRef)) return;

        setIsOpenDatePicker(!isOpenDatePicker);
        setStartDate(e);

        setLoading(true);
        try {
            let response = await api.put(
                `/client/order/alter-delivery-date/${id}`,
                {
                    delivery: format(e, "yyyy-MM-dd H:m:00", {
                        locale: ptBRDate,
                    }),
                }
            );
            setLoading(false);
            show(parseInt(id));
        } catch (error) {
            setLoading(false);
            openNotification(translate("formService.submit.error"), "error");
            return false;
        }
    };
    const handleClickDate = () => {
        setIsOpenDatePicker(!isOpenDatePicker);
    };

    const {
        userType,
        profile: userProfile,
        canEdit,
        clientDetails,
        handleCanEdit,
    } = useAuth();

    const notAllow = useMemo(() => {
        return {
            11: "Cancelado",
            6: "Falta Pagamento",
        };
    }, []);

    const initialPaymentsOptions = useMemo(
        () => [
            {
                title: "",
                value: "",
            },
        ],
        []
    );
    const listReasonsOptions = useMemo(
        () => [
            { title: "Retorno em 15 Dias", value: "return_in_15" },
            { title: "Danos de Aviamentos", value: "damages" },
            { title: "Outros", value: "others" },
        ],
        []
    );

    const listWeekDay = useMemo(
        () => [
            "Domingo",
            "Segunda-Feira",
            "Terça-Feira",
            "Quarta-Feira",
            "Quinta-Feira",
            "Sexta-Feira",
            "Sabado",
        ],
        []
    );

    const [listPaymentsOptions, setListPaymentsOptions] = useState<
        PaymentsOptions[]
    >(initialPaymentsOptions);

    const getPaymentsOptions = useCallback(async () => {
        const selectList = [
            { title: "Pix", value: "PIX" },
            { title: "Cartão de Credito", value: "CREDITO" },
            { title: "Cartão de Débito", value: "DEBITO" },
            { title: "Deposito", value: "DEPOSITO" },
            { title: "Dinheiro", value: "DINHEIRO" },
        ];
        setListPaymentsOptions(selectList);
    }, []);

    const [rowsService, setRowsService] = useState([]);
    const [statusUltimoPedido, setStatusUltimoPedido] = useState<ActualStatus>({
        status: "1",
    });
    const [loading, setLoading] = useState(false);
    const [isInsert, setIsInsert] = useState(true);
    const [oldPhoto, setOldPhoto] = useState<String>("");
    const [typeModelAction, setTypeModelAction] = useState<String>("");
    const [dataModelAction, setDataModelAction] = useState<String>("");
    const [statusNeedPartner, setStatusNeedPartner] = useState<String>("");
    const [oldPartner, setOldPartner] = useState<String>("");
    const [currentAction, setCurrentAction] = useState<String>("");
    const [currentStatusId, setCurrentStatusId] = useState<number>(-1);
    const [statusModal, setStatusModal] = useState<
        "loading" | "success" | "error" | ""
    >("");

    const [modalConfirmPeca, setModalConfirmPeca] = useState(false);
    const [titleModalPeca, setTitleModalPeca] = useState("");
    const [messageModalPeca, setMessageModalPeca] = useState("");
    const [layoutModalPeca, setLayoutModalPeca] = useState<
        "" | "loading" | "success" | "error"
    >("");

    const [modalPayment, setModalPayment] = useState(false);
    const [openModalPrinter, setOpenModalPrinter] = useState({
        open: false,
        ip: "",
    });
    const [titleModalPayment, setTitleModalPayment] = useState("");
    const [messageModalPayment, setMessageModalPayment] = useState("");
    const [editPayment, setEditPayment] = useState([]);
    const [layoutModalPayment, setLayoutModalPayment] = useState<
        "" | "loading" | "success" | "error"
    >("");

    const [modalOpen, setModalOpen] = useState(false);
    const [marking, setMarking] = useState<string>("");
    const { onActivated } = useDrawer();
    const { openNotification } = useNotification();
    const initialProduct = useMemo(
        () => ({
            id: 0,
            status: "",
            client_id: 0,
            costumer_id: 0,
            total_value: "0",
            created_at: new Date(),
            updated_at: new Date(),
            costumer: {
                id: 0,
                client_id: null,
                type: "",
                phone: "",
                document: null,
                remember_me_token: null,
                collect: false,
                delivery: false,
                measurement: false,
                workshop: false,
                active_notification: true,
                created_at: new Date(),
                updated_at: new Date(),
                address: [
                    {
                        id: 0,
                        client_id: null,
                        session_id: 0,
                        postal_code: "",
                        neighborhood: "",
                        address_name: "",
                        address_number: "",
                        complement: "",
                        city: "",
                        state: "",
                        latitude: 0,
                        longitude: 0,
                        is_default: true,
                        created_at: new Date(),
                        updated_at: new Date(),
                    },
                ],
                profile: {
                    id: 4,
                    session_id: 4,
                    name: "",
                    photo: null,
                    created_at: new Date(),
                    updated_at: new Date(),
                },
            },
            client: {
                id: 0,
                name: "",
                document_client: "",
                master: false,
                client_master_id: null,
                deleted_at: null,
                created_at: new Date(),
                updated_at: new Date(),
            },
            products: [],
            history: [],
            partners: [],
            pieces: [
                {
                    id: 0,
                    name: null,
                    color: null,
                    mark: null,
                    observation: null,
                    order_id: 0,
                    status: "",
                    created_at: new Date(),
                    updated_at: new Date(),
                },
            ],
        }),
        []
    );
    const [showOrderDetail, setShowOrderDetail] = useState(
        initialProduct as unknown as PropOrderShow
    );

    const initialCategories = useMemo(
        () => [
            {
                title: "",
                value: "",
            },
        ],
        []
    );
    const [listServices, setListServices] = useState<PropCategoriasSelect[]>(
        []
    );
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [deliveryOrderDate, setDeliveryOrderDate] = useState(null);
    const [isOpenDateEdit, setIsOpenDateEdit] = useState(false);

    const [listCategories, setListCategories] =
        useState<PropCategoriasSelect[]>(initialCategories);

    const [needReWork, setNeedReWork] = useState(false);
    const [openAddPartner, setOpenAddPartner] = useState(false);
    const [openAddDressmaker, setOpenAddDressmaker] = useState(false);
    const [openModalRework, setOpenModalRework] = useState(false);
    const [typeModalPartners, setTypeModalPartners] = useState(0);

    const [listOptionsPartners, setListOptionsPartners] = useState([]);
    const [listMarkingOptions, setListMarkingOptions] = useState([]);

    const [selectPartner, setSelectPartner] = useState([-1]);
    const [selectCreatedAt, setSelectCreatedAt] = useState(
        new Date().toISOString().split("T")[0]
    );
    const [valorFaltaPagar, setValorFaltaPagar] = useState(0);
    const [valorTotalPago, setValorTotalPago] = useState(0);
    const [valorTotalFaturado, setValorTotalFaturado] = useState(0);
    const [listRowsPiecesFilleter, setListRowsPiecesFilleter] = useState([]);
    const [orderType, setOrderType] = useState("online");

    enum STATUS_WITH_PARTNER {
        EM_COLETA = 2,
        EM_TRABALHO = 12,
        RETRABALHO = 13,
        PRONTO_PARA_ENTREGA = 9,
    }

    const status =
        orderType === "online"
            ? [
                  { label: "Solicitado", value: "requested", id: 1 },
                  { label: "Em Coleta", value: "withdrawn", id: 2 },
                  { label: "Coletado", value: "confirmed_withdrawal", id: 3 },
                  { label: "Disponibilização", value: "in_worked", id: 12 },
                  { label: "Pedido Editado", value: "changed_items", id: 4 },
                  { label: "Aprovado", value: "approved", id: 5 },
                  { label: "Falta Pagamento", value: "missing_payment", id: 6 },
                  { label: "Na Oficina", value: "in_workshop", id: 7 },
                  {
                      label: "A Caminho da Entrega",
                      value: "out_for_delivery",
                      id: 8,
                  },
                  {
                      label: "Pronto para Entrega",
                      value: "awaiting_delivery",
                      id: 9,
                  },
                  { label: "Entregue/Retirado", value: "delivered", id: 10 },
                  { label: "Retrabalho ", value: "rework", id: 13 },
                  { label: "Cancelado", value: "canceled", id: 11 },
              ]
            : [
                  { label: "Solicitado", value: "requested", id: 1 },
                  { label: "Disponibilização", value: "in_worked", id: 12 },
                  {
                      label: "Pronto para Entrega",
                      value: "awaiting_delivery",
                      id: 9,
                  },
                  { label: "Entregue/Retirado", value: "delivered", id: 10 },
                  { label: "Retrabalho ", value: "rework", id: 13 },
                  { label: "Cancelado", value: "canceled", id: 11 },
              ];

    const sizes = [
        { title: "Único", value: "Único" },
        { title: "PP", value: "PP" },
        { title: "P", value: "P" },
        { title: "M", value: "M" },
        { title: "G", value: "G" },
        { title: "GG", value: "GG" },
        { title: "EG", value: "EG" },
        { title: "36", value: "36" },
        { title: "38", value: "38" },
        { title: "40", value: "40" },
        { title: "42", value: "42" },
        { title: "44", value: "44" },
        { title: "46", value: "46" },
        { title: "48", value: "48" },
        { title: "50", value: "50" },
    ];

    const paymentsTypes = {
        "1": "Pagamento Antecipado",
        "2": "Faturado",
        "3": "Misto",
        "4": "Desconto",
        "5": "Cortesia",
        "6": "Espaço A+",
    };

    const statusXService = {
        "2": "collect",
        "7": "workshop",
        "9": "delivery",
        "12": "dressmaker",
        "13": "dressmaker",
    };

    const getStatusById = useCallback((id: any) => {
        let statuses = status.filter((item) => item.id == id).pop();
        return statuses.label ?? "";
    }, []);

    const getTitleHistory = useCallback((type: "edit" | "add" | "delete") => {
        const title = {
            edit: "Edição ",
            add: "Adicionado ",
            delete: "Excluido ",
        };

        return title[type] ?? "Edição";
    }, []);

    const goPage = useCallback(
        (link: string) => {
            navigate(setPrefix(link));
        },
        [navigate]
    );

    const [isClicked, setIsClicked] = useState(false);

    const creatingPiece = async (type: number) => {
        if (type === 0) {
            const idCategory = await api
                .get("/categories?type=tax&page=1&limit=10")
                .then((resp) => resp.data[0].id);

            const serviceId = await api
                .get(`/services/?category=${idCategory}`)
                .then((resp) => resp.data[0].id);
            await api.post(`/client/order/createPiece/${showOrderDetail.id}`, {
                name: "Acrescimo 40%",
                color: "color qq ",
                mark: "Urgente",
                status: "pending",
                observation: "",
                size: "Único",
                services: [{ id: serviceId, quantity: 1 }],
            });
            setIsClicked(true);
            show(parseInt(id));
        } else {
            const idCategory = await api
                .get("/categories?type=tax&page=1&limit=10")
                .then((resp) => resp.data[0].id);

            const serviceId = await api
                .get(`/services/?category=${idCategory}`)
                .then((resp) => resp.data[1].id);
            await api.post(`/client/order/createPiece/${showOrderDetail.id}`, {
                name: "Acrescimo 20%",
                color: "color qq ",
                mark: "Urgente",
                status: "pending",
                observation: "",
                size: "Único",
                services: [{ id: serviceId, quantity: 1 }],
            });
            setIsClicked(true);
            show(parseInt(id));
        }
    };

    const formikItemsAddDressmaker = useFormik({
        initialValues: {
            partners: [],
            reasons: [],
            justifications: [],
            createdAt: new Date().toISOString().split("T")[0],
        },
        // validationSchema: validationItemSchema,

        onSubmit: (values, c) => {
            // console.log(values)
            handleAddDressmaker();
            // saveRows(values);
            // resetForm();
            // handleModal();
        },
    });

    const show = useCallback(async (idProduct: number = 0) => {
        setLoading(true);
        try {
            const data = (await api.get(
                `/client/order/index/${idProduct}`
            )) as PropOrderShow;
            const totalValue = parseFloat(data.total_value);
            let valoresPagos = data.payments.map((item) => {
                return parseFloat(
                    item.more.type_payment == 4 || item.more.type_payment == 2
                        ? "0"
                        : item.more.value
                );
            });

            let discount = data.payments.map((item) => {
                return parseFloat(
                    item.more.type_payment == 4 ? item.more.value : "0"
                );
            });

            let valorFaturado = data.payments.map((item) => {
                return parseFloat(
                    item.more.type_payment == 4 ? item.more.value : "0"
                );
            });
            setValorTotalFaturado(totalValue - sum(valorFaturado));
            setValorTotalPago(sum(valoresPagos));
            setValorFaltaPagar(totalValue - sum(valoresPagos) - sum(discount));

            let piecesRow = [];
            let piecesRowAll = [];
            data.pieces.map((item: any, index) => {
                let grupo = crypto.randomUUID();
                item.services.map((service: any, serviceIndex) => {
                    //@ts-ignore
                    const dressMakers = item.dressMakers.find((value) => {
                        return (
                            -1 !==
                            value.serviceToWorked.findIndex(
                                (serviceWork) => serviceWork.id === service.id
                            )
                        );
                    });
                    let price =
                        service.id === 390 || service.id === 389
                            ? parseFloat(item.price)
                            : parseFloat(service.price);
                    const pieceService =
                        item.piece_service?.find(
                            (value) => value.service_id === service.id
                        ) ?? null;
                        const dataItem = {
                            pieceService: {
                                pieceId: pieceService.piece_id,
                                serviceId: pieceService.service_id,
                            },
                            needRework: pieceService?.need_rework ?? false,
                            hide: item.services[0].id !== service.id,
                            discount_percent: service.discount_percent ?? 0, // OK, se discount_percent puder ser nullish
                            service_id: service.id,
                            id: `${pieceService.service_id}_${pieceService.piece_id}`,
                            id_piece: item.id,
                            reason: pieceService?.reason_rework,
                            justification: pieceService?.justification_rework,
                            dress_maker: item.dress_maker,
                            category: service.category,
                            color_piece: item.color,
                            mark_piece: item.mark,
                            dressMakers: [item.dressMakers[serviceIndex]],
                            size: item.size ?? "PP",
                            obs_piece: item.observation,
                            price: price,
                            qtd: service.quantity,
                            valor_total: service.quantity * price * (1 - (service.discount_percent ?? 0)), // Corrigido
                            service_item: {
                                title: service.name,
                                type: {
                                    photo: service.photo,
                                    price: service.price,
                                },
                            },
                            category_name: service.categorie?.name ?? "",
                            grupo: grupo,
                        };
                        
                    piecesRow.push(dataItem);
                    piecesRowAll.push(dataItem);
                    if (dataItem.category === 40) {
                        setIsClicked(true);
                    }
                });
                if (item.services.length === 0) {
                    let dataItem = {
                        id: item.id,
                        service_id: item.id,
                        pieceService: { pieceId: -1, serviceId: -1 },
                        needRework: false,
                        dress_maker: item.dress_maker,
                        id_piece: item.id,
                        mark_piece: `Peça #${item.id}`,
                        dressMakers: item.dressMakers,
                        reason: item.reason,
                        justification: item.justification,
                        price: 0,
                        qtd: 1,
                        valor_total: 0,
                    };
                    piecesRowAll.push(dataItem);
                }
            });

            data.products.map((item: any, index) => {
                let grupo = crypto.randomUUID();

                let price = parseFloat(item.price);
                let dataItem = {
                    id: item.id,
                    id_piece: item.id,
                    needRework: item.need_rework,
                    category: item.category,
                    reason: item.reason,
                    justification: item.justification,
                    // color_piece: "",
                    // mark_piece: "",
                    // size: "",
                    // name_piece: item.name,
                    // obs_piece: item.observation,
                    price: price,
                    qtd: item.quantity,
                    valor_total: item.quantity * price,
                    service_item: {
                        title: item.name,
                        type: {
                            photo: item.photo,
                            price: item.price,
                        },
                    },
                    // category_name: service.categorie?.name ?? "",
                    grupo: grupo,
                };
                const exist = piecesRow.findIndex(
                    (value) => value.id == dataItem.id
                );
                if (exist > -1) {
                    piecesRow[exist].qtd++;
                    piecesRow[exist].valor_total += dataItem.price;
                } else {
                    piecesRow.push(dataItem);
                    piecesRowAll.push(dataItem);
                }
            });
            let auxFormatPiecesRow = [];
            piecesRowAll
                .filter((piece) =>
                    needReWork ? piece.needReWork === true : true
                )
                .map((piece) => {
                    if (piece.category != 41) {
                        const index = auxFormatPiecesRow.findIndex((value) => {
                            return value.id_piece === piece.id_piece;
                        });

                        if (index === -1) {
                            auxFormatPiecesRow.push(piece);
                        }
                    }
                });

            setRowsPiecesAll(piecesRowAll);
            const rowsFillter = piecesRowAll.filter(
                (row) => row.category != 40 && row.category != 41
            );
            setListRowsPiecesFilleter(rowsFillter);
            setRowsPieces(piecesRow);
            setPieces(
                data.pieces.filter(
                    (piece) =>
                        !piece.services.some((service) => service.id === 381)
                )
            );
            setStatusPedido(
                chunk(orderBy(data.actualStatus, ["id"], ["asc"]), 4)
            );
            setStatusUltimoPedido(
                last(orderBy(data.actualStatus, ["id"], ["asc"]))
            );
            setShowOrderDetail(data);
            setNeedReWork(data.need_rework);
            setDeliveryDate(data.delivery_app_date ?? null);
            setDeliveryOrderDate(data.delivery ?? null);
            setOrderType(data.type === 2 ? "store" : "online");
            setOptionsPayment(data.payment);
            setPromotionCredit(
                data.costumer.promotionCredits.filter(
                    (credit) => credit.client_id === data.client_id
                )[0]?.value ?? 0
            );
            const auxDressmakers = [];
            rowsFillter.forEach((row) => {
                if (row?.dressMakers?.[0]?.profile?.session_id) {
                    auxDressmakers.push(row?.dressMakers[0]?.profile.session_id);
                } else {
                    auxDressmakers.push(0);
                }
            });
            formikItemsAddDressmaker.setFieldValue("partners", auxDressmakers);
            if (data.last_status === STATUS_WITH_PARTNER.EM_TRABALHO || data.last_status === STATUS_WITH_PARTNER.RETRABALHO) {
                const dressMakers = new Array<number>();
                const reasons = new Array<string>();
                const justifications = new Array<string>();
                rowsFillter.forEach((value) => {
                    dressMakers.push(value?.dressMakers[0]?.id);
                    reasons.push(value.reason);
                    justifications.push( value.justification);
                });
                formikItemsAddDressmaker.setFieldValue("partners", dressMakers);
                formikItemsAddDressmaker.setFieldValue("reasons", reasons);
                formikItemsAddDressmaker.setFieldValue("justifications", justifications);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }, [STATUS_WITH_PARTNER.EM_TRABALHO, STATUS_WITH_PARTNER.RETRABALHO, formikItemsAddDressmaker, needReWork]);

    useEffect(() => {
        const dressmaker = new Array<number>();
        const reasons = new Array<string>();
        const justifications = new Array<string>();

        if (
            // listRowsPiecesFilleter.length > 0 &&
            // formikItemsAddDressmaker?.values?.partners.length <= 0 &&
            formikItemsAddDressmaker?.values.reasons.length === 0 &&
            formikItemsAddDressmaker?.values.partners.length === 0
            ) {
            listRowsPiecesFilleter.forEach(value => {
                dressmaker.push(value?.dressMakers?.[0]?.id);
                reasons.push(value?.reason);
                justifications.push(value?.justification);
            })
            formikItemsAddDressmaker.setFieldValue("partners", dressmaker);
            formikItemsAddDressmaker.setFieldValue("reasons", reasons);
            formikItemsAddDressmaker.setFieldValue("justifications", justifications);
        }
    }, [])

    const handleModal = useCallback(
        (clearForm: boolean = true) => {
            setModalOpen(!modalOpen);
            if (clearForm) {
                formikItems.resetForm();
            }
        },
        [modalOpen]
    );

    const handleModalAddPartner = useCallback(
        (clearForm: boolean = true) => {
            setOpenAddPartner(!openAddPartner);
            if (clearForm) {
                setSelectPartner([-1]);
                setListOptionsPartners([]);
            }
        },
        [openAddPartner]
    );

    const handleModalAddDressmaker = useCallback(
        (clearForm: boolean = true) => {
            setOpenAddDressmaker(!openAddDressmaker);
            if (clearForm) {
                setSelectPartner([-1]);
                setListOptionsPartners([]);
            }
        },
        [openAddDressmaker]
    );

    const handleModalRework = useCallback(
        (clearForm: boolean = true) => {
            setOpenModalRework(!openModalRework);
            if (clearForm) {
            }
        },
        [openModalRework]
    );

    const handleOpenGetAuth = useCallback(() => {
        setOpenGetAuth(!openGetAuth);
    }, [openGetAuth]);

    const handleModalPrinter = useCallback(
        (clearForm: boolean = true) => {
            setOpenModalPrinter((old) => {
                return { ip: old.ip, open: !old.open };
            });
            if (clearForm) {
                setOpenModalPrinter({ open: false, ip: "" });
            }
        },
        [openAddPartner]
    );

    const validationItemSchema = useCallback(
        () =>
            yup.object({
                // name_piece: yup
                //     .string()
                //     .required(translate("validation.required")),
                mark_piece: ignoreValidate
                    ? yup.string()
                    : yup.string().required(translate("validation.required")),
                color_piece: ignoreValidate
                    ? yup.string()
                    : yup.string().required(translate("validation.required")),
                category: ignoreValidate
                    ? yup.string()
                    : yup.string().required(translate("validation.required")),
            }),
        [translate, ignoreValidate]
    );

    const formikItems = useFormik({
        initialValues: {
            id: "",
            name_piece: "",
            size: "",
            mark_piece: "",
            color_piece: "",
            obs_piece: "",
            status: "pending",
            quantity: 1,
            product_id: "",
            service: [],
            service_name: [],
            valor_total: "",
            category: "",
            product_piece: "",
        },
        validationSchema: validationItemSchema,
        onSubmit: (values, { resetForm }) => {
            // console.log(values);
            saveRows(values);
            resetForm();
            handleModal();
        },
    });


    const saveRows = useCallback(
        async (dataItemOld: any) => {
            let services = dataItemOld.services;
            let itemForm = {
                category: dataItemOld.category,
                id: dataItemOld.id,
                id_piece: dataItemOld.id_piece,
                // name: dataItemOld.name_piece,
                size: dataItemOld.size,
                color: dataItemOld.color_piece,
                mark: dataItemOld.mark_piece,
                observation: dataItemOld.obs_piece,
                quantity: dataItemOld.quantity,
                valor_total: dataItemOld.valor_total,
                status: dataItemOld.status,
                services: [],
            };
            services.map((item: any) => {
                itemForm.services.push({
                    id: parseInt(item.value),
                    price: item.price,
                    quantity: item.qtd,
                });
            });

            // console.log(itemForm);

            saveRowApi(itemForm, dataItemOld.id == "");
            setRowsService([]);
        },
        [rowsPieces]
    );

    const saveRowApi = useCallback(
        async (dataItem: any, isInsert = false) => {
            let form = dataItem;
            try {
                let response;
                let messageNotification = "Peça adicionada com sucesso";
                const isProduct = listCategories.some((value) => {
                    return (
                        value.value === form.category &&
                        value.type?.type === "product"
                    );
                });
                if (isInsert) {
                    form.status = "pending";
                    if (isProduct) {
                        await form.services.map(async (value) => {
                            for (
                                let index = 0;
                                index < value.quantity;
                                index++
                            ) {
                                response = await api.post(
                                    `/orders/associateProduct/${showOrderDetail.id}`,
                                    { produtcId: value.id }
                                );
                            }
                        });
                    } else {
                        response = await api.post(
                            `/client/order/createPiece/${showOrderDetail.id}`,
                            form
                        );
                    }
                    // response = `/client/order/createPiece/${showOrderDetail.id}`;
                } else {
                    response = await api.put(
                        `/client/order/editPiece/${dataItem.id_piece}`,
                        form
                    );
                    messageNotification = "Peça alterada com sucesso!";
                }
                openNotification(messageNotification, "success");
                show(parseInt(id));
            } catch (error) {
                let messageNotification = "Erro ao adicionar a peça";
                if (isInsert) {
                    messageNotification = "Erro ao atualizar a peça";
                }
                openNotification(messageNotification, "error");
            }
        },
        [showOrderDetail, id]
    );

    const removeProduct = useCallback(
        async (dataItem: any) => {
            try {
                const isProduct = listCategories.some((value) => {
                    return (
                        Number(value.value) === dataItem.category &&
                        value.type?.type === "product"
                    );
                });
                if (isProduct) {
                    const response = await api.delete(
                        `/orders/desassociateProduct/${id}`,
                        { data: { produtcId: dataItem.id_piece } }
                    );
                } else {
                    const response = await api.delete(
                        `/client/order/deletePiece/${dataItem.id_piece}`
                    );
                }
                openNotification("Peça excluída com sucesso!", "success");
                if (dataItem.category === 40) setIsClicked(false);
                show(parseInt(id));
            } catch (error) {
                openNotification("Erro ao excluir a peça", "error");
            }
        },
        [rowsPieces, id]
    );

    const alterStatus = useCallback(
        async (statusPiece: any, partnerId?: any, marking?: string) => {
            try {
                if(statusPiece === 10 && (!showOrderDetail.payments||showOrderDetail.payments.length <= 0)){
                    openNotification("Erro ao alterar o status, necessário incluir pagamento", "error");
                    return
                }
                if (!partnerId || partnerId === -1) {
                    openNotification("Selecione um parceiro", "error");
                    return
                }

                const body = {
                    status: statusPiece,
                    marking: marking,
                    partnerId,
                    createdAt: formikItemsAddDressmaker.values.createdAt,
                };

                if (statusPiece != 2) delete body.marking;
               const response = await api.put(
                    `/client/order/alterOrderStatus/${id}`,
                    body
                );

                if (statusPiece === STATUS_WITH_PARTNER.EM_TRABALHO) {
                    await associeteDressmaker(
                        formikItemsAddDressmaker.values.partners,
                        formikItemsAddDressmaker.values.reasons,
                        formikItemsAddDressmaker.values.justifications
                    );
                }

                openNotification("Status atualizado com sucesso", "success");
                show(parseInt(id));
            } catch (error) {
                openNotification("Erro ao alterar o status", "error");
            }
        },
        [rowsPieces, formikItemsAddDressmaker]
    );

    const alterDressmaker = useCallback(
        async (statusPiece: any, partnerId?: number[]) => {
            try {
                if (partnerId.some((value) => value === -1 || !value)) {
                    openNotification("Selecione um parceiro", "error");
                    return
                }

                if (statusPiece == STATUS_WITH_PARTNER.EM_TRABALHO) {
                    await updateDressmaker(
                        formikItemsAddDressmaker.values.partners,
                        formikItemsAddDressmaker.values.createdAt
                    );
                }

                openNotification("Status atualizado com sucesso", "success");
                show(parseInt(id));
            } catch (error) {
                openNotification("Erro ao alterar o status", "error");
            }
        },
        [rowsPieces, formikItemsAddDressmaker]
    );

    const getPartners = useCallback(
        async (statusPiece: any) => {
            if (!openAddPartner && !openAddDressmaker) return;

            try {
                const response = await api.get(
                    `/client/partners?service=${statusXService[statusPiece]}&state=${showOrderDetail.costumer.address[0].state}&limit=100&disablePartner`
                );
                const listPartners = response.data;

                const auxListOptionPartners = [
                    { title: "Escolher", value: -1 },
                ];

                for (let index = 0; index < listPartners.length; index++) {
                    if (
                        listPartners[index].profile &&
                        !listPartners[index].disable_partner
                    ) {
                        const auxOption = {
                            title: listPartners[index].profile.name,
                            value: listPartners[index].profile.session_id,
                        };
                        auxListOptionPartners.push(auxOption);
                    }
                }

                setListOptionsPartners(auxListOptionPartners);
                setStatusModal("");
                setSelectPartner([-1]);
            } catch (error) {
                openNotification("Erro ao alterar o status", "error");
            }
        },
        [openAddPartner, openAddDressmaker]
    );

    const getPartnersMarking = useCallback(async () => {
        try {
            const response = await api.get(
                `/client/partners?service=marking&state=${showOrderDetail.costumer.address[0].state}&limit=100&disablePartner=1`
            );
            const listPartners = response.data;

            const auxListOptionPartners = [{ title: "Escolher", value: -1 }];

            for (let index = 0; index < listPartners.length; index++) {
                if (listPartners[index].profile) {
                    const auxOption = {
                        title: listPartners[index].profile.name,
                        value: listPartners[index].profile.session_id,
                    };
                    auxListOptionPartners.push(auxOption);
                }
            }

            setListMarkingOptions(auxListOptionPartners);
            setStatusModal("");
        } catch (error) {
            openNotification("Erro ao alterar o status", "error");
        }
    }, [openAddPartner, openAddDressmaker]);

    const editProduct = useCallback(
        async (dataItemOld: any) => {
            let dataItem = Object.assign({}, dataItemOld);
            let grupo = dataItem.grupo;
            let rowItem = {
                category: 0,
                grupo: 0,
            };

            let services = [];
            rowsPieces.map((item: any) => {
                if (item.grupo == grupo) {
                    if (rowItem.grupo === 0) {
                        rowItem = item;
                    }
                    services.push({
                        id: item.service_id,
                        value: item.service_id,
                        qtd: item.qtd,
                        title: item.service_item.title,
                        price: parseFloat(item.service_item.type.price),
                        type: item.service_item.type,
                    });
                }
            });

            // console.log(rowItem);
            await getServices(rowItem.category);
            Object.keys(dataItemOld).map((item, key) => {
                if (!["service", "id"].includes(item)) {
                    formikItems.setFieldValue(item, dataItemOld[item]);
                }
                if (item == "id_piece") {
                    formikItems.setFieldValue("id", dataItemOld[item]);
                }
            });
            setRowsService(services);
            handleModal(false);
        },
        [formikItems]
    );

    const getCategories = useCallback(async () => {
        setLoading(true);
        const categories = (await api.get(`/categories`, {
            params: {
                page: 1,
                limit: 100,
            },
        })) as PropCategorias;
        if ("data" in categories) {
            const dataCategories = categories.data ?? [];
            let selectList = dataCategories.map((item) => {
                return {
                    value: `${item.id ?? ""}`,
                    title: item.name ?? "",
                    type: item,
                };
            });
            setListCategories(selectList);
        }
        setLoading(false);
    }, []);

    const getServices = useCallback(
        async (idCategoria: any = 0) => {
            try {
                const categories = (await api.get(
                    `/services/category/${idCategoria}`,
                    {
                        params: {
                            orderType: showOrderDetail.type,
                        },
                    }
                )) as PropServices[];
                const dataCategories = categories ?? [];
                let selectList = dataCategories.map((item) => {
                    return {
                        value: `${item.id ?? ""}`,
                        title: item.name ?? "",
                        type: item,
                    };
                });
                setListServices(selectList);
            } catch (error) {}
        },
        [showOrderDetail.type]
    );

    const selectServices = useCallback(
        async (dataItem: any) => {
            const myCat = listServices.filter((item: any) => {
                return dataItem.includes(item.value);
            });
            const valorTotal = myCat.map((item) => {
                return parseFloat(item.type.price);
            });
            formikItems.setFieldValue("service_name", myCat);
            formikItems.setFieldValue("valor_total", sum(valorTotal));
        },
        [formikItems, listServices]
    );

    const handleModalDeletePeca = useCallback(
        async (
            statusModal = false,
            dado: any = "",
            typeModal = "peca",
            skipNeedAddPartner = false
        ) => {
            if (
                (!canEdit &&
                    (notAllow[Number(dado)] !== undefined ||
                        notAllow[Number(showOrderDetail.last_status)] !==
                            undefined)) ||
                (dado === 6 && showOrderDetail.payments.length > 0)
            )
                return openNotification(
                    `Não é possivel alterar para o Status ${
                        notAllow[Number(dado)]
                    }`,
                    "warning"
                );

            // if (!canAlterStatus(Number(dado))) {
            //     return openNotification(
            //         `Não é possivel Adicionar esse Status!`,
            //         "warning"
            //     );
            // }

            const statusNeedAddPartner = [
                STATUS_WITH_PARTNER.EM_COLETA,
                STATUS_WITH_PARTNER.PRONTO_PARA_ENTREGA,
                STATUS_WITH_PARTNER.EM_TRABALHO,
                STATUS_WITH_PARTNER.RETRABALHO,
            ];
            setLayoutModalPeca("");
            if (statusModal === false) {
                setModalConfirmPeca(statusModal);
                return;
            }

            if (!skipNeedAddPartner && statusNeedAddPartner.includes(dado)) {
                if (
                    dado !== STATUS_WITH_PARTNER.EM_TRABALHO &&
                    dado !== STATUS_WITH_PARTNER.RETRABALHO
                )
                    setOpenAddPartner(true);
                else if (dado !== STATUS_WITH_PARTNER.RETRABALHO) {
                    // setTypeModalPartners(needReWork ? 1 : 0);
                    setTypeModalPartners(0);
                    setOpenAddDressmaker(true);
                } else {
                    setTypeModalPartners(1);
                    setOpenAddDressmaker(true);
                    // handleModalRework();
                }
                setStatusModal("loading");
                setStatusNeedPartner(dado);
                setCurrentAction("addPartner");
                statusModal = false;
            }

            setDataModelAction(dado);
            setModalConfirmPeca(statusModal);
            setTypeModelAction(typeModal);
            if (typeModal === "peca") {
                setTitleModalPeca("Deseja realmente excluir esta peça?");
                setMessageModalPeca("Excluindo a peça");
            }
            if (typeModal === "status") {
                setTitleModalPeca(
                    "Deseja realmente alterar o status do pedido?"
                );
                setMessageModalPeca("Alterando Status do pedido!");
            }
        },
        [
            canEdit,
            notAllow,
            showOrderDetail.last_status,
            showOrderDetail?.payments?.length,
            openNotification,
            STATUS_WITH_PARTNER.EM_COLETA,
            STATUS_WITH_PARTNER.PRONTO_PARA_ENTREGA,
            STATUS_WITH_PARTNER.EM_TRABALHO,
            STATUS_WITH_PARTNER.RETRABALHO,
            needReWork,
            handleModalRework,
        ]
    );

    const associeteDressmaker = useCallback(
        async (
            newPartners: number[],
            reasons: number[],
            justifications: number[]
        ) => {
            const body = {
                partners: newPartners,
                pieces: needReWork
                    ? listRowsPiecesFilleter.filter((piece) => piece.needRework)
                    : listRowsPiecesFilleter,
                justifications: justifications ?? [],
                reasons: reasons ?? [],
            };
            await api.post(`/client/order/associateDressMaker/${id}`, body);
        },
        [needReWork, listRowsPiecesFilleter, id]
    );

    const canAlterStatus = useCallback(
        (newStatus: number) => {
            const NEED_A_SPECIFIC_STATUS_DELIVERY = {
                12: 5,
            };

            const needSpecificStatus =
                orderType === "online"
                    ? NEED_A_SPECIFIC_STATUS_DELIVERY[newStatus] ?? null
                    : null;

            if (needSpecificStatus === null) return true;

            const can = statusPedido.some((group) =>
                group.some((value) => value.status === needSpecificStatus)
            );

            return can;
        },
        [statusPedido, orderType]
    );

   const saveRework = useCallback(
        async (orderId, body) => {

            try {
                 await api.put(
                    `/client/order/rework/${orderId}`,
                    body
                );

            } catch (error) {
                console.log(error)
            }
        },
        []
    );

    const editRework = useCallback(async (orderId, content) => {
        try {
            await api.put(`/client/order/updateRework/${orderId}`, {
                partners: content.partners,
                reasons: content.reasons,
                justifications: content.justifications,
                orderId,
            });
        } catch (error) {
            console.log(error);
        }
    }, []);

    const handleModalConfirm = useCallback(async () => {
        setLayoutModalPeca("loading");
        // if (selectPartner.some((value) => !value || value === -1) && dataModelAction != '10' && typeModelAction != 'peca'){
        //     openNotification("Parceiro não pode estar vazio. Por favor selecione um parceiro", "error");
        //     setModalConfirmPeca(false);
        //     setSelectPartner([-1]);
        //     show(parseInt(id))
        //     return;
        // }
        if (typeModelAction === "peca") {
            await removeProduct(dataModelAction);
        }
        if (typeModelAction === "status") {
            if (currentAction === "editPartner") {
                if (showOrderDetail?.last_status === STATUS_WITH_PARTNER.RETRABALHO) {
                    await editRework(showOrderDetail?.id, formikItemsAddDressmaker.values);
                    await associeteDressmaker(formikItemsAddDressmaker.values.partners, formikItemsAddDressmaker.values.reasons, formikItemsAddDressmaker.values.justifications)
                } else {
                    await alterDressmaker(dataModelAction, selectPartner);
                }
                setCurrentAction("");
            } else {
                if (Number(dataModelAction) === STATUS_WITH_PARTNER.RETRABALHO) {
                    const pieceService = showOrderDetail.pieces.map((piece, index) => {
                        if (!formikItemsAddDressmaker.values.justifications[index]) return null
                        return { pieceId: piece.id, serviceId: piece.services[0].id }
                    }).filter((value) => value !== null)

                    await saveRework(showOrderDetail?.id, { pieceService })
                    await alterStatus(dataModelAction, selectPartner, marking);
                    await editRework(showOrderDetail?.id, formikItemsAddDressmaker.values);
                    await associeteDressmaker(
                        formikItemsAddDressmaker.values.partners,
                        formikItemsAddDressmaker.values.reasons,
                        formikItemsAddDressmaker.values.justifications
                    )
                } else {
                    await alterStatus(dataModelAction, selectPartner, marking);
                    if (Number(dataModelAction) === STATUS_WITH_PARTNER.EM_TRABALHO) await associeteDressmaker(
                        formikItemsAddDressmaker.values.partners,
                        formikItemsAddDressmaker.values.reasons,
                        formikItemsAddDressmaker.values.justifications
                    )
                }
            }
        }
        setModalConfirmPeca(false);
        setSelectPartner([-1]);
        show(parseInt(id))
    }, [typeModelAction, show, id, removeProduct, dataModelAction, currentAction, showOrderDetail?.last_status, showOrderDetail?.id, showOrderDetail.pieces, STATUS_WITH_PARTNER.RETRABALHO, editRework, formikItemsAddDressmaker.values, associeteDressmaker, alterDressmaker, selectPartner, saveRework, alterStatus, marking]);

    const alterQuantity = useCallback(
        async (qtd, rowIndex) => {
            setRowsService((prevRows) => {
                return prevRows.map((row, index) => {
                    if (index === rowIndex) {
                        row.qtd = qtd;
                    }
                    return row;
                });
            });
        },
        [rowsService, setRowsService]
    );

    const addServices = useCallback(async () => {
        let serviceAdd = formikItems.values.service;
        rowsService.map((value) => {
            if (serviceAdd.includes(String(value.id))) {
                serviceAdd = serviceAdd.filter((item) => item != value.id);
            }
        });

        let servicesAdd = listServices.filter((items: any) => {
            items.qtd = 1;
            items.id = items.value;
            items.price = items.type.price;
            return serviceAdd.includes(items.value);
        });
        setRowsService((oldValues) => [...oldValues, ...servicesAdd]);
    }, [formikItems, rowsService, listServices, setRowsService]);

    useEffect(() => {
        const valorTotal = rowsService.map((item) => {
            return item.qtd * parseFloat(item.price);
        });
        formikItems.setFieldValue("valor_total", sum(valorTotal));
        formikItems.setFieldValue("services", rowsService);
    }, [rowsService]);

    const [notAdminPayment, setNotAdminPayment] = useState<boolean>(false);

    const handlPaymentOpitons = async (e) => {
        if (userType !== "admin") {
            setNotAdminPayment(true);
            setTimeout(() => {
                setNotAdminPayment(false);
            }, 5000);
            return;
        }
        const paymentMethod = e.target.value;
        const response1 = await api
            .put(`/client/order/setPayment/${id}`, { payment: paymentMethod })
            .then(() => show(parseInt(id)));
    };

    const handleSelectPartnerOption = (e) => {
        const selectPartnerAux = [...selectPartner];
        const pos = e.target.name === "state2" ? 1 : 0;
        if (pos === 1 && selectPartnerAux[0] == e.target.value) return;
        selectPartnerAux[pos] = e.target.value;
        setSelectPartner(selectPartnerAux);
    };

    const updateStatus = useCallback(
        async (newPartner: number[], marking?: string) => {
            const body = {
                partnerId: newPartner,
                marking,
            };
            const response2 = await api
                .put(`/client/order/${id}/status/${currentStatusId}`, body)
                .then(() => show(parseInt(id)));
        },
        [currentStatusId, oldPartner, marking]
    );

    const printerNF = useCallback(
        async (data: any, type: "normal" | "chunck" | "pieces" = "normal") => {
            // const path = type === "normal" ? "" : "chunck";
            let path = ""
            if (type === "normal") {
                path = ""
            } else if (type === "chunck") {
                path = "chunck"
            } else if (type === "pieces") {
                path = "pieces"
            }
            await apiLocal.post(path, data);
        },
        []
    );

    const updateDressmaker = useCallback(
        async (newPartners: number[], created_at: string) => {
            const response2 = await api.put(
                `/client/order/updateDressmaker/${id}`,
                {
                    partners: newPartners,
                    pieces: listRowsPiecesFilleter,
                    createdAt: created_at,
                }
            );
        },
        [currentStatusId, oldPartner, listRowsPiecesFilleter]
    );

    const handleAddPartner = useCallback(async () => {
        try {
            if (oldPartner != "") {
                await updateStatus(selectPartner, marking);
                setOldPartner("");
            }

            setStatusModal("success");
        } catch (error) {
            openNotification("Error ao associar o parceiro(a)", "error");
        }
    }, [selectPartner, oldPartner, currentStatusId, marking]);

    const handleAddDressmaker = useCallback(async () => {
        try {
            setSelectPartner(formikItemsAddDressmaker.values.partners);
            if (dataModelAction != "13")
                setDataModelAction(STATUS_WITH_PARTNER.EM_TRABALHO.toString());
            setStatusModal("success");
        } catch (error) {
            openNotification("Error ao associar o parceiro(a)", "error");
        }
    }, [selectPartner, oldPartner, currentStatusId, formikItemsAddDressmaker]);

    const handleContinueFlow = useCallback(async () => {
        setStatusModal("");
        setOpenAddPartner(false);
        setOpenAddDressmaker(false);
        setOpenModalRework(false);
        if (currentAction === "addPartner")
            handleModalDeletePeca(true, dataModelAction, "status", true);
        if (currentAction === "editPartner")
            handleModalDeletePeca(true, dataModelAction, "status", true);
    }, [dataModelAction, currentAction]);

    const handleEditPartner = useCallback(
        async ({
            status,
            partner_id,
            id,
            partners,
            meta,
        }: {
            status: string;
            partner_id: any;
            id: number;
            partners: any[];
            meta: { marking: string };
        }) => {
            setMarking(meta.marking ?? "");
            setCurrentAction("editPartner");
            setCurrentStatusId(id);
            setStatusNeedPartner(status);
            setOldPartner(partner_id);
            if (
                Number(status) !== STATUS_WITH_PARTNER.EM_TRABALHO &&
                Number(status) !== STATUS_WITH_PARTNER.RETRABALHO
            ) {
                handleModalAddPartner();
                const partenrsAux = partners.reduce((acc, value) => {
                    acc.push(value.id);
                    return acc;
                }, []);
                setEditPartner([...partenrsAux]);
            } else if (Number(status) !== STATUS_WITH_PARTNER.RETRABALHO) {
                // setTypeModalPartners(needReWork ? 1 : 0);
                setTypeModalPartners(0);
                handleModalAddDressmaker();
            } else {
                setTypeModalPartners(1);
                handleModalAddDressmaker();
                // handleModalRework();
            }
        },
        [STATUS_WITH_PARTNER.EM_TRABALHO, STATUS_WITH_PARTNER.RETRABALHO, handleModalAddPartner, handleModalAddDressmaker]
    );

    const handleEditPayments = useCallback(async (payment) => {
        setEditPayment([payment]);
        handlerModalPayment(true);
    }, []);

    const handleDeletePayments = async (paymentId) => {
        setLoading(true);
        try {
            await api.delete(`/client/order/${id}/payment-order/${paymentId}`);
            setLoading(false);
            show(parseInt(id));
        } catch (error) {
            setLoading(false);
            openNotification(translate("formService.delete.error"), "error");
            return false;
        }
    };

    const changeDeliveryDate = useCallback(async (delivery_app_date) => {
        setLoading(true);
        try {
            await api.put(`/client/order/${id}/delivery-app-date`, {
                delivery_app_date: delivery_app_date,
            });
            setLoading(false);
            show(parseInt(id));
        } catch (error) {
            setLoading(false);
            setDeliveryDate(null);
            openNotification("Erro ao alterar a data de Coleta", "error");
            return false;
        }
    }, []);

    const getPartnerInfo = useCallback(
        (status: any) => {
            const partnerId = status?.partners[0]?.id;
            if (partnerId === null) return "";

            const actualStatus = showOrderDetail.actualStatus.filter(
                (value) => {
                    return value?.partners[0]?.profile?.session_id == partnerId;
                }
            );

            return [
                actualStatus[0].partners[0]?.profile?.session_id,
                actualStatus[0].partners[0]?.profile?.name,
            ];
        },
        [showOrderDetail]
    );

    const getPartnerInfoMaker = useCallback(
        (status: any) => {
            const partnerId = status?.partners[1]?.id;
            if (partnerId === null) return "";

            const actualStatus = showOrderDetail.actualStatus.filter(
                (value) => {
                    return value?.partners[1]?.profile?.session_id == partnerId;
                }
            );

            return [
                actualStatus[0].partners[1]?.profile?.session_id,
                actualStatus[0].partners[1]?.profile?.name,
            ];
        },
        [showOrderDetail]
    );

    const handlerModalPayment = useCallback((status: any) => {
        setModalPayment(status);
        show(parseInt(id));
        if (!status) setEditPayment([]);
    }, []);

    const createDataPrinter = useCallback(
        (row = null, formatPos = null) => {
            const paymentsPrinter = [];
            const withdrawType = ["Retirar", "Personalizada"];
            const dateWithDraw = new Date(deliveryOrderDate || showOrderDetail.delivery_app_date);
            const nowDate = new Date();

            showOrderDetail.payments.map((payment) => {
                const aux = {
                    method: payment.header_payment,
                    label: payment.label_payment,
                    value: payment.more.value,
                };
                paymentsPrinter.push(aux);
            });

            let dtAprovacao = showOrderDetail.actualStatus.filter(
                (i: any) => i.status == 5
            );
            let dtSolicitacao = showOrderDetail.actualStatus.filter(
                (i: any) => i.status == 1
            );

            let dtEmissa =
                nowDate.getDate() +
                "/" +
                (nowDate.getMonth() + 1).toString().padStart(2, "0") +
                "/" +
                nowDate.getFullYear();

            if (showOrderDetail.client_id == 10 && dtAprovacao.length > 0) {
                let newDt = new Date(dtAprovacao[0].created_at);
                dtEmissa =
                    newDt.getDate() +
                    "/" +
                    (newDt.getMonth() + 1).toString().padStart(2, "0") +
                    "/" +
                    newDt.getFullYear();
            }
            if (showOrderDetail.client_id != 10 && dtSolicitacao.length > 0) {
                let newDt = new Date(dtSolicitacao[0].created_at);
                dtEmissa =
                    newDt.getDate() +
                    "/" +
                    (newDt.getMonth() + 1).toString().padStart(2, "0") +
                    "/" +
                    newDt.getFullYear();
            }

            const dataPrinter = {
                orderId: showOrderDetail.id.toString(),
                header: {
                    cnpj: "35.088.142/0001-37",
                    unid: `Dona Filó - SAO PAULO - SP`,
                    phones: [
                        "Matriz (11) 3078-4924",
                        "Vila Nova Conceição (11) 3845-7687",
                        "Delivery (11) 94057-1137",
                        userProfile.name.includes('Cidade Jardim') ? "Cidade Jardim (11) 91163-9594" : ""
                    ],
                    phrase: "TRADICAO E EFICIENCIA, MAIS PERTO DE VOCE!",
                },
                costumer: {
                    phone: showOrderDetail.costumer.phone,
                    name: showOrderDetail.costumer.profile.name,
                },
                pieces: [],
                paidType: {
                    totalServices: showOrderDetail.total_value,
                    payments: paymentsPrinter,
                    totalPaid: valorTotalPago.toFixed(2),
                    method: showOrderDetail.payments[0]?.header_payment ?? "",
                    noPaid: valorFaltaPagar.toFixed(2),
                    credit: valorFaltaPagar.toFixed(2),
                    totalOrder: showOrderDetail.total_value,
                    totalInvoice: valorTotalFaturado.toFixed(2),
                },
                emitDate: dtEmissa,
                withdraw: {
                    Withdraw: withdrawType[showOrderDetail.flag],
                    date:
                        dateWithDraw.getDate() +
                        "/" +
                        (dateWithDraw.getMonth() + 1)
                            .toString()
                            .padStart(2, "0") +
                        "/" +
                        dateWithDraw.getFullYear(),
                    weekDay: listWeekDay[dateWithDraw.getDay()],
                    hours:
                        dateWithDraw.getHours().toString() +
                        ":" +
                        dateWithDraw.getMinutes().toString(),
                },
                operator: userProfile.name,
                total: showOrderDetail.total_value,
                footer: {
                    instagram: "@DONAFILOCOSTURA",
                    site: "WWW.DONAFILOCOSTURA.COM.BR",
                    phrase: "AGRADECEMOS PELA PREFERENCIA",
                },
            };
            if (!row) {
                rowsPiecesAll
                .filter((piece) =>
                    needReWork ? piece.needRework === true : true
            )
            .map((piece) => {
                if (piece.category != 41) {
                    const index = dataPrinter.pieces.findIndex(
                                (value) => {
                                    return value.id_piece === piece.id_piece;
                                }
                            );


                            if (index !== -1) {
                                dataPrinter.pieces[index].service.push({
                                    name: piece.service_item.title,
                                    pos: formatPos,
                                    qtd: piece.qtd,
                                    discount: Number(
                                        (
                                            piece.price *
                                            (piece.discount_percent ?? 0)
                                        ).toFixed(2)
                                    ),
                                    price: Number(piece.price),
                                    justification_rework: piece.justification,
                                });

                                dataPrinter.pieces[index].total += Number(
                                    Number(piece.valor_total).toFixed(2)
                                );
                                dataPrinter.pieces[index].total = Number(
                                    dataPrinter.pieces[index].total.toFixed(2)
                                );
                            } else {

                                const pieceAux = {
                                    name:
                                        piece.category_name ??
                                        piece.service_item.title,
                                    pos: formatPos,
                                    id_piece: piece.id_piece,
                                    service: [
                                        {
                                            name: piece.service_item.title,
                                            qtd: piece.qtd,
                                            discount: Number(
                                                (
                                                    piece.price *
                                                    (piece.discount_percent ??
                                                        0)
                                                ).toFixed(2)
                                            ),
                                            price: Number(piece.price),
                                            ...(piece?.needRework && piece.justification === '' ? {
                                                justification_rework: " "
                                            } : {}),
                                        },
                                    ],
                                    size: piece.size,
                                    brand: piece.mark_piece,
                                    color: piece.color_piece,
                                    obs: piece.obs_piece,
                                    total: Number(
                                        Number(piece.valor_total).toFixed(2)
                                    ),
                                    isAddition: piece.category == 40,
                                };
                                dataPrinter.pieces.push(pieceAux);
                            }
                        }
                    });
            } else {
                const pieceAux = {
                    name: row.category_name,
                    service: [
                        {
                            name: row.service_item.title,
                            qtd: row.qtd,
                            discount: Number(
                                (row.price * row.discount_percent).toFixed(2)
                            ),
                            price: Number(row.price.toFixed(2)),
                            justification_rework:
                                                row.justification === ''? " " : row.justification,
                        },
                    ],
                    size: row.size,
                    brand: row.mark_piece,
                    color: row.color_piece,
                    obs: row.obs_piece,
                    pos: formatPos,
                    total: Number(Number(row.valor_total).toFixed(2)),
                };
                dataPrinter.pieces.push(pieceAux);
                dataPrinter.paidType.totalServices = String(row.valor_total);
            }

            return dataPrinter;
        },
        [
            deliveryOrderDate,
            showOrderDetail.payments,
            showOrderDetail.actualStatus,
            showOrderDetail.client_id,
            showOrderDetail.id,
            showOrderDetail.costumer.phone,
            showOrderDetail.costumer.profile.name,
            showOrderDetail.total_value,
            showOrderDetail.flag,
            valorTotalPago,
            valorFaltaPagar,
            valorTotalFaturado,
            listWeekDay,
            userProfile.name,
            rowsPiecesAll,
            needReWork,
        ]
    );

    const sendPrint = useCallback(
        (row = null) => {
            let formatPos = null;

            if (row) {
                const getIndex =
                    rowsPiecesAll.findIndex((value) => value.id === row.id) + 1;
                formatPos = `${getIndex}/${rowsPiecesAll.length}`;
            }

            const dataPrinter = createDataPrinter(row, formatPos);
            printerNF(dataPrinter);
        },
        [createDataPrinter, printerNF, rowsPiecesAll]
    );

    const handlerPrinter = useCallback(
        (e) => {
            e.preventDefault();
            sendPrint();
        },
        [sendPrint]
    );

    const handlOpenDateEdit = () => {
        setIsOpenDateEdit(!isOpenDateEdit);
    };

    const handlerPrinterChange = (e) => {
        e.preventDefault();
        if (e.target.value.length > 15) return;
        const value = e.target.value.replace(/^[a-zA-Z]*$/, "");
        // replace(/^(\d{3})(\d{3})[(\d{2})(\d{3})](\d{3}).*/, '$1.$2.$3.$4');
        setOpenModalPrinter({ open: true, ip: value });
    };

    const handleAddIpPrinter = useCallback(
        (row = null) => {
            sendPrint(row);
        },
        [openModalPrinter, showOrderDetail]
    );

    const handlePrinterChunckPieces = useCallback(() => {
        const dataPrinterAux = createDataPrinter();
        const dataPrinter = [];
        for (let index = 0; index < dataPrinterAux.pieces.length; index++) {
            const aux = { ...dataPrinterAux };
            aux.pieces = aux.pieces.slice(index, index + 1);
            dataPrinter.push(aux);
        }
        printerNF(dataPrinter, "chunck");
    }, [rowsPiecesAll]);

    const handlePrinterPieceByPiece = useCallback(() => {
        const dataPrinterAux = createDataPrinter();
        printerNF(dataPrinterAux, "pieces");
    }, [createDataPrinter, printerNF]);

    const handleChangeDeliveryDate = useCallback((date: Date) => {
        setDeliveryDate(date);
        changeDeliveryDate(date);
    }, []);

    const handleMarking = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setMarking(e.currentTarget.value);
        },
        [marking]
    );

    useEffect(() => {
        document.title = translate("detailOrder.head.title");
        onActivated("admin", "pedidos", "/pedidos");
        getCategories();
        getPaymentsOptions();
        if (typeof id !== "undefined" && id !== "novo") {
            show(parseInt(id));
        }

        return () => {
            setIsInsert(true);
        };
    }, [translate, onActivated]);

    useEffect(() => {
        if (openAddPartner || openAddDressmaker) {
            getPartners(statusNeedPartner);
            getPartnersMarking();
        }
    }, [openAddPartner, statusNeedPartner, openAddDressmaker]);

    // useEffect(() => {
    //     setDeliveryDate(null)
    //     setDeliveryOrderDate(null)
    // }, [needReWork])

    useEffect(() => {
        if (formikItems.values.category !== "") {
            const isProduct = listCategories.some((value) => {
                return (
                    value.value === formikItems.values.category &&
                    value.type?.type === "product"
                );
            });
            if (isProduct) return setIgnoreValidate(true);

            setIgnoreValidate(false);
        }
    }, [formikItems.values.category]);

    useEffect(() => {
        handleCanEdit(false);
    }, []);
    // console.log("teste data update", formikItemsAddDressmaker.values.createdAt);
    return {
        loading,
        translate,
        goPage,
        showOrderDetail,
        isInsert,
        status,
        oldPhoto,
        formikItems,
        statusModal,
        handleModal,
        modalOpen,
        getServices,
        selectServices,
        listCategories,
        listServices,
        editProduct,
        rowsPieces,
        statusPedido,
        getStatusById,
        removeProduct,
        isClicked,
        creatingPiece,

        //Modal Delete
        handleModalConfirm,
        titleModalPeca,
        handleModalDeletePeca,
        modalConfirmPeca,
        messageModalPeca,
        layoutModalPeca,

        titleModalPayment,
        // handleModalDeletePayment,
        modalPayment,
        setModalPayment,
        messageModalPayment,
        layoutModalPayment,

        statusUltimoPedido,

        addServices,
        alterQuantity,
        rowsService,
        rowsPiecesAll,

        listPaymentsOptions,
        handlPaymentOpitons,
        notAdminPayment,
        sizes,

        openAddPartner,
        listOptionsPartners,
        listMarkingOptions,
        selectPartner,
        handleModalAddPartner,
        handleModalAddDressmaker,
        handleSelectPartnerOption,
        handleAddPartner,
        handleContinueFlow,
        handleEditPartner,
        STATUS_WITH_PARTNER,
        getPartnerInfo,
        paymentsTypes,
        handlerModalPayment,
        valorTotalFaturado,
        valorTotalPago,
        valorFaltaPagar,
        startDate,
        isOpenDatePicker,
        formikItemsAddDressmaker,
        openModalPrinter,
        listRowsPiecesFilleter,
        listReasonsOptions,
        openAddDressmaker,
        typeModalPartners,
        canEdit,
        userType,
        openGetAuth,
        editPayment,
        deliveryDate,
        isOpenDateEdit,
        orderType,
        statusNeedPartner,
        editPartner,
        marking,
        pieces,
        deliveryOrderDate,
        openModalRework,
        needReWork,
        promotionCredit,
        handleModalRework,
        handleMarking,
        handleChangeDate,
        handleClickDate,
        handlerPrinter,
        handleAddIpPrinter,
        handlerPrinterChange,
        handleModalPrinter,
        getTitleHistory,
        handleOpenGetAuth,
        handleCanEdit,
        handleEditPayments,
        handleDeletePayments,
        handleChangeDeliveryDate,
        handlePrinterChunckPieces,
        handlOpenDateEdit,
        getPartnerInfoMaker,
        setSelectCreatedAt,
        selectCreatedAt,
        handlePrinterPieceByPiece,
    };
}
