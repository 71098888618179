import Menu from "@material-ui/core/Menu";
import Popover from "@material-ui/core/Popover";
import styled from "styled-components";

export const MenuCustom = styled(Popover)`
    box-shadow: 0px 4px 14px 11px #9b999926;
    .MuiPaper-root {
        margin-top: 0px;
        background: transparent;
    }
    .MuiList-root {
    }
`;

export const Inner = styled.ul`
    opacity: 1;
    background: rgba(255, 255, 255, 0.9);
    width: 200px;
`;
