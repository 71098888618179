import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/button";
import { InputSelect, InputText } from "components/form";
import { Typography } from "@material-ui/core";

import {
    Grid,
    IconError,
    IconSuccess,
    Loading,
    Modal,
    ModalActions,
    ModalBody,
    ModalGroupMessage,
    ModalStatus,
    ModalTextSuccess,
    ModalTitle,
} from "./styled";
import FooterButton from "components/footerButton";

interface Props {
    open: boolean;
    isCollect: boolean;
    status: "loading" | "success" | "error" | "";
    message?: string;
    marking?: string;
    listPartnersOptions: any;
    listMarkingOptions: any;
    partenerValue: number[];
    city: string;
    notAdminPayment: any;
    handlePartnerOpitons: any;
    onHandleAddPartner: () => void;
    handleMarking: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onGoNext: () => void;
    onGoback: () => void;
    onClose: () => void;
}

const AddPartner: React.FC<Props> = memo(
    ({ open, status, message, marking, isCollect, partenerValue, listPartnersOptions, listMarkingOptions, city, handlePartnerOpitons, notAdminPayment, onHandleAddPartner, handleMarking, onGoNext, onGoback, onClose }) => {
        const { t: translate } = useTranslation();
        const modalContent = () => {
            switch (status) {
                case "loading":
                    return (
                        <ModalStatus>
                            <Loading color="secondary" />
                        </ModalStatus>
                    );
                case "success":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconSuccess />
                                    <ModalTextSuccess>
                                        {message}
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={onGoNext}
                                        >
                                            continue
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );
                case "error":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconError />
                                    <ModalTextSuccess>
                                        {message}
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={onGoback}
                                        >
                                            {translate("modal.button.goback")}
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );

                default:
                    return (
                        <>
                            <ModalTitle>
                                Defina o Parceiro
                            </ModalTitle>
                            <ModalBody>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Cidade: {city}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Parceiro Entregador
                                        </Typography>
                                        <InputSelect
                                            color="primary"
                                            name="state"
                                            options={listPartnersOptions}
                                            value={partenerValue[0]}
                                            onChange={(e) => handlePartnerOpitons(e)}
                                            error={Boolean(notAdminPayment)}
                                        />
                                    </Grid>
                                    {isCollect &&
                                        (
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        Técnica de Marcação
                                                    </Typography>
                                                    <InputSelect
                                                        color="primary"
                                                        name="state2"
                                                        options={listMarkingOptions}
                                                        value={partenerValue[1] ?? -1}
                                                        onChange={(e) => handlePartnerOpitons(e)}
                                                        error={Boolean(notAdminPayment)}
                                                    />
                                                </Grid>
                                            </>
                                        )
                                    }
                                    <Grid item xs={12}>
                                        <FooterButton>
                                            <Button
                                                onClick={onHandleAddPartner}
                                            >
                                                {translate("modal.button.add")}
                                            </Button>
                                            <Button
                                                onClick={onClose}
                                                variant="outlined"
                                            >
                                                {translate("modal.button.cancel")}
                                            </Button>

                                        </FooterButton>
                                    </Grid>
                                </Grid>
                            </ModalBody>
                        </>
                    );
            }
        };

        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                scroll="body"
            >
                {modalContent()}
            </Modal>
        );
    }
);

export default AddPartner;
