import styled from "styled-components";
import {
    Avatar,
    Popover,
    MenuItem as MenuItemCustom,
    Typography,
} from "@material-ui/core";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PermDataSettingOutlinedIcon from "@material-ui/icons/PermDataSettingOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";

export const Main = styled.div`
    && {
        button {
            width: 145px;
            border-radius: 5px;
            box-shadow: none;
            padding: 6px 10px;
            background-color: #d8913a;
            background-image: none;
            height: 40px;
            span {
                display: flex;
                justify-content: space-between;

                svg {
                    fill: ${(props) => props.theme.palette.primary.dark};
                    margin-right: 5px;
                }
            }

            &:hover,
            &:focus {
                background-color: #d8913a;
                background-image: none;

                svg {
                    fill: ${(props) => props.theme.palette.primary.dark};
                }
            }
            &::before {
                width: 40px;
                height: 40px;
                content: "";
                position: absolute;
                top: 0;
                right: -20px;
                border: 2px solid #fff;
                border-radius: 50%;
            }
        }
    }
`;

export const IconSettings = styled(SettingsOutlinedIcon)``;

export const PhotoProfile = styled(Avatar)``;

export const Menu = styled(Popover)`
    [class*="MuiPaper-root"] {
        border-radius: 10px;
        margin-top: 10px;
    }
`;

export const InnerMenu = styled.div`
    width: 334px;
    padding: 20px;
`;

export const Divisor = styled.div`
    border-bottom: 1px solid #eee;
    margin: 10px 0;
`;

export const MenuNameText = styled(Typography)`
    &[class*="MuiTypography-h3"] {
        font-size: 16px;
        text-transform: capitalize;
    }
`;
export const MenuItemText = styled(Typography)`
    &[class*="MuiTypography-h3"] {
        font-size: 20px;
        text-transform: capitalize;
    }
`;

export const MenuItem = styled(MenuItemCustom)`
    &:not(:last-child) {
        margin-bottom: 10px;
    }

    svg {
        margin-right: 10px;
    }
`;

export const IconHome = styled(HomeOutlinedIcon)``;

export const IconPower = styled(PowerSettingsNewIcon)``;

export const IconPermDataSettingOutlined = styled(
    PermDataSettingOutlinedIcon
)``;

export const IconPerson = styled(AccountCircleIcon)``;

export const IconCompany = styled(BusinessIcon)``;

export const IconUsers = styled(PeopleAltOutlinedIcon)``;
