import { ReactNode } from "react";
import { useAuthProvider, AuthContext } from "./useAuth";

interface Props {
    children?: ReactNode;
}

export default function Auth({ children }: Props) {
    const {
        loadingPage,
        loadingForm,
        signed,
        profile,
        userType,
        canEdit,
        clientId,
        clientDetails,
        signIn,
        signOut,
        onLogout,
        onChageProfile,
        sendCodeToEmail,
        handleCanEdit
    } = useAuthProvider();

    return (
        <AuthContext.Provider
            value={{
                loadingPage,
                loadingForm,
                signed,
                profile,
                userType,
                canEdit,
                clientId,
                clientDetails,
                signIn,
                signOut,
                onLogout,
                onChageProfile,
                sendCodeToEmail,
                handleCanEdit
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
