import React, { memo } from "react";

import Visible from "components/visible";
import { Button } from "components/button";

import Profile from "assets/images/gravatar.png";

import { shortName } from "utils";

import useMenuSettings from "./useMenuSettings";

import {
    Main,
    IconSettings,
    PhotoProfile,
    Divisor,
    Menu,
    InnerMenu,
    MenuItem,
    MenuItemText,
    IconPower,
    IconPerson,
    MenuNameText,
} from "./styled";

const MenuSettings: React.FC = memo(() => {
    const {
        profile,
        open,
        anchorEl,
        translate,
        handleOpen,
        handleClose,
        goToPage,
        onLogout,
    } = useMenuSettings();
    return (
        <Main>
            <Button onClick={handleOpen} color={open ? "secondary" : "primary"}>
                {/* <PhotoProfile src={Profile} /> */}
                {/* <IconSettings /> */}
                <MenuNameText variant="h3">{profile?.name}</MenuNameText>
            </Button>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <InnerMenu>
                    <Visible condition={true}>
                        <MenuItemText variant="h3">
                            {profile?.name}
                        </MenuItemText>
                    </Visible>
                    {/* <Divisor /> */}
                    <Visible condition={false}>
                        <MenuItem onClick={() => goToPage("/profile")}>
                            <IconPerson /> {translate("navbar.profile")}
                        </MenuItem>
                    </Visible>
                    <Divisor />
                    <MenuItem onClick={onLogout}>
                        <IconPower /> {translate("navbar.logout")}
                    </MenuItem>
                </InnerMenu>
            </Menu>
        </Main>
    );
});

export default MenuSettings;
