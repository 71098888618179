import React, { Suspense, lazy } from "react";
import {
    Routes,
    Route,
    BrowserRouter as Router,
    Navigate,
} from "react-router-dom";

import { setPrefix } from "utils";

import Loading from "components/loading";

const Login = lazy(() => import("pages/login"));
const ResetPassword = lazy(() => import("pages/resetPassword"));
const RegistrationSupplier = lazy(() => import("pages/registrationSupplier"));

const Public: React.FC = () => (
    <Suspense fallback={<Loading />}>
        <Router>
            <Routes>
                <Route path={setPrefix("/")} element={<Login />} />
                <Route
                    path={setPrefix("/reset-password/:code/:email")}
                    element={<ResetPassword />}
                />
                <Route
                    path={setPrefix("/registration-supplier/:uuid/:user_id")}
                    element={<RegistrationSupplier />}
                />
                <Route
                    path="*"
                    element={<Navigate to={setPrefix("/")} replace />}
                />
            </Routes>
        </Router>
    </Suspense>
);

export default Public;
