import styled from "styled-components";
import { createTheme } from "@material-ui/core/styles";
import {
    Drawer,
    Accordion as AccordionCustom,
    AccordionSummary as AccordionSummaryCustom,
    AccordionDetails as AccordionDetailsCustom,
    List as ListCustom,
    ListItem as ListItemCustom,
    ListItemText as ListItemTextCustom,
    Typography,
} from "@material-ui/core";

import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PermDataSettingOutlinedIcon from "@material-ui/icons/PermDataSettingOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import { DeviceMediaQuery } from "assets/styled";

interface PropsItemList {
    active?: string;
    subitem?: string;
}

export const themeWhite = createTheme({
    palette: {
        primary: {
            main: "#fff",
            dark: "#fff",
            light: "#fff",
        },
    },
});

export const DrawerCustom = styled(Drawer)`
    width: 250px;
    transition: all 0.1s ease-in-out;
    background-color: #ffffff;
    min-height: 120vh;
    box-shadow: ${(props) =>
        props.open !== true
            ? "none"
            : "-20px -20px 4px -1px rgb(0 0 0 / 20%), -19px 20px 5px 0px rgb(0 0 0 / 14%), 2px -11px 10px 0px rgb(0 0 0 / 12%)"};
    z-index: ${(props) => (props.open !== true ? "0" : "90")};

    @media ${DeviceMediaQuery.laptop} {
        z-index: ${(props) => (props.open !== true ? "0" : "1111")};
    }

    & > div[class*="MuiPaper-root"] {
        width: 250px;
        top: 20px;
        border-right: 5px;
        background-color: transparent;
    }
`;

export const DrawerInner = styled.div`
    width: 100%;
    height: 100%;
    padding: 0;
    overflow-y: auto;
`;

export const List = styled(ListCustom)``;

export const Accordion = styled(AccordionCustom)`
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    margin: 0;

    &:before {
        display: none;
    }

    /* &:after {
        top: -1px;
        left: 0;
        right: 0;
        height: 1px;
        content: "";
        opacity: 1;
        position: absolute;
        transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: ${(props) => props.theme.palette.divider};
    } */

    &.Mui-expanded {
        margin: 0;
    }
`;

export const AccordionSummary = styled(AccordionSummaryCustom)`
    padding: 0;
    margin: 0;

    [class*="MuiSvgIcon-root"] {
        transition: all 0.3s ease-in-out;
    }
    [class*="MuiAccordionSummary"] {
        margin: 0;
    }

    /* &:hover {
        [class*="MuiButtonBase-root"] {

            [class*="MuiTypography-root"] {
                color: ${(props) => props.theme.palette.primary.light};
            }

            [class*="MuiSvgIcon-root"] {
                fill: ${(props) => props.theme.palette.primary.light};
            }
        }
    } */

    &.Mui-expanded {
        min-height: 30px;

        [class*="MuiButtonBase-root"] {
            height: 50px;
            background-color: #fef5ea;

            [class*="MuiTypography-root"] {
                color: #d8913a;
            }

            [class*="MuiSvgIcon-root"] {
                fill: #d8913a;
            }

            [class*="MuiSvgIcon-root"]:not(:first-child) {
                transform: rotate(-180deg);
            }
        }
    }

    .Mui-expanded {
        margin: 0 0;
    }
`;

export const AccordionDetails = styled(AccordionDetailsCustom)`
    padding: 0 0 0 16px;
    margin-bottom: 10px;

    [class*="MuiList-root"] {
        width: 100%;

        [class*="MuiListItem-root"] {
            padding-top: 3px;
            padding-bottom: 3px;
            transition: all 0.3s ease-in-out;
            margin-bottom: 3px;
        }
    }
`;

export const ListItem = styled(ListItemCustom)<PropsItemList>`
    border-radius: 0px;
    background-color: ${(props) =>
        props.active === "true" ? "#FEF5EA" : "transparent"};
    cursor: ${(props) => (props.active === "true" ? "default" : "point")};
    border-left: ${(props) =>
        props.active === "true" ? "3px #FEF5EA solid" : "3px #FFF solid"};
    display: flex;

    [class*="MuiSvgIcon-root"] {
        fill: #8c8888;

        &:first-child {
            margin-right: 15px;
        }
        color: #d8913a;
    }

    &:hover {
        background-color: #fef5ea;
        border-left: 3px #d8913a solid;

        [class*="MuiTypography-root"] {
            color: #d8913a;
        }
        [class*="MuiSvgIcon-root"] {
            fill: #d8913a;
        }
        [class*="icone-drawer"] {
            path {
                fill: #d8913a !important;
            }
        }
    }

    &.subitem {
        &:hover {
            background-color: transparent;
            border-left: 3px transparent solid;
        }
    }
`;

export const ListItemText = styled(ListItemTextCustom)`
    color: #8c8888;
`;

export const Subtitle = styled(Typography).attrs({
    component: "h3",
})`
    font-weight: 500;
`;

export const IconPermDataSettingOutlined = styled(
    PermDataSettingOutlinedIcon
)``;

export const IconPerson = styled(AccountCircleIcon)``;

export const IconCompany = styled(BusinessIcon)``;

export const IconUsers = styled(PeopleAltOutlinedIcon)``;

export const IconSettings = styled(SettingsOutlinedIcon)``;

export const BoxLogo = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
`;
export const BoxIcon = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
`;
