import React, { memo } from "react";

import { Inner, MenuCustom } from "./styled";

import Fade from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "components/button";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { toSlugText } from "utils";
// BsCheck2Circle
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiCommentDetail } from "react-icons/bi";

interface Props {
    label?: string;
    icon?: any;
    fullWidth?: boolean;
    variant?: "outlined" | "contained" | "text";
    items?: PropItems[];
    disabled?: boolean
}

export interface PropItems {
    label?: string;
    onClick(): void;
}

const ButtonActionTable: React.FC<Props> = memo(
    ({ label = "", variant, items, fullWidth = true, icon, disabled = false }) => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
            null
        );

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const RenderIcon = (name: string) => {
            if ("deletar" === toSlugText(name)) {
                return <BsXCircle color={"#FD683E"} fontSize="14px" />;
            }
            if ("editar" === toSlugText(name)) {
                return <BsCheck2Circle color={"#2BC155"} fontSize="16px" />;
            }
            if ("visualizar" === toSlugText(name)) {
                return (
                    <AiOutlineInfoCircle color={"#634FD2"} fontSize="16px" />
                );
            }
            if ("imprimir" === toSlugText(name)) {
                return (
                    <BiCommentDetail color={"#634FD2"} fontSize="16px" />
                );
            }

            return null;
        };

        const RenderIconLabel = () => {
            if (typeof icon !== "undefined") {
                return icon;
            }

            return label == "" ? <MoreHorizIcon /> : label;
        };

        return (
            <>
                <Button
                    variant={variant || "text"}
                    fullWidth={fullWidth}
                    size="large"
                    className="header"
                    onClick={handleClick}
                    disabled={disabled}
                >
                    {RenderIconLabel()}
                </Button>
                <MenuCustom
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <Inner>
                        {items?.map((item, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    onClick={() => {
                                        item.onClick();
                                        handleClose();
                                    }}
                                >
                                    {RenderIcon(item.label)}
                                    <span style={{ marginLeft: "5px" }}>
                                        {item.label}
                                    </span>
                                </MenuItem>
                            );
                        })}
                    </Inner>
                </MenuCustom>
            </>
        );
    }
);

export default ButtonActionTable;
