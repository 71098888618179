import React, { memo, ReactNode } from "react";
import { ButtonCustom } from "./styled";

interface Props {
    fullWidth?: boolean;
    type?: "submit" | "button";
    variant?: "outlined" | "contained" | "text";
    size?: "small" | "medium" | "large";
    color?: "primary" | "secondary";
    className?: string;
    borderRadius?: string;
    transparent?: boolean;
    onClick?: any;
    style?: any;
    disabled?: boolean;
    children: ReactNode;
}

const Button: React.FC<Props> = memo(
    ({
        fullWidth,
        type,
        variant,
        size,
        color,
        className,
        onClick,
        disabled,
        style,
        borderRadius,
        children,
    }) => {
        borderRadius = borderRadius ?? "";
        return (
            <ButtonCustom
                fullWidth={fullWidth || false}
                type={type || "button"}
                variant={variant || "contained"}
                size={size || "medium"}
                color={color || "default"}
                className={className}
                onClick={onClick}
                disabled={disabled}
                style={style}
            >
                {children}
            </ButtonCustom>
        );
    }
);

export default Button;
