import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { setPrefix } from "utils";

const Layout = lazy(() => import("components/layout"));
const Home = lazy(() => import("pages/home"));

const ListOrder = lazy(() => import("pages/listOrder"));
const DetailOrder = lazy(() => import("pages/detailOrder"));
const FormOrder = lazy(() => import("pages/formOrder"));
//Product
const ListProduct = lazy(() => import("pages/listProduct"));
const FormProduct = lazy(() => import("pages/formProduct"));
//Service
const ListService = lazy(() => import("pages/listService"));
const FormService = lazy(() => import("pages/formService"));

//Category
const Category = lazy(() => import("pages/category"));

//Customer
const ListCustomer = lazy(() => import("pages/listCustomer"));
const ListUsers = lazy(() => import("pages/listUsers"));
const FormCustomer = lazy(() => import("pages/formCustomer"));
const FormUser = lazy(() => import("pages/formUser"));

//Partner
const ListPartner = lazy(() => import("pages/listPartner"));
const FormPartner = lazy(() => import("pages/formPartner"));
const PartnerAward = lazy(() => import("pages/partnerAward"));
const PartnerRework = lazy(() => import("pages/partnerRework"));

const FlowCash = lazy(() => import("pages/flowCash"));
const Scheduler = lazy(() => import("pages/scheduler"));

//Rotas das páginas compartilhadas do sistema
const Profile = lazy(() => import("pages/profile"));
const Page404 = lazy(() => import("pages/404"));

const Admin: React.FC = () => (
    
    <Suspense>
        <Router>
            <Layout>
                <Routes>
                    <Route path={setPrefix("/")} element={<Home />} />
                    <Route
                        path={setPrefix("/pedidos")}
                        element={<ListOrder />}
                    />
                    <Route
                        path={setPrefix("/controle-caixa")}
                        element={<FlowCash />}
                    />
                    <Route
                        path={setPrefix("/pedidos-loja")}
                        element={<ListOrder />}
                    />
                    <Route
                        path={setPrefix("/pedidos-online")}
                        element={<ListOrder />}
                    />
                    <Route
                        path={setPrefix("/pedido/:id")}
                        element={<DetailOrder />}
                    />
                    <Route
                        path={setPrefix("/criar-pedido/:id")}
                        element={<FormOrder />}
                    />
                    <Route
                        path={setPrefix("/criar-pedido-online/:id")}
                        element={<FormOrder />}
                    />
                    <Route
                        path={setPrefix("/produtos")}
                        element={<ListProduct />}
                    />
                    <Route
                        path={setPrefix("/produto/:id")}
                        element={<FormProduct />}
                    />

                    <Route
                        path={setPrefix("/servicos")}
                        element={<ListService />}
                    />
                    <Route
                        path={setPrefix("/servico/:id")}
                        element={<FormService />}
                    />

                    <Route
                        path={setPrefix("/clientes")}
                        element={<ListCustomer />}
                    />
                    <Route
                        path={setPrefix("/cliente/:id")}
                        element={<FormCustomer />}
                    />

                    <Route
                        path={setPrefix("/parceiros")}
                        element={<ListPartner />}
                    />
                    <Route
                        path={setPrefix("/parceiro/:id")}
                        element={<FormPartner />}
                    />
                    
                    <Route
                        path={setPrefix("/parceiro-premiacao")}
                        element={<PartnerAward />}
                    />
                    <Route
                        path={setPrefix("/agendamento/entrega")}
                        element={<Scheduler />}
                    />
                    <Route
                        path={setPrefix("/agendamento/coleta")}
                        element={<Scheduler />}
                    />
                    <Route
                        path={setPrefix("/agendamento/entrega-online")}
                        element={<Scheduler />}
                    />

                    <Route
                        path={setPrefix("/parceiro-retrabalho")}
                        element={<PartnerRework/>}
                    />

                    <Route
                        path={setPrefix("/categoria/:tipo")}
                        element={<Category />}
                    />

                    <Route path={setPrefix("/profile")} element={<Profile />} />
                    
                    <Route
                        path={setPrefix("/usuarios")}
                        element={<ListUsers/>}
                    />
                    <Route
                        path={setPrefix("/usuarios/:id")}
                        element={<FormUser />}
                    />

                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Layout>
        </Router>
    </Suspense>
);

export default Admin;
