import React, { memo } from "react";
import { ThemeProvider } from "@material-ui/core/styles";

import MenuSettings from "components/menuSettings";

import useNavbar from "./useNavbar";

import {
    themeNavbar,
    NavBar,
    ToolBar,
    GroupLogo,
    Logo,
    NameProject,
    GroupOptions,
    ButtonOpen,
    IconMenuOpen,
} from "./styled";

const Navbar: React.FC = memo(() => {
    const { openDrawer, activated, profile, onOpenDrawer } = useNavbar();
    return (
        <ThemeProvider theme={themeNavbar}>
            <NavBar open={openDrawer}>
                <ToolBar>
                    <GroupLogo>
                        <Logo className="logo-header" />
                        <ButtonOpen
                            edge="start"
                            onClick={() => onOpenDrawer(!openDrawer)}
                        >
                            <IconMenuOpen />
                        </ButtonOpen>
                    </GroupLogo>
                    <NameProject></NameProject>
                    <GroupOptions>
                        <MenuSettings />
                    </GroupOptions>
                </ToolBar>
            </NavBar>
        </ThemeProvider>
    );
});

export default memo(Navbar);
