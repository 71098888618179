import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import LanguageDetector from "i18next-browser-languagedetector";

import en from "./en-US";
import ptBR from "./pt-BR";

i18n.use(initReactI18next).init({
    resources: {
        "pt-BR": {
            translation: {
                navbar: ptBR.navbar,
                modal: ptBR.modal,
                format: ptBR.format,
                table: ptBR.table,
                status: ptBR.status,
                settings: ptBR.settings,
                profile: ptBR.profile,
                users: ptBR.users,
                validation: ptBR.validation,
                page404: ptBR.page404,

                //PAGES
                login: ptBR.login,
                home: ptBR.home,
                resetPassword: ptBR.resetPassword,
                listOrder: ptBR.listOrder,
                formOrder: ptBR.formOrder,
                listProduct: ptBR.listProduct,
                formProduct: ptBR.formProduct,
                listService: ptBR.listService,
                formService: ptBR.formService,
                category: ptBR.category,
                listCustomer: ptBR.listCustomer,
                listUsers: ptBR.listUsers,
                formUser: ptBR.formUser,
                formCustomer: ptBR.formCustomer,
                listPartner: ptBR.listPartner,
                formPartner: ptBR.formPartner,
                detailOrder: ptBR.detailOrder,
            },
        },
        // en: {
        //     translation: {
        //         login: en.login,
        //         resetPassword: en.resetPassword,
        //         home: en.home,
        //         navbar: en.navbar,
        //         modal: en.modal,
        //         format: en.format,
        //         table: en.table,
        //         status: en.status,
        //         settings: en.settings,
        //         profile: en.profile,
        //         users: en.users,
        //         validation: en.validation,
        //         page404: en.page404,
        //     },
        // },
    },
    // lng: window.localStorage.i18nextLng || "pt-BR",
    // fallbackLng: window.localStorage.i18nextLng || "pt-BR",
    lng: "pt-BR",
    fallbackLng: "pt-BR",
    interpolation: {
        escapeValue: false,
    },
});
