import React, { memo } from "react";
import { ThemeProvider } from "@material-ui/core/styles";

import {
    themeSelect,
    FormControlCustom,
    InputLabelCustom,
    InputSelectCustom,
    OptionSelect,
    FormHelperText,
} from "./styled";

interface OptionsProps {
    title: string;
    value: string;
}

interface Props {
    color?: "primary" | "secondary";
    label?: string;
    name?: string;
    variant?: "filled" | "outlined" | "standard";
    options: OptionsProps[];
    value: any;
    style?: any;
    error?: boolean;
    multiple?: boolean;
    disabled?: boolean;
    helperText?: string;
    onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const InputSelect: React.FC<Props> = ({
    color,
    options,
    label,
    name,
    variant,
    style,
    value,
    error,
    multiple = false,
    disabled = false,
    helperText,
    onChange,
}) => (
    <ThemeProvider theme={themeSelect}>
        <FormControlCustom color={color} variant="outlined">
            {label && (
                <InputLabelCustom error={error}>{label}</InputLabelCustom>
            )}
            <InputSelectCustom
                variant={variant ?? "outlined"}
                color={color}
                label={label}
                name={name}
                multiple={multiple}
                disabled={disabled}
                onChange={onChange}
                value={value}
                error={error}
                style={style}
            >
                {options?.map((item, index) => (
                    <OptionSelect key={index} value={item.value}>
                        {item.title}
                    </OptionSelect>
                ))}
            </InputSelectCustom>
            {helperText && (
                <FormHelperText error={error}>{helperText}</FormHelperText>
            )}
        </FormControlCustom>
    </ThemeProvider>
);

export default memo(InputSelect);
