import { ThemeProvider } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Navbar from "components/navbar/Navbar";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { MenusNaveBar, PropsMenus } from "../../ListMenus";
import { toSlugText } from "utils";

import Visible from "components/visible";

import useDrawer from "./useDrawer";

import { Logo } from "components/navbar/styled";

import Icon from "components/icon";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    BoxLogo,
    DrawerCustom,
    DrawerInner,
    List,
    ListItem,
    ListItemText,
    themeWhite,
    BoxIcon,
} from "./styled";
import { MenusNaveBarNoAdmin } from "ListMenusNoAdmin";
import { MenusNaveBarStore } from "ListMenusStoreReceptionist";
import { MenusNaveBarDelivery } from "ListMenusDeliveryReceptionist";
import { useAuth } from "hooks/auth";

import { BsCashCoin } from "react-icons/bs";
import { HiOutlineUsers } from "react-icons/hi";

interface PropsModule {
    name: string;
    label: string;
    expanded: boolean;
    active: string | null;
    onChange: any;
    levelAccess: number | undefined;
    goToPage(link: string): void;
}

const RenderIcon = (name: string, isActive = false) => {
    return null;
    const color = isActive ? "#D3872A" : "#8C8888";
    if ("dashboard" === toSlugText(name)) {
        return (
            <Icon
                className="icone-drawer"
                size="20px"
                color={color}
                icon="dashboard"
            />
        );
    }
    if ("clientes" === toSlugText(name)) {
        return (
            <Icon
                className="icone-drawer"
                size="20px"
                color={color}
                icon="cliente"
            />
        );
    }
    if ("servicos" === toSlugText(name)) {
        return (
            <Icon
                className="icone-drawer"
                size="20px"
                color={color}
                icon="servico"
            />
        );
    }
    if ("pedidos-online" === toSlugText(name)) {
        return (
            <Icon
                className="icone-drawer"
                size="25px"
                color={color}
                icon="pedido"
            />
        );
    }
    if ("produtos" === toSlugText(name)) {
        return (
            <Icon
                className="icone-drawer"
                size="20px"
                color={color}
                icon="produto"
            />
        );
    }
    if ("parceiros" === toSlugText(name)) {
        return (
            <Icon
                className="icone-drawer"
                size="20px"
                color={color}
                icon="parceiro"
            />
        );
    }
    if ("atendimento-loja" === toSlugText(name)) {
        return (
            <Icon
                className="icone-drawer"
                size="20px"
                color={color}
                icon="atendimento"
            />
        );
    }
    if ("atendimento-online" === toSlugText(name)) {
        return (
            <Icon
                className="icone-drawer"
                size="20px"
                color={color}
                icon="atendimento"
            />
        );
    }
    if ("controle-de-caixa" === toSlugText(name)) {
        return <BsCashCoin color={color} fontSize="20px" />;
    }
    if ("usuarios" === toSlugText(name)) {
        return <HiOutlineUsers size="20px" color={color} />;
    }

    // Garante que `null` é retornado de forma explícita
    return <></>;
};

const Drawer: React.FC = () => {
    const { t: translate } = useTranslation();
    const { profile, openDrawer, activated, expanded, handleChange, goToPage } =
        useDrawer();
    const { userType } = useAuth();

    const isTypeAdmin = userType === "admin";
    const isTypeStore = userType === "storeReceptionist";
    const isTypeDelivery = userType === "deliveryReceptionist";

    const renderMenu = (
        { name, icon, href, childrens }: PropsMenus,
        index: number
    ) => {
        const childrensNovo = childrens ?? [];

        if (childrensNovo.length == 0)
            return (
                <ListItem
                    key={crypto.randomUUID()}
                    button
                    onClick={() => goToPage(href)}
                    active={Boolean(
                        expanded === toSlugText(name) ||
                            activated?.menu === toSlugText(name)
                    ).toString()}
                >
                    <BoxIcon className="icone">
                        {RenderIcon(
                            name,
                            expanded === toSlugText(name) ||
                                activated?.menu === toSlugText(name)
                        )}
                    </BoxIcon>
                    <ListItemText primary={name} />
                </ListItem>
            );

        return (
            <Accordion
                key={crypto.randomUUID()}
                expanded={
                    expanded === toSlugText(name) ||
                    activated?.menu === toSlugText(name)
                }
                onChange={handleChange(toSlugText(name))}
            >
                <AccordionSummary>
                    <ListItem
                        onClick={() => {
                            if (href != "/") {
                                goToPage(href);
                            }
                        }}
                        button
                        active={Boolean(
                            activated?.menu === toSlugText(name)
                        ).toString()}
                    >
                        <BoxIcon className="icone">
                            {RenderIcon(
                                name,
                                activated?.menu === toSlugText(name)
                            )}
                        </BoxIcon>
                        <ListItemText primary={name} />
                        <ExpandMoreIcon />
                    </ListItem>
                </AccordionSummary>
                <AccordionDetails>
                    <List>
                        {childrensNovo.map((item, index) => (
                            <ListItem
                                key={crypto.randomUUID()}
                                button
                                className={"subitem"}
                                onClick={() => {
                                    goToPage(item.href);
                                }}
                                active={Boolean(
                                    activated?.submenu === item.href
                                ).toString()}
                            >
                                <ListItemText primary={item.name} />
                            </ListItem>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        );
    };

    return (
        <ThemeProvider theme={themeWhite}>
            <Navbar />
            <DrawerCustom variant="persistent" anchor="left" open={openDrawer}>
                <DrawerInner>
                    <BoxLogo>
                        <Logo />
                    </BoxLogo>
                    <Visible condition={true}>
                        {isTypeAdmin
                            ? MenusNaveBar.map((item, index) =>
                                  renderMenu(item, index)
                              )
                            : isTypeStore
                            ? MenusNaveBarStore.map((item, index) =>
                                  renderMenu(item, index)
                              )
                            : isTypeDelivery
                            ? MenusNaveBarDelivery.map((item, index) =>
                                  renderMenu(item, index)
                              )
                            : MenusNaveBarNoAdmin.map((item, index) =>
                                  renderMenu(item, index)
                              )}
                    </Visible>
                </DrawerInner>
            </DrawerCustom>
        </ThemeProvider>
    );
};

export default memo(Drawer);
