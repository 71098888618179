import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 3px;
    }

    /* ::-webkit-scrollbar-thumb {
        background-color: #424242;
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #424242;
    } */

    html, body, #root {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    body {
        background-color: rgba(86, 86, 86, 1);
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    input{
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #8C8888 !important;
        }
    }

    [class*="MuiDataGrid-root"] {
        box-shadow: 0px 4px 14px 11px #9b999926;
        border:0 !important;
        /* MuiDataGrid-cell */
        .MuiDataGrid-cell{
            border-bottom: 1px solid #FBEDE9 !important;
        }
        .MuiDataGrid-columnHeaderTitle{
            color: #635d5d;
        }
        .MuiTablePagination-actions{
            button{
                background: #DD9E50;
                svg{
                    fill: #FFF !important;
                }
                &:nth-child(odd){
                    border-radius: 5px 0 0 5px !important;
                }
                &:nth-child(even){
                    border-radius: 0 5px 5px 0 !important;
                }
                &.Mui-disabled{
                    background: #FEF5EB;
                    svg{
                        fill: #DD9E50 !important;
                    }
                }
            }
        }
    }

    [class*="MuiMenu-list"] {
        li{
            &:hover{
                background: #DD9E50 !important;
                color: #FFF !important;
            }
            &.Mui-selected{
                background: #DD9E50;
                color: #FFF;
                &:hover{
                    background: #dfa761 !important;
                    color: #FFF !important;
                }
            }
        }
    }

    .react-datepicker{
        button.react-datepicker__navigation{
            min-width: 32px !important;
            width: 32px !important;
        }
        .react-datepicker__day--outside-month{
            color: #CCC;
        }
    }
`;
