import { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormik, useFormikContext } from "formik";

import * as yup from "yup";
import api from "service/api";
export default function useAddRework( orderId: number) {
    const { t: translate } = useTranslation();
    const [status, setStatus] = useState<"loading" | "success" | "error" | "">(
        ""
    );  
    const [errorMessage, setErrorMessage] = useState<string>("Erro ao salvar o pagamento!");

    const validationPaymentSchema = useCallback(
        () =>
            yup.object({
                pieceService: yup
                    .array()
                    .min(1, 'Deve selecionar')
                    .required(translate("validation.required")),
            }),
        [translate]
    );

    const formik = useFormik({
        initialValues: {            
            pieceService: []
        },
        validationSchema: validationPaymentSchema,
        onSubmit: (values, { resetForm }) => {
            const form = Object.assign({}, values);
            form.pieceService = form.pieceService.filter((value) => value !== null && value !== undefined )
            saveRework(form);            
        },
    });

    const saveRework = useCallback(
        async (form: any) => {

            setStatus("loading");            
            try {
                 await api.put(
                    `/client/order/rework/${orderId}`,
                    form
                );
    
                setStatus("success");
            } catch (error) {
                setStatus("error");
                setErrorMessage(error.message)
            }    
        },
        []
    );
    return {
        status,
        formik,
        errorMessage
    };
}
