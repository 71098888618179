import React, { memo, ReactNode } from "react";

import { MyFooter } from "./styled";

interface Props {
    children?: ReactNode;
}

const Header: React.FC<Props> = memo(({ children }) => {
    return <MyFooter>{children}</MyFooter>;
});

export default Header;
