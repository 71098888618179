export interface PropsMenus {
    name: string;
    icon: string;
    href: string;
    childrens?: PropsMenus[];
}

export const MenusNaveBarNoAdmin = <PropsMenus[]>[
    {
        name: "Dashboard",
        icon: "",
        href: "/",
        childrens: [],
    },
    {
        name: "Pedidos",
        icon: "",
        href: "/pedidos",
        childrens: [
            {
                name: "Lista de Pedidos",
                icon: "",
                href: "/pedidos",
            },
        ],
    },
    {
        name: "Atendimento",
        icon: "",
        href: "criar-pedido/novo",
        childrens: [
            {
                name: "Criar Pedido",
                icon: "",
                href: "/criar-pedido/novo",
            },
            {
                name: "Pedidos Loja",
                icon: "",
                href: "/pedidos-loja",
            },
        ],
    },
    // {
    //     name: "Serviços",
    //     icon: "",
    //     href: "/servicos",
    //     childrens: [
    //         {
    //             name: "Lista de Serviços",
    //             icon: "",
    //             href: "/servicos",
    //         },
    //         {
    //             name: "Adicionar Serviço",
    //             icon: "",
    //             href: "/servico/novo",
    //         },
    //         {
    //             name: "Categoria",
    //             icon: "",
    //             href: "/categoria/servico",
    //         },
    //     ],
    // },
    // {
    //     name: "Produtos",
    //     icon: "",
    //     href: "/produtos",
    //     childrens: [
    //         {
    //             name: "Lista de Produtos",
    //             icon: "",
    //             href: "/produtos",
    //         },
    //         {
    //             name: "Adicionar Produto",
    //             icon: "",
    //             href: "/produto/novo",
    //         },
    //         {
    //             name: "Categoria",
    //             icon: "",
    //             href: "/categoria/produto",
    //         },
    //     ],
    // },
    {
        name: "Clientes",
        icon: "",
        href: "/clientes",
        childrens: [
            {
                name: "Lista Clientes",
                icon: "",
                href: "/clientes",
            },
            {
                name: "Cadastrar Cliente",
                icon: "",
                href: "/cliente/novo",
            },
        ],
    },
    // {
    //     name: "Parceiros",
    //     icon: "",
    //     href: "/parceiros",
    //     childrens: [
    //         {
    //             name: "Lista de Parceiros",
    //             icon: "",
    //             href: "/parceiros",
    //         },
    //         {
    //             name: "Cadastrar Parceiros",
    //             icon: "",
    //             href: "/parceiro/novo",
    //         },
    //         // {
    //         //     name: "Notificar Parceiros",
    //         //     icon: "",
    //         //     href: "/",
    //         // },
    //     ],
    // },
];
