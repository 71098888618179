import { useDrawer } from "hooks/drawer";
import { useAuth } from "hooks/auth";

export default function useNavbar() {
    const { openDrawer, activated, onOpenDrawer } = useDrawer();
    const { profile } = useAuth();

    return {
        openDrawer,
        activated,
        profile,
        onOpenDrawer,
    };
}
