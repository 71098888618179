import { red, amber, grey } from "@material-ui/core/colors";
import { unstable_createMuiStrictModeTheme as createTheme } from "@material-ui/core/styles";
declare module "@material-ui/core/styles/createPalette" {
    interface PaletterOptionsLoading {
        bg: string;
        bgInner: string;
        dark: string;
        light: string;
    }

    interface PaletteOptionsButton {
        default: { main: string; light: string[]; dark: string[] };
        primary: { main: string; light: string[]; dark: string[] };
        secondary: { main: string; light: string[]; dark: string[] };
        tertiary: { main: string; light: string[]; dark: string[] };
        quaternary: { main: string; light: string[]; dark: string[] };
    }

    interface DrawerOption {
        primary: string;
        secondary: string;
    }

    interface PaletteOptions {
        loading?: PaletterOptionsLoading;
        button?: PaletteOptionsButton;
        drawer?: DrawerOption;
    }
}

// Customiza o tema para esse app
const theme = createTheme({
    palette: {
        primary: {
            main: "#D8913A",
            light: "#976528",
            dark: "#dfa761",
        },
        secondary: {
            main: amber[700],
        },
        error: {
            main: red[900],
        },
        background: {
            default: grey[900],
            paper: "rgba(86, 86, 86, 1)",
        },
        divider: grey[800],
        loading: {
            bg: grey[900],
            bgInner: "rgba(86,86,86,1)",
            dark: grey[800],
            light: grey[50],
        },
        button: {
            default: {
                main: "#fff",
                light: ["#D8913A", "#dfa761"],
                dark: ["#D8913A", "#976528"],
            },
            primary: {
                main: "#000",
                light: ["#fff", "#f1f1f1"],
                dark: ["#eee", "#e1e1e1"],
            },
            secondary: {
                main: "#D3872A",
                light: ["#D3872A", "#dfa761"],
                dark: ["#D3872A", "#dfa761"],
            },
            tertiary: {
                main: "#fff",
                light: ["#bd3336", "#b15252"],
                dark: ["#932528", "#bd3336"],
            },
            quaternary: {
                main: "#000",
                light: ["#ffa000", "#f7a925"],
                dark: ["#bf7a06", "#ffa000"],
            },
        },
        drawer: {
            primary: "#e47816",
            secondary: "#424242",
        },
    },
});

export default theme;
