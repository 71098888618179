import { useState, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuth } from "hooks/auth";
import { useDrawer } from "hooks/drawer";
import { setPrefix } from "utils";
import { useFormik, useFormikContext } from "formik";
import * as yup from "yup";
import api from "service/api";
import { formatMoney, moneyToFloat } from "utils/Utils";

export default function useAddPayments(valorTotal: any, orderId: any) {
    const { t: translate } = useTranslation();
    const [status, setStatus] = useState<"loading" | "success" | "error" | "">(
        ""
    );
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("Erro ao salvar o pagamento!");

    const paymentsTypes = [
        { title: "Pagamento Antecipado", value: "1" },
        { title: "Faturado", value: "2" },
        { title: "Parcial", value: "3" },
        { title: "Desconto", value: "4" },
        { title: "Cortesia", value: "5" },
        { title: "Espaço A+", value: "6" },
    ];

    const paymentsMethod = [
        { title: "Cartão de Crédito", value: "CREDITO" },
        { title: "Cartão de Débito", value: "DEBITO" },
        // { title: "Crédito em Conta", value: "CREDITO_CONTA" },
        { title: "Dinheiro", value: "DINHEIRO" },
        { title: "Pix", value: "PIX" },

    ];
    const paymentsQuota = [
        { title: "1x", value: "1" },
        { title: "2x", value: "2" },
        { title: "3x", value: "3" },
    ];




    const paymentsMethodFlag = {
        CREDITO: [
            { title: "Visa", value: "Visa" },
            { title: "Visa Link", value: "Visa Link" },
            { title: "Master", value: "Master" },
            { title: "Master Link", value: "Master Link" },
            { title: "Amex", value: "Amex" },
            { title: "Amex Link", value: "Amex Link" },
            { title: "Elo", value: "Elo" },
            { title: "Elo Link", value: "Elo Link" },
        ],
        DEBITO: [
            { title: "Rede shop", value: "Rede shop" },
            { title: "Visa Eléctron", value: "Visa Eléctron" },
            { title: "Elo débito", value: "Elo débito" },
        ],
    };

    const validationPaymentSchema = useCallback(
        () =>
            yup.object({
                payment: yup
                    .string()
                    .required(translate("validation.required")),
            }),
        [translate]
    );

    const formik = useFormik({
        initialValues: {
            orderId: orderId,
            payment: "",
            payment_method: "",
            type_discount: "1",
            payment_flag: "",
            price_total: "",
            discount: "0",
            anticipated: "0",
            quota: "1",
            id : 0,
        },
        validationSchema: validationPaymentSchema,
        onSubmit: (values, { resetForm }) => {
            const form = Object.assign({}, values);
            savePayment(form);
        },
    });

    const handleEditPayments = useCallback((item) =>{
        if(item.length === 0 || item[0].more.id == formik.values.id)
            return
        formik.setFieldValue('id', item[0].more.id)
        formik.setFieldValue('orderId', item[0].more.order_id)
        formik.setFieldValue('payment', item[0].more.type_payment)
        formik.setFieldValue('payment_flag', item[0].more.flag)
        formik.setFieldValue('payment_method', item[0].more.method)
        formik.setFieldValue('quota', item[0].more.quota)
        formik.setFieldValue('price_total', Number(item[0].more.value))
        formik.setFieldValue('anticipated', Number(item[0].more.value))
        formik.setFieldValue('discount', Number(item[0].more.value_discount))
        formik.setFieldValue('type_discount', Number(item[0].more.type_discount))
        setIsEdit(true)
    }, [formik.values.id, isEdit])

    const savePayment = useCallback(
        async (form: any) => {
            form.price_total = moneyToFloat(form.price_total);
            form.discount = moneyToFloat(form.discount);
            form.anticipated = moneyToFloat(form.anticipated);
            form.percent_discount = 0;

            let valorTotalGeral = parseFloat(valorTotal);

            if (form.payment == "3" && form.anticipated > parseFloat(valorTotalGeral.toFixed(2))) {
                formik.setFieldError(
                    "anticipated",
                    "Valor Maior que o valor total do pedido"
                );
                return false;
            }

            if (["PIX", "DINHEIRO", "CREDITO_CONTA"].includes(form.payment_method)) {
                form.payment_flag = form.payment_method;
            }

            if (!["1", "3"].includes(String(form.payment))) {
                let payments = paymentsTypes.filter(
                    (item) => item.value === form.payment
                );
                form.payment_flag = payments[0].title.toLocaleUpperCase();
                form.payment_method = payments[0].title.toLocaleUpperCase();
            }

            if (["5", "6", "2"].includes(form.payment)) {
                form.price_total = valorTotalGeral;
            }

            if (form.payment == "4" && form.type_discount == "2") {
                form.percent_discount = form.discount;
                form.discount =
                    valorTotalGeral * (parseInt(form.discount) / 100);
            }

            if (form.payment == "4" && form.discount > valorTotalGeral) {
                formik.setFieldError(
                    "discount",
                    "Valor Maior que o valor total do pedido"
                );
                return false;
            }

            setStatus("loading");
            const headers = { "Content-Type": "multipart/form-data" };
            try {
                let response = isEdit ?  await api.put(
                    `/client/order/${orderId}/payment-order/${form.id}`,
                    form,
                    {
                        headers,
                    }
                ) :  await api.post(
                    `/client/order/payment-order/${orderId}`,
                    form,
                    {
                        headers,
                    }
                );

                setStatus("success");
            } catch (error) {
                setStatus("error");
                setErrorMessage(error.message)
            }
            finally{
                setIsEdit(false)
            }
        },
        [valorTotal, isEdit]
    );

    useEffect(() => {
        console.log(valorTotal);
        formik.setFieldValue("price_total", formatMoney(valorTotal));
        return () => {
            setStatus("");
        };
    }, [valorTotal]);

    return {
        paymentsTypes,
        paymentsMethod,
        paymentsMethodFlag,
        paymentsQuota,
        status,
        formik,
        errorMessage,
        handleEditPayments
    };
}
