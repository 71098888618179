import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/button";
import Form, { FormGroup, InputText } from "components/form";

import { Typography } from "@material-ui/core";
import DropzoneUpload from "components/dropzoneUpload";
import {
    Grid,
    IconError,
    IconSuccess,
    Loading,
    Modal,
    ModalActions,
    ModalBody,
    ModalGroupMessage,
    ModalStatus,
    ModalTextSuccess,
    ModalTitle,
} from "./styled";
import FooterButton from "components/footerButton";

type Error = {
    name?: string;
};

interface Props {
    open: boolean;
    status: "loading" | "success" | "error" | "";
    message?: string;
    fields: any;
    onGoback: () => void;
    onClose: () => void;
}

const CategoriesDetails: React.FC<Props> = memo(
    ({ open, status, message, fields, onGoback, onClose }) => {
        const { t: translate } = useTranslation();

        const modalContent = () => {
            switch (status) {
                case "loading":
                    return (
                        <ModalStatus>
                            <Loading color="secondary" />
                        </ModalStatus>
                    );
                case "success":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconSuccess />
                                    <ModalTextSuccess>
                                        {message}
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={onGoback}
                                        >
                                            {translate("modal.button.goback")}
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );
                case "error":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconError />
                                    <ModalTextSuccess>
                                        {message}
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={onGoback}
                                        >
                                            {translate("modal.button.goback")}
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );

                default:
                    return (
                        <>
                            <ModalTitle>
                                {fields.id !== ""
                                    ? translate(
                                          "modal.categoriesDetails.title.update"
                                      )
                                    : translate(
                                          "modal.categoriesDetails.title.add"
                                      )}
                            </ModalTitle>
                            <ModalBody>
                                <Form onSubmit={fields.handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography>
                                                Nome do Produto
                                            </Typography>
                                            <InputText
                                                label={""}
                                                name="name"
                                                color={"primary"}
                                                value={fields.values.name}
                                                onChange={fields.handleChange}
                                                error={
                                                    fields.touched.name &&
                                                    Boolean(fields.errors.name)
                                                }
                                                helperText={
                                                    fields.touched.name &&
                                                    fields.errors.name
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormGroup>
                                                <Typography>Foto </Typography>
                                                <DropzoneUpload
                                                    oldPhoto={
                                                        fields.values.image
                                                    }
                                                    error={
                                                        Boolean(
                                                            fields.touched.image
                                                        ) &&
                                                        Boolean(
                                                            fields.errors.image
                                                        )
                                                    }
                                                    helperText={
                                                        fields.errors.image
                                                    }
                                                    onFiles={(files: any) => {
                                                        fields.setFieldValue(
                                                            "image",
                                                            files[0]
                                                        );
                                                    }}
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    <FooterButton>
                                        <Button
                                            onClick={onClose}
                                            variant="outlined"
                                        >
                                            {translate("modal.button.cancel")}
                                        </Button>
                                        <Button type="submit">
                                            {fields.id !== ""
                                                ? translate(
                                                      "modal.button.update"
                                                  )
                                                : translate("modal.button.add")}
                                        </Button>
                                    </FooterButton>
                                </Form>
                            </ModalBody>
                        </>
                    );
            }
        };

        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                scroll="body"
            >
                {modalContent()}
            </Modal>
        );
    }
);

export default CategoriesDetails;
