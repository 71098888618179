import styled from 'styled-components';
import { Button, IconButton } from '@material-ui/core';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import CloseIcon from '@material-ui/icons/Close';

interface PropsHasFile {
  type: string;
  fixed?: boolean;
}

export const Container = styled.div`
  position: relative;
  display: flex;
`;

export const Group = styled.label`
  && {
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;

    input[type='file'] {
      width: 200%;
      height: 100%;
      position: absolute;
      top: 0;
      left: -100%;
      opacity: 0;
      z-index: 1;
      cursor: pointer;

      &:hover ~ button {
        background-color: #333;
        border-color: #333;
      }
    }
  }
`;

export const ButtonCustom = styled(Button)`
  && {
    width: 100%;
    border-radius: 10px;
    padding: 12px 20px;
    font-weight: 700;
    overflow: hidden;
    background-color: ${({ theme }: any) => theme.palette.button.default.light[0]};
    background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.default.light})`};

    &:not(:disabled):hover {
      background-color: ${({ theme }: any) => theme.palette.button.default.dark[0]};
      background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.default.dark})`};
    }

    &,
    .MuiButton-label {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .MuiButton-label {
      color: ${({ theme }: any) => theme.palette.button.default.main};
    }

    &.MuiButton-textPrimary {
      background-color: ${({ theme }: any) => theme.palette.button.primary.light[0]};
      background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.primary.light})`};

      .MuiButton-label {
        color: ${({ theme }: any) => theme.palette.button.primary.main};
      }

      &:not(:disabled):hover {
        background-color: ${({ theme }: any) => theme.palette.button.primary.dark[0]};
        background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.primary.dark})`};
      }
    }

    &.MuiButton-textSecondary {
      background-color: ${({ theme }: any) => theme.palette.button.secondary.light[0]};
      background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.secondary.light})`};

      .MuiButton-label {
        color: ${({ theme }: any) => theme.palette.button.secondary.main};
      }

      &:not(:disabled):hover {
        background-color: ${({ theme }: any) => theme.palette.button.secondary.dark[0]};
        background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.secondary.dark})`};
      }
    }
  }
`;

export const HasFile = styled.div<PropsHasFile>`
  min-width: 50px;
  max-width: 50px;
  height: 65px;
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #333;
  display: flex;
  align-items: center;
  justify-content: center;
  position: ${(props) => (props.fixed ? 'absolute' : 'relative')};
  top: ${(props) => (props.fixed ? '-40px' : '0')};
  left: ${(props) => (props.fixed ? '30px' : '0')};
  margin-left: ${(props) => (!props.fixed ? '20px' : '0')};
  z-index: 5;

  &:before {
    width: 100%;
    height: 100%;
    content: '';
    border: 4px solid #fff;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const ButtonIcon = styled(IconButton)``;

export const ButtonRemove = styled(IconButton)`
  && {
    &.btn__remove {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #bd3336;
      position: absolute;
      top: -15px;
      right: -15px;
      padding: 0;
      transition: all 0.1s ease-in-out;
      border: 0;

      &:hover {
        background: #bd3336;
      }

      &:active {
        transform: scale(0.7);
      }
    }
  }
`;

export const IconAttach = styled(AttachFileOutlinedIcon)`
  color: #fff;
`;

export const IconRemove = styled(CloseIcon)`
  color: #fff;
`;

export const LabelType = styled.div`
  text-transform: uppercase;
  color: #bd3336;
  font-size: 12px;
  font-weight: 600;
`;
