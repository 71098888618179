import React, { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/button";
import Form, { FormGroup, InputSelect, InputText } from "components/form";
import { Input, Typography } from "@material-ui/core";

import {
    Grid,
    IconError,
    IconSuccess,
    Loading,
    Modal,
    ModalActions,
    ModalBody,
    ModalGroupMessage,
    ModalStatus,
    ModalTextSuccess,
    ModalTitle,
} from "./styled";
import FooterButton from "components/footerButton";
import useDetailOrder from "pages/detailOrder/useDetailOrder";

interface Props {
    open: boolean;
    status: "loading" | "success" | "error" | "";
    message?: string;
    listPartnersOptions: any;
    listReasonsOptions?: any;
    listPieces: any[];
    orderId: number;
    fields: any;
    typeModel?: number;
    notAdminPayment: any;
    onGoNext: () => void;
    onGoback: () => void;
    onClose: () => void;
}

const AddPartnerDressmaker: React.FC<Props> = memo(
    ({
        open,
        status,
        message,
        fields,
        listPartnersOptions,
        orderId,
        notAdminPayment,
        listPieces,
        typeModel = 0,
        listReasonsOptions = [],
        onGoNext,
        onGoback,
        onClose,
    }) => {
        const { t: translate } = useTranslation();
        const [just, setJust] = useState([]);
        const { selectCreatedAt, setSelectCreatedAt } = useDetailOrder();
        useEffect(() => {
            setJust(fields.values.justifications);
        }, [open]);
        const handleSubmit = () => {
            fields.setFieldValue("justifications", just);
            fields.handleSubmit();
        };
        const modalContent = () => {
            switch (status) {
                case "loading":
                    return (
                        <ModalStatus>
                            <Loading color="secondary" />
                        </ModalStatus>
                    );
                case "success":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconSuccess />
                                    <ModalTextSuccess>
                                        {message}
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={onGoNext}
                                        >
                                            continue
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );
                case "error":
                    return (
                        <>
                            <ModalStatus>
                                <ModalGroupMessage>
                                    <IconError />
                                    <ModalTextSuccess>
                                        {message}
                                    </ModalTextSuccess>
                                    <ModalActions>
                                        <Button
                                            color="primary"
                                            onClick={onGoback}
                                        >
                                            {translate("modal.button.goback")}
                                        </Button>
                                    </ModalActions>
                                </ModalGroupMessage>
                            </ModalStatus>
                        </>
                    );

                default:
                    return (
                        <>
                            <ModalTitle>Defina o Costureira</ModalTitle>
                            <Form onSubmit={handleSubmit}>
                                <ModalBody>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography>
                                                Pedido: #{orderId}
                                            </Typography>
                                        </Grid>
                                        {listPieces.map((pieces, index) => {
                                            return (
                                                <>
                                                    <Grid
                                                        item
                                                        xs={
                                                            typeModel === 1
                                                                ? 3
                                                                : 5
                                                        }
                                                    >
                                                        <Typography>
                                                            Peça
                                                        </Typography>
                                                        <InputText
                                                            key={crypto.randomUUID()}
                                                            label={""}
                                                            disabled={false}
                                                            // multiple={true}
                                                            name={`pieces[${index}]`}
                                                            color={"primary"}
                                                            value={`${pieces.id_piece} ${pieces?.category_name} ${pieces?.service_item?.title}`}
                                                            onChange={(
                                                                item
                                                            ) => {
                                                                fields.handleChange(
                                                                    item
                                                                );
                                                            }}
                                                            error={
                                                                Boolean(
                                                                    fields
                                                                        .touched
                                                                        .pieces
                                                                ) &&
                                                                Boolean(
                                                                    fields
                                                                        .errors
                                                                        .pieces
                                                                )
                                                            }
                                                            helperText={
                                                                fields.touched
                                                                    .pieces &&
                                                                fields.errors
                                                                    .pieces
                                                            }
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={
                                                            typeModel === 1
                                                                ? 3
                                                                : 7
                                                        }
                                                    >
                                                        <Typography>
                                                            Parceiro Costureira
                                                        </Typography>
                                                        <InputSelect
                                                            key={crypto.randomUUID()}
                                                            color="primary"
                                                            name={`partners[${index}]`}
                                                            // multiple={true}
                                                            options={listPartnersOptions.sort(
                                                                (a, b) => {
                                                                    if (
                                                                        a.title <
                                                                        b.title
                                                                    ) {
                                                                        return -1;
                                                                    }
                                                                    if (
                                                                        a.title >
                                                                        b.title
                                                                    ) {
                                                                        return 1;
                                                                    }
                                                                    return 0;
                                                                }
                                                            )}
                                                            value={
                                                                fields.values
                                                                    .partners[
                                                                    index
                                                                ] ?? 0
                                                            }
                                                            onChange={(
                                                                item
                                                            ) => {
                                                                fields.handleChange(
                                                                    item
                                                                );
                                                            }}
                                                            error={Boolean(
                                                                notAdminPayment
                                                            )}
                                                        />
                                                    </Grid>
                                                    {typeModel === 1 ? (
                                                        <>
                                                            <Grid item xs={3}>
                                                                <Typography>
                                                                    Motivos
                                                                </Typography>
                                                                <InputSelect
                                                                    key={crypto.randomUUID()}
                                                                    color="primary"
                                                                    name={`reasons[${index}]`}
                                                                    options={
                                                                        listReasonsOptions
                                                                    }
                                                                    value={
                                                                        fields
                                                                            .values
                                                                            .reasons[
                                                                            index
                                                                        ] ?? 0
                                                                    }
                                                                    onChange={(
                                                                        item
                                                                    ) => {
                                                                        fields.handleChange(
                                                                            item
                                                                        );
                                                                    }}
                                                                    error={Boolean(
                                                                        notAdminPayment
                                                                    )}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={3}>
                                                                <Typography>
                                                                    Justificativa
                                                                </Typography>
                                                                <InputText
                                                                    label={""}
                                                                    disabled={
                                                                        false
                                                                    }
                                                                    name={`justifications[${index}]`}
                                                                    value={
                                                                        just[
                                                                            index
                                                                        ]
                                                                    }
                                                                    onChange={(
                                                                        item
                                                                    ) => {
                                                                        just[
                                                                            index
                                                                        ] =
                                                                            item.target.value;
                                                                        const aux =
                                                                            just;
                                                                        setJust(
                                                                            [
                                                                                ...aux,
                                                                            ]
                                                                        );
                                                                    }}
                                                                    color={
                                                                        "primary"
                                                                    }
                                                                    error={
                                                                        Boolean(
                                                                            fields
                                                                                .touched
                                                                                .pieces
                                                                        ) &&
                                                                        Boolean(
                                                                            fields
                                                                                .errors
                                                                                .pieces
                                                                        )
                                                                    }
                                                                    helperText={
                                                                        fields
                                                                            .touched
                                                                            .pieces &&
                                                                        fields
                                                                            .errors
                                                                            .pieces
                                                                    }
                                                                />
                                                            </Grid>
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                </>
                                            );
                                        })}
                                        <>
                                            <Grid
                                                item
                                                xs={typeModel === 1 ? 3 : 5}
                                            >
                                                <Typography>Data</Typography>
                                                <Input
                                                    disabled={false}
                                                    type="date"
                                                    name={`createdAt`}
                                                    value={
                                                        fields.values.createdAt
                                                    }
                                                    onChange={(item) => {
                                                        fields.handleChange(
                                                            item
                                                        );
                                                    }}
                                                    color={"primary"}
                                                />
                                            </Grid>
                                        </>
                                        <Grid item xs={12}>
                                            <FooterButton>
                                                <Button type="submit">
                                                    {translate(
                                                        "modal.button.add"
                                                    )}
                                                </Button>
                                                <Button
                                                    onClick={onClose}
                                                    variant="outlined"
                                                >
                                                    {translate(
                                                        "modal.button.cancel"
                                                    )}
                                                </Button>
                                            </FooterButton>
                                        </Grid>
                                    </Grid>
                                </ModalBody>
                            </Form>
                        </>
                    );
            }
        };

        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                scroll="body"
            >
                {modalContent()}
            </Modal>
        );
    }
);

export default AddPartnerDressmaker;
